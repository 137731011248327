import { DateParams, SpecialDateAvailableParams, SpecialDateDataForm } from '../types/api/SpecialDate.type'
import { api } from './configs/axiosConfigs'

export const SpecialDateAPI = {

  getSpecialDates: async (params:DateParams|null = null) => {

    const response = await api.get(
      '/specialDates',
      {
        params: params,
      }
    ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
    return response
  },

  getSpecialDatesAvailable: async (params:SpecialDateAvailableParams|null = null) => {

    const response = await api.get(
      '/specialDates/available',
      {
        params: params,
      }
    ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
    return response
  },

  getSpecialDatesGroup: async (params:any) => {

    const response = await api.get(
      '/specialDates/listGroup',
      {
        params: params,
      }
    ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
    return response
  },

  createSpecialDate: async (data: SpecialDateDataForm) => {
    const response = await api.post(
        '/specialDates/register', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  createSpecialDateMassive: async (data: any) => {
    const response = await api.post(
        '/specialDates/registerMassive', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  editSpecialDate: async (idspecialdate: number, data: SpecialDateDataForm) => {
    const response = await api.patch(
    `/specialDates/${idspecialdate}`,
    { ...data }
    ).then(response => response.data)
    .catch((error) => {
        return error.response.data
    })
    return response
  },

  updateMassive: async (data: any) => {
    const response = await api.patch(
    `/specialDates/updateMassive`, data
    ).then(response => response.data)
    .catch((error) => {
        return error.response.data
    })
    return response
  },

  deleteSpecialDate: async (idspecialdate: number) => {
    const response = await api.delete(
    `/specialDates/${idspecialdate}`
    ).then(response => response.data)
    .catch((error) => {
        return error.response.data
    })
    return response
  },

  deleteSpecialDateMassive: async (data: any) => {
    const response = await api.post(
    `/specialDates/deleteMassive`, {...data}
    ).then(response => response.data)
    .catch((error) => {
        return error.response.data
    })
    return response
  },
}