import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { OwnerModal } from "./OwnerModal";
import { Owner } from "../../types/slices/ownerType";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { ApiStatus } from "../../types/api/status";
import GroupsIcon from '@mui/icons-material/Groups';
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";

const columns = [
    { type: 'options',  field: 'options', align:'center', label: 'Opciones',  },
    { type: 'text',     field: 'name', align:'center', label: 'Nombre' },
    { type: 'text',     field: 'rut', align:'center', label: 'Rut' },
    { type: 'text',     field: 'email', align:'center', label: 'Correo' },
    { type: 'text',     field: 'phone', align:'center', label: 'Celular' },
    // { type: 'text',     field: 'percentage', align:'center', label: 'Porcentaje departamento', format:(row) => row.percentage ? row.percentage : '-----'},
    { type: 'detail',   field: 'detail',  align:'center', label: '' },
];

export const OwnerView = () => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const { status: statusOwnerApi, owners, getOwners, setSelectedOwner, deleteOwner, setActionOner } = useOwnerStore()

    const [ rows, setRows ] = useState<Owner[]>([])
    const [rowSelected, setRowSelected] = useState<any>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelectedOwner({} as Owner)
        getOwnersApi()
    },[search])

    useEffect(() => {
        if (owners) {
          setRows(owners)
          return
        }
        setRows([])
    }, [owners])

    const getOwnersApi = async() => {
        try {
          setLoading(true);
          const response = await getOwners({search});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionOner('add')
        navigate({ pathname: RoutesMap.OWNER_FORM })
    }
    const onEdit = (rowSelected) => {
        setActionOner('edit')
        setSelectedOwner(rowSelected)
        if(rowSelected.is_admin === "0" ) {
            navigate({ pathname: RoutesMap.OWNER_FORM })
        }else {
            CustomSnackbar('error', 'No se puede editar el propietario por defecto.')
        }
    }
    const onDelete = (rowSelected) => {
        setActionOner('delete')
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }
    const onDetails = (rowSelected) => {
        setActionOner('detail')
        setRowSelected(rowSelected)
        setShowModal(true);
    }
    const onHelper = (rowSelected) => {
        setSelectedOwner(rowSelected)
        navigate({ pathname: RoutesMap.GUEST_ADMIN })
    }
    const onDeleteConfirm = async() => {
        const response = await deleteOwner(rowSelected.id)
        if(response){
            setSelectedOwner({} as Owner)
            setShowModalConfirm(false)
            getOwnersApi()
        }else{
            return false;
        }
    }

    return (
        <>
            {/* { statusOwnerApi === ApiStatus.FETCHING && <CustomBackdrop open={true} /> } */}

            <Grid item xs={12}>
                <CustomTable 
                    title={'Propietarios'}
                    columns={columns} 
                    loading={loading}
                    rows={rows || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDetails={onDetails}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    // onBtnMenu
                    // tooltip_menu={'Reportes'}
                    onHelper={onHelper}
                    icon_helper={<GroupsIcon fontSize="small" />}
                    tooltip_helper={'Invitados'}
                />
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{setShowModalConfirm(false)}}
                        rowSelected={rowSelected}
                        onConfirm={onDeleteConfirm}
                        status2={statusOwnerApi == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModal && (
                    <OwnerModal
                        open={showModal}
                        closeModal={()=>{setShowModal(false)}}
                        rowSelected={rowSelected}
                    />
                )
            }
        </>
    )
}