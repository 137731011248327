import { FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { RoutesMap } from "../../types";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { CircularProgress , Grid, Checkbox, FormControlLabel, TextField, FormLabel, Typography, Button, Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CessionAddThirdModal } from "./components/CessionAddThirdModal";
import { useOwnerStore } from "../../hooks/useOwnerStore"
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Department } from "../../types/slices/departmentType"
import { AssignmentAPI } from "../../apis/AssignmentAPI";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { useReservationStore } from "../../hooks/useReservationStore";
import { Owner } from "../../types/slices/ownerType";
import { Reservation } from "../../types/slices/reservationType";
import { showBackdrop } from "../../toolbox/helpers/custom-action";
import { useDispatch } from "../../redux/store";
import { useGuestStore } from "../../hooks/useGuestStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useAssignmentStore } from "../../hooks/useAssignmentStore";
import { Guest } from "../../types/slices/guestType";
import moment from "moment";

type Values = {
  id: number | null,
  iddepartment: number | string,
  idowner_assignor: number | string,
  idowner_assignee: number | string,
  idassignment_type: number | string,
  idreservation: number | string,
  days_give: number | string,
  days_recieve: number | string,
  start_date: string,
  end_date: string
}

export const CessionForm : FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {owners, ownerAssignee, ownerAssignor, status:statusOwnerAssignor, getOwners, setSelectedOwnerAssignee, setSelectedOwnerAssignor} = useOwnerStore();
  const { departments, selectedDepartment, getDepartments, setSelectedDepartment } = useDepartmentStore();
  const { reservations, selectedReservation, setSelectedReservation, getReservations } = useReservationStore();
  const { guests, selectedGuest, getGuestsType, getGuestsByOwner, setSelectedGuest, getGuestsThird } = useGuestStore();

  const { selectedAssignment } = useAssignmentStore();
  const [ showModal, setShowModal ] = useState<boolean>(false)
  const [ typeAssignee, setTypeAssignee ] = useState<number|string>("")
  const [ arrayAssignee, setArrayAssignee ] = useState<Owner[]>([])
  const [ loadingAssignor, setLoadingAssignor ] = useState<boolean>(false)
  const [ loadingDepartment, setLoadingDepartment ] = useState<boolean>(false)
  const [ loadingReservation, setLoadingReservation ] = useState<boolean>(false)
  const [ loadingSubmit, setLoadingSubmit ] = useState<boolean>(false)

  const [checked, setChecked] = useState<string>('Propietario')

  const [data, setData] = useState<Values>({
    id: selectedAssignment.id ? selectedAssignment.id : null,
    iddepartment: selectedAssignment.iddepartment ? selectedAssignment.iddepartment : "",
    idowner_assignor: selectedAssignment.idowner_assignor ? selectedAssignment.idowner_assignor : "",
    idowner_assignee: (selectedAssignment.idowner_assignee && selectedAssignment.type_assignee === "1") ? selectedAssignment.idowner_assignee : "",
    idassignment_type: selectedAssignment.idassignment_type ? selectedAssignment.idassignment_type : "",
    idreservation: selectedAssignment.idreservation ? selectedAssignment.idreservation : "",
    days_give: selectedAssignment.days_give ? selectedAssignment.days_give : "",
    days_recieve: selectedAssignment.days_recieve ? selectedAssignment.days_recieve : "",
    start_date: "",
    end_date: ""
  });


  useEffect(() => {
    getGuestsType();
    validateEditMode();
  }, [selectedAssignment]);

  useEffect(() => {
    if (selectedAssignment?.id) {
      setSelectedOwnerAssignor(owners.find((item) => item.id === selectedAssignment.idowner_assignor) || {} as Owner);
      const newArray = owners.filter(obj => obj.id !== selectedAssignment.idowner_assignor);
      setArrayAssignee(newArray);
      setTypeAssignee(selectedAssignment.type_assignee);
      
      if (selectedAssignment.type_assignee === "1") {
        setSelectedOwnerAssignee(owners.find((item) => item.id === selectedAssignment.idowner_assignee) || {} as Owner)
      }
    }
  }, [owners]);

  useEffect(() => {
    if (selectedAssignment?.id) {
      setSelectedDepartment(departments.find((item) => item.id === selectedAssignment.iddepartment) || {} as Department);
    }
  }, [departments]);

  useEffect(() => {
    if (selectedAssignment?.id) {      
      setSelectedReservation(reservations.find((item) => item.id === selectedAssignment.idreservation) || {} as Reservation);
    }
  }, [reservations]);

  useEffect(() => {
    if (selectedAssignment?.id) {
      if (selectedAssignment.type_assignee === "2") {
        setSelectedGuest(guests.find((item) => item.id === selectedAssignment.idinvited_owner) || {} as Guest);
      } 
    }
  }, [guests]);

  const validateEditMode = async () => {
    // llamar a los demas endpoints
    if (selectedAssignment?.id) {
      const responseOwnerAssignor = await getOwnersApi();
      const responseDepartment = await getDepartmentsApi(selectedAssignment.idowner_assignor);
      const responseReservation = await getReservationApi(selectedAssignment.idowner_assignor, selectedAssignment.iddepartment);
      getGuestsByOwner(selectedAssignment.idguest_type_invited_owner);
      setLoadingAssignor(false);
    } else {
      getOwnersApi();
      getGuestsType();
      setSelectedDepartment({} as Department);
      setSelectedReservation({} as Reservation);
      setSelectedOwnerAssignee({} as Owner);
    }
  }

  const getOwnersApi = async() => {
    try {
      setLoadingAssignor(true);
      const response = await getOwners();
      if (!selectedAssignment?.id) {
        setSelectedOwnerAssignor({} as Owner);
        setLoadingAssignor(false);
      }
    } catch (error) {
      setLoadingAssignor(false);
    }
  }

  const getDepartmentsApi = async(idowner?:number|undefined) => {
    try {
      setLoadingDepartment(true);
      const response = await getDepartments({ idowner });
      setLoadingDepartment(false)
    } catch (error) {
      setLoadingDepartment(false);
    }
  }

  const getReservationApi = async(idowner?:number|string|undefined, iddepartment?:number|undefined) => {
    try {
      setLoadingReservation(true);
      const params: any = { idowner, iddepartment, status: '1' }
      const response = await getReservations(params);
      setLoadingReservation(false)
    } catch (error) {
      setLoadingReservation(false);
    }
  }

  const validateForm = (values) => {
    let errors:any = {};
    if(!values.idowner_assignor) errors.idowner_assignor = "Campo requerido";
    if(!values.iddepartment) errors.iddepartment = "Campo requerido";
    if(!values.idreservation) errors.idreservation = "Campo requerido";
    if(!values.days_recieve) errors.days_recieve = "Campo requerido";
    
    return errors;
  }

  const onSubmit = async (formData) => {
    if (!typeAssignee) return CustomSnackbar('warning', "Debe seleccionar un tipo de Cesionario");
    if (typeAssignee == "2" && !selectedGuest.id) return CustomSnackbar('warning', "Debe seleccionar un Cesionario");

    setLoadingSubmit(true);
    dispatch(showBackdrop(true))
    
    const data = {
      ...formData,
      type_assignee: typeAssignee,
      idassignment_type: 1,
      idinvited_owner: typeAssignee == "2" ? selectedGuest.id : ""
    }

    let response:any;
    if (!!selectedAssignment.id) {
      response = await AssignmentAPI.editAssignment(selectedAssignment.id, data)
    } else {
      response = await AssignmentAPI.createAssignment(data);
    }

    if (response && !response.status) {
      setLoadingSubmit(false);
      dispatch(showBackdrop(false));
      CustomSnackbar('error', response.data.message);
    } else {
      CustomSnackbar('success', response.data.message);
      setTimeout(() => {
        dispatch(showBackdrop(false));
        navigate({ pathname: RoutesMap.CESSION_LIST })
      }, 2000);
    }
    
  }

  const handleCancel = () => {
    navigate({ pathname: RoutesMap.CESSION_LIST })
  }

  const onChangeAssignor = (newValue: Owner | null, setFieldValue:any) => {
    if (newValue) {
      setFieldValue('idowner_assignor', newValue.id);
      setSelectedOwnerAssignor(newValue);

      getDepartmentsApi(newValue.id);
      if (newValue.id == ownerAssignee?.id) {
        validateChangeAssignor(setFieldValue, true);
      } else {
        validateChangeAssignor(setFieldValue, false);
      }
      const newArray = owners.filter(obj => obj.id !== newValue.id);
      setArrayAssignee(newArray);
    } else {
      setFieldValue('idowner_assignor', "");
      setSelectedOwnerAssignor({} as Owner);
      validateChangeAssignor(setFieldValue, true);
    }
  }

  const validateChangeAssignor = (setFieldValue: any, deleteSelect: boolean) => {
      deleteSelect && setFieldValue('idowner_assignee', "");
      setFieldValue('days_give', "");
      setFieldValue('days_recieve', "");

      deleteSelect && setSelectedOwnerAssignee({} as Owner);
      setSelectedDepartment({} as Department);
      setSelectedReservation({} as Reservation);
      setArrayAssignee([]);
      setTypeAssignee("");
  }

  const validateDateReservation = () => {
    
    const fechaActual = moment().format('YYYY-MM-DD');
    const startDate = moment(selectedReservation.startDate).format('YYYY-MM-DD')
    const endDate = moment(selectedReservation.endDate).format('YYYY-MM-DD')

    const estaEnRango = moment(fechaActual).isBetween(startDate, endDate, null, '[]');

    if (estaEnRango)  
    return true;

    return false;
  }

  return(
    <>
      {
        selectedAssignment?.id ? (
          loadingAssignor && <CustomBackdrop open={true} />
        ) : (
          statusOwnerAssignor == ApiStatus.FETCHING && <CustomBackdrop open={true} />
        )
      }
      
      <CustomForm>
        <CustomFormHeader
          title={selectedAssignment.id ? 'Editar cesión' : 'Agregar cesión'}
          text={selectedAssignment.id ? 'Modifique los datos de la solicitud' : 'Modifique los datos de la nueva solicitud'}
          goBack={RoutesMap.CESSION_LIST}
        />
      <CustomFormBody>
          <Formik
            initialValues={data}
            enableReinitialize
            validate={ (values) => validateForm(values) }
            onSubmit={onSubmit}
          >
          {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
            return (
              
              <Grid container sx={{ padding:'0px 20px' }}>
                <Grid container item>
                  <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                    <Grid item xs={12} md={6}>
                      <FormLabel>Cedente:</FormLabel>
                      <Autocomplete
                        id="idowner_assignor"
                        size="small"
                        placeholder="Seleccione un Cedente"
                        options={owners || []}
                        value={!!ownerAssignor?.id ? ownerAssignor : null}
                        loading={loadingAssignor}
                        fullWidth
                        getOptionLabel={(owner) => owner.name}
                        onChange={(e, newValue) => {
                          onChangeAssignor(newValue,setFieldValue);
                        }}
                        isOptionEqualToValue={(option,value)=> option.id === value?.id} 
                        renderInput={(params) => 
                          <TextField
                            {...params}
                            label=""
                            size="small"
                            error={errors.idowner_assignor && touched.idowner_assignor ? true : false}
                            helperText={errors.idowner_assignor && touched.idowner_assignor ? errors.idowner_assignor : ''}
                          />
                        }
                        disabled={!!selectedAssignment.id}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel>Departamento:</FormLabel>
                      <Autocomplete
                        id="iddepartment"
                        size="small"
                        placeholder="Seleccione un Departamento"
                        options={departments || []}
                        value={!!selectedDepartment.id ? selectedDepartment : null}
                        loading={loadingDepartment}
                        getOptionLabel={(departament) => departament?.name}
                        onChange={(e, newValue: any) => {
                          setSelectedDepartment(newValue || {} as Department);
                          newValue && getReservationApi(values.idowner_assignor,newValue.id);

                          setFieldValue('iddepartment', newValue ? newValue.id : "");
                        }}
                        isOptionEqualToValue={(option,value)=> option.id === value?.id} 
                        renderInput={(params) => 
                          <TextField
                            {...params}
                            label=""
                            size="small"
                            error={errors.iddepartment && touched.iddepartment ? true : false}
                            helperText={errors.iddepartment && touched.iddepartment ? errors.iddepartment : ''}
                          />
                        }
                        disabled={!!!ownerAssignor?.id}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel>Reservación:</FormLabel>
                      <Autocomplete
                        id="idreservation"
                        size="small"
                        placeholder="Seleccione una Reservación"
                        options={reservations || []}
                        value={!!selectedReservation.id ? selectedReservation : null}
                        loading={loadingReservation}
                        fullWidth
                        getOptionLabel={(reservation) => { return `Ingreso: ${moment(reservation.startDate).format("YYYY-MM-DD")} - Salida: ${moment(reservation.endDate).format("YYYY-MM-DD")}` }}
                        onChange={(e, newValue) => {
                          setFieldValue('idreservation', newValue ? newValue.id : "");
                          setSelectedReservation(newValue || {} as Reservation)

                          setFieldValue('days_give',newValue ? newValue.number_days : "")
                          setFieldValue('days_recieve',newValue ? newValue.number_days : "")

                          validateDateReservation() && setFieldValue('start_date', newValue ? moment(newValue.startDate).format("YYYY-MM-DD") : "")
                          validateDateReservation() && setFieldValue('end_date', newValue ? moment(newValue.endDate).format("YYYY-MM-DD") : "")
                        
                        }}
                        isOptionEqualToValue={(option,value)=> option.id === value?.id} 
                        renderInput={(params) => 
                          <TextField
                            {...params}
                            label=""
                            size="small"
                            error={errors.idreservation && touched.idreservation ? true : false}
                            helperText={errors.idreservation && touched.idreservation ? errors.idreservation : ''}
                          />
                        }
                        disabled={!!!selectedDepartment?.id}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel>Días reservados:</FormLabel>
                      <TextField
                        id="days_give"
                        type="text"
                        name="days_give"
                        fullWidth
                        size="small"
                        value={values.days_give}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel control={
                        <Checkbox
                          checked={ checked == 'Propietario'}
                          onClick={ (e) => setChecked('Propietario')}
                        />}
                        label="Propietario"
                        disabled={!!!selectedDepartment?.id}
                      />

                      <FormControlLabel control={
                        <Checkbox
                          checked={checked == 'Tercero'}
                          onClick={(e) => {setChecked('Tercero');  
                          getGuestsThird();
                          setSelectedOwnerAssignee( {} as Owner);
                          setFieldValue('idowner_assignee', "");
                          setTypeAssignee("");
                        }}
                        />}
                        label="Tercero"
                        disabled={!!!selectedDepartment?.id}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel>Cesionario:</FormLabel>
                      <Grid item container>
                        <Grid item xs={checked === "Propietario" ? 12 : 9}>
                        { checked === "Propietario" && <Autocomplete
                          id="combo-box-assignee"
                          size="small"
                          placeholder="Seleccione un Cesionario"
                          options={arrayAssignee}
                          value={
                            selectedAssignment?.id ? (
                              ownerAssignee?.id && (selectedAssignment.type_assignee === "1") ? ownerAssignee : null
                            ) : (
                              ownerAssignee?.id ? ownerAssignee : null
                            )
                          }
                          fullWidth
                          getOptionLabel={(owner) => owner.name}
                          isOptionEqualToValue={(option,value)=> option.id === value?.id} 
                          onChange={(e, newValue) => {
                            setSelectedOwnerAssignee(newValue || {} as Owner);
                            setFieldValue('idowner_assignee', newValue ? newValue.id : "");
                            setTypeAssignee(newValue ? 1 : "");
                          }}
                          renderInput={(params) => 
                            <TextField
                              {...params}
                              label=""
                              size="small"
                            />
                          }
                          disabled={!!!ownerAssignor?.id || !!selectedGuest.id }
                        />}
                        { checked === "Tercero" && <Autocomplete
                          id="combo-box-assignee"
                          size="small"
                          placeholder="Seleccione un Cesionario"
                          options={guests || []}
                          value={
                            !!selectedGuest?.id ? selectedGuest : null
                          }
                          fullWidth
                          getOptionLabel={(guest) => guest.name}
                          onChange={(e, newValue) => {
                            setSelectedGuest(newValue || {} as Guest);
                          }}
                          renderInput={(params) => 
                            <TextField
                              {...params}
                              label=""
                              size="small"
                            />
                          }
                          disabled={!!!ownerAssignor?.id }
                        />}
                        </Grid>
                        { checked === "Tercero" && <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            onClick={ () => setShowModal(true) }
                            sx={{ backgroundColor: '#fff', border: '1px solid #212D39', color: '#212D39', height: 40, padding: '5px', textTransform: 'none', width: 100, "&:hover": { backgroundColor: "#212D39", color: '#fff' } }}
                            disabled={!!!ownerAssignor?.id || !!ownerAssignee?.id}
                          >
                            Tercero
                          </Button>
                        </Grid>}
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <FormLabel>Tipo de Cesión:</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        row
                        
                      >
                        <FormControlLabel
                          sx={{ padding: '0px 20px' }}
                          value={1}
                          checked={cessionType == 1 ? true : false}
                          control={
                            <Radio onChange={(e) => handleChangeCessionType(e)} sx={{ color: '#000000', '&.Mui-checked': { color: '#000000' } }} />
                          }
                          label="Equitativo"
                          disabled={true}
                        />
                        <FormControlLabel
                          sx={{ padding: '0px 20px' }}
                          value={2}
                          checked={cessionType == 2 ? true : false}
                          control={
                            <Radio onChange={(e) => handleChangeCessionType(e)} sx={{ color: '#000000', '&.Mui-checked': { color: '#000000' } }} />
                          }
                          label="Negociable"
                          disabled={true}
                        />
                      </RadioGroup>
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <FormLabel>Días a ceder:</FormLabel>
                      <Grid sx={{ marginLeft:"100px" }}>
                        <TextField
                          id="days_recieve"
                          type="number"
                          name="days_recieve"
                          sx={{ width: '40px' }}
                          InputProps={{
                            readOnly:  true ,
                            inputProps: {
                              min: 0,
                              max: selectedReservation.number_days,
                              style: { textAlign: "center", justifyContent:"center", alignItems: "center" }
                            },
                          }}
                          size="small"
                          value={values.days_recieve}
                          onChange={handleChange}
                          error={errors.days_recieve && touched.days_recieve ? true : false}
                          variant="standard"
                          disabled={!!!selectedDepartment?.id} 
                        />
                        <Typography sx={{ color: 'grey', marginLeft: "10px" }}>{'días (equitativo)'}</Typography>
                      </Grid>
                    </Grid>
                    {
                      !!selectedReservation.id && validateDateReservation() &&
                      <>
                      <Grid item xs={12} md={6}>
                      <FormLabel>Fecha Inicio:</FormLabel>
                      <TextField
                        id="start_date"
                        type="date"
                        name="start_date"
                        fullWidth
                        size="small"
                        value={values.start_date}
                        InputProps={{
                          inputProps: {
                            min: moment().format('YYYY-MM-DD'), // Establece la fecha mínima permitida
                            max: moment(selectedReservation.endDate).format('YYYY-MM-DD'), // Establece la fecha máxima permitida
                          },
                        }}
                        onChange={(e) => {handleChange(e); 
                          console.log(e)
                          console.log(values.start_date)
                          console.log(values.end_date)
                          setFieldValue('days_recieve', moment(values.end_date).diff(e.target.value, 'days'))
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel>Fecha Fin:</FormLabel>
                      <TextField
                        id="end_date"
                        type="date"
                        name="end_date"
                        fullWidth
                        size="small"
                        value={values.end_date}
                        InputProps={{
                          inputProps: {
                            min: moment(values.start_date).format('YYYY-MM-DD'), // Establece la fecha mínima permitida
                            max: moment(selectedReservation.endDate).format('YYYY-MM-DD'), // Establece la fecha máxima permitida
                          },
                        }}
                        onChange={(e) => {handleChange(e); 
                          console.log(values.start_date)
                          console.log(values.end_date)
                          setFieldValue('days_recieve', moment(e.target.value).diff(values.start_date, 'days'))
                        }}
                      />
                    </Grid>
                      </>
                    }
                    
                  </Grid>
                </Grid>
                <Grid container item sx={{ marginTop: '30px' }}>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      disabled={loadingSubmit}
                      onClick={ () => handleSubmit() }
                      sx={{ backgroundColor: '#212D39', color: '#fff', height: '25px' , margin: '0px 20px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                      type="submit"
                    >
                      {
                        loadingSubmit && (
                          <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginRight: "10px" }} />
                        )
                      }
                      Guardar
                    </Button>
                    <Button
                      onClick={ () => handleCancel() }
                      style={{ color:'#73B2FF', border: '1px solid #73B2FF', borderRadius: '5px', height: '25px', margin: '0px 20px', padding: '6px 16px', textTransform: 'none', width: 160  }}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}}
        </Formik>
      </CustomFormBody>
    </CustomForm>
    {
      showModal && (
        <CessionAddThirdModal
          open={showModal}
          closeModal={ () => setShowModal(false) }
          setTypeAssignee={(e:any)=>setTypeAssignee(e ? 2 : "")}
        />
      )
    }
    </>
  )
}