import { useState } from 'react';
import { Box, Button, CircularProgress, Divider, Grid, IconButton, InputAdornment, Paper, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useAuthStore } from '../../hooks';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LocalStorageKey, RoutesMap } from '../../types';
import logo from '../../assets/image/Support.jpg';
import { Role } from '../../types/roles/roleTypes';
import { Formik } from 'formik';
import { useDepartmentStore } from '../../hooks/useDepartmentStore';
import { saveLocalStorage } from '../../toolbox/helpers/local-storage-helpers';
import { MODE_BUILDING_OWNER } from '../../toolbox/defaults/app';
import './Login.css';
import { ModalForgotPassword } from '../../components/common/ModalForgotPassword/ModalForgotPassword';

const themeAvenir = createTheme({
    typography: {
      fontFamily: 'Avenir',
      fontSize: 15
    },
})

const roles = [
  { id: 2, name: 'superadministrador' },
  { id: 1, name: 'administrador' },
  { id: 3, name: 'propietario' },
  { id: 4, name: 'invitado' },
  // {id:4, name: 'familiar'},
]

type Values = {
  rut: string,
  password: string,
  perfil: number|undefined|null,
  idcompany: number|undefined|null,
}

export const LoginView: React.FC<any> = (props:any): JSX.Element | any => {

  const { login } = useAuthStore();
  const { getBuilding, getDepartments } = useDepartmentStore()
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [selectedRol, setSelectedRol] = useState<any>('administrador');
    const [selectedRolNum, setSelectedRolNum] = useState<any>(2);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showModalForgotPassword, setShowModalForgotPassword] = useState<boolean>(false);
    const [data, setData] = useState<Values>({
        rut: '',
        password: '',
        perfil: 2,
        idcompany: 1
    });

    const handleChangeTypeUser = (event: React.SyntheticEvent, role_user: string) => {
      switch (role_user) {
        case Role.SUPER_ADMIN:
          setData(prev => ({ ...prev, perfil: 1, idcompany: null }))
          setSelectedRolNum(1);
          break
        case Role.ADMIN:
          setData(prev => ({ ...prev, perfil: 2, idcompany: 1 }))
          setSelectedRolNum(2);
          break
        case Role.OWNER:
          setData(prev => ({ ...prev, perfil: 3, idcompany: 1 }))
          setSelectedRolNum(3);
          break
        case Role.FAMILY:
          setData(prev => ({ ...prev, perfil: 4, idcompany: 1 }))
          setSelectedRolNum(4);
          break
        default:
          break
      }
      setSelectedRol(role_user);
    };

    const validateForm = (values) => {
      let errors:any = {};
      // let regRut = /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/;
      if(!values.rut) errors.rut = "rut requerido";
      // if(values.rut && !(regRut.test(values.rut))) errors.rut = "rut no válido";
      if(!values.password) errors.password = "contraseña requerida";
      return errors;
    }

    const handleClickShowPassword = () => {
      if(showPassword) setShowPassword(false)
      if(!showPassword) setShowPassword(true)
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
    }

    const onSubmit = async (values) => {
      const { rut, password, perfil, idcompany } = values
      onSendLogin(perfil, password, rut, idcompany);
  }
  
  const getDepartamentsByOwner = (data:any,role:any) => {
    const owner = data.data;

    if (owner) {
      let idowner:any;
      switch (role) {
        case Role.OWNER:
          idowner = owner.id;
          break;
        case Role.FAMILY:
          idowner = owner.idowner;
          break;
      }
      
      getDepartments({ idowner })
      if (MODE_BUILDING_OWNER === "building") getBuilding(idowner)
    }
  }
    
    const onSendLogin = async (perfil, password, rut, idcompany) => {
      setLoading(true)
      try {
        const success = await login({ iduser_type: perfil, password, rut, idcompany});

        if (!success?.status) {
          setLoading(false)
          return
        }

        const role = success?.detail?.userType;
        const data = success?.detail;
        const departments = data?.data?.department_owner_active || [];
        saveLocalStorage(LocalStorageKey.BUILDING_OWNER, data?.data?.building || []);


        const idCompany = data?.data?.idcompany || "";

        switch (role) {
          case Role.SUPER_ADMIN:
            navigate({ pathname: RoutesMap.COMPANIES}, { replace: true })
            break
          case Role.ADMIN:
            navigate({ pathname: RoutesMap.DEPARTAMENT}, { replace: true })
            break
          case Role.OWNER:
            if (departments.length > 0) {
              getDepartamentsByOwner(data,role);
              navigate({ pathname: RoutesMap.HOME }, { replace: true })
            } else {
              navigate({ pathname: RoutesMap.REQUEST_DEPARTMENTS }, { replace: true })
            }
            break
          case Role.FAMILY:
            if (departments.length > 0) {
              getDepartamentsByOwner(data,role);
              navigate({ pathname: RoutesMap.HOME }, { replace: true })
            } else {
              navigate({ pathname: RoutesMap.REQUEST_DEPARTMENTS }, { replace: true })
            }
            break
          default:
            break
        }
        setLoading(false)
      } catch (error) {
          setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }))
          setLoading(false)
      }
    }
        
    return (
      <>
        <Box className='login-main-container'>
          <Grid container justifyContent="center">

            <Grid  item xs={12} sx={{backgroundColor:'#212D39', height:'60px'}}>
              <Tabs  
                sx={{ maxWidth: { xs: 380, sm: 480, md:600, lg:600, xl:600 }, color:'#FFF'}} 
                value={selectedRol} 
                onChange={handleChangeTypeUser} 
                variant="scrollable" 
                scrollButtons allowScrollButtonsMobile
              >
                {
                  roles && roles.map((role) => (
                    <Tab sx={{color:'#fff', fontSize:'11px'}} value={role.name} label={role.name} />
                  ))
                }
              </Tabs>
            </Grid>

            <Grid item xs={12} className='login-container' 
              sx={{ padding: {md:'30px 200px 30px 100px', lg:'30px 200px 30px 100px', xl: '30px 200px 30px 100px'}}} >
              <Grid item xs={12} sm={12} md={5} lg={5} 
              sx={{textAlign: { md: 'end', xs: 'start'}, display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}> 
                <img style={{ width: '300px' }} src={logo} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} p={ {md: '0px 5px', xs: '0px 20px', lg:'0px 80px 0px 0px'}}>
                <Paper elevation={5}  sx={{ borderRadius: '16px'}}>
                    <Grid xs={12} sx={{height:'40px', backgroundColor:'#212D39', borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}></Grid>
                    <Grid sx={{padding:'20px 60px'}}>
                      {/* Title */}
                      <Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="start">
                            <Typography variant='h5' color='#212D39' sx={{ fontWeight:600, margin: '0px auto'}}>Ingresa tu Usuario</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="start">
                            <Typography variant='body2' color='#808080' sx={{ fontWeight:400, paddingBottom: 3}}>Si ya eres parte de nosotros, ingresa tus datos:</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                        {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                          return (
                            <form onSubmit={handleSubmit}>
                                <ThemeProvider theme={themeAvenir}>
                                  <Grid container spacing={2}>
                                    <Grid item container xs={12} spacing={2}>
                                      <Grid item xs={12}>
                                        <TextField
                                          id="rut"
                                          name='rut'
                                          fullWidth
                                          variant='outlined'
                                          value={values.rut}
                                          onChange={handleChange}
                                          placeholder='Ingrese su RUT'
                                          size='small'
                                          type="text"
                                          InputProps={{
                                              inputProps: {
                                                maxLength: 10
                                            },
                                            startAdornment: (
                                              <InputAdornment position='start'>
                                                <PersonIcon sx={{ color: '#29333B' }} />
                                              </InputAdornment>)
                                          }}
                                          error={errors.rut && touched.rut ? true : false}
                                          helperText={errors.rut && touched.rut ? errors.rut : ''}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          id="password"
                                          name="password"
                                          fullWidth
                                          variant='outlined'
                                          type={showPassword ? 'text' : 'password'}
                                          value={values.password}
                                          onChange={handleChange}
                                          size='small'
                                          placeholder='Ingresa su clave'
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position='start'  >
                                                <LockIcon sx={{ color: '#29333B' }} />
                                              </InputAdornment>),
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleClickShowPassword}
                                                  onMouseDown={handleMouseDownPassword}
                                                  edge="end"
                                                  sx={{ color: '#29333B' }}
                                                >
                                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                          error={errors.password && touched.password ? true : false}
                                          helperText={errors.password && touched.password ? errors.password : ''}
                                        />
                                      </Grid>

                                      <Grid item xs={12} display='flex' justifyContent='center'>
                                        <a 
                                          style={{cursor:'pointer', textDecoration:'underline', fontSize:'13px'}}
                                          onClick={()=>{setShowModalForgotPassword(true)}}
                                        >
                                          He olvidado mi contraseña
                                        </a>
                                      </Grid>

                                      <Grid item xs={12} display='flex' justifyContent='center'>
                                        <Button
                                          type="submit"
                                          disabled={loading}
                                          fullWidth
                                          size="small"
                                          variant="contained"
                                          sx={{ width:'150px', background:'#73B2FF',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}}
                                          onClick={()=>{handleSubmit()}}
                                        >
                                          { loading ? <CircularProgress size={28} /> :  'Continuar' }
                                        </Button>
                                      </Grid>

                                      <Grid item xs={12} display='flex' justifyContent='center'>
                                        {/* <Button
                                          fullWidth
                                          size="small"
                                          variant="contained"
                                          sx={{ width:'150px', background:'#FFFFFF',color:'#73B2FF', border:'solid 1px #73B2FF', '&:hover':{bgcolor:'#73B2FF', color:'#FFFFFF'}}}
                                        >
                                          Crear cuenta
                                        </Button> */}
                                      </Grid>

                                      <Grid item xs={12}>
                                        <Divider variant="middle" />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </ThemeProvider>
                            </form>
                          )
                        }} 
                      </Formik>
                    </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {
          showModalForgotPassword && (
            <ModalForgotPassword
                open={showModalForgotPassword}
                closeModal={()=>{setShowModalForgotPassword(false)}}
                role={selectedRolNum}
            />
          )
        }
      </>
    )
}
