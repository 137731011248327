import { Grid, Typography, Card, Button, Skeleton, CircularProgress, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { UploadTranfersModal } from './components/UploadTranfersModal';
import { useNavigate } from 'react-router-dom';
import { RoutesMap } from '../../types';
import { moneyFormat } from '../../toolbox/helpers/money-helpers';
import { RefusedDebtModal } from './components/RefusedDebtModal';
import { useDepartmentStore } from '../../hooks/useDepartmentStore';
import { useRenditionStore } from '../../hooks/useRenditionStore';
import { ApiStatus } from '../../types/api/status';
import { useAuthStore } from '../../hooks';
import { useRenditionBuildingStore } from '../../hooks/useRenditionBuildingStore';
import { MODE_BUILDING_OWNER } from '../../toolbox/defaults/app';
import { UploadTranfersBuildingModal } from '../RenditionBuilding/components/UploadTransferBuildingModal';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const RenditionView = () => {

    const navigate = useNavigate();
    const { status: renditionStatus, renditionOwner ,getRenditionOwner, getRenditionOwnerPDF } = useRenditionStore()
    const { renditionBuildingOwner, getRenditionBuildingOwner, getRenditionDetailOwnerPDF } = useRenditionBuildingStore()
    const { selectedDepartment, selectedBuildingOwner } = useDepartmentStore()
    const { user } = useAuthStore()
    const idowner:any = user?.data?.id
    
    const [showModalTransfer, setShowModalTransfer] = useState<boolean>(false);
    const [showModalRefusedDebt, setShowModalRefusedDebt] = useState<boolean>(false);
    const [ downloading, setDownloading ] = useState<boolean>(false);
    const [ renditionList, setRenditionList ] = useState<any>(null);

    useEffect(()=>{
      validateRendition();
    }, [])
  
    useEffect(()=>{
      if (MODE_BUILDING_OWNER === "building") {
        setRenditionList(renditionBuildingOwner)
      } else {
        setRenditionList(renditionOwner)
      }
    },[renditionOwner,renditionBuildingOwner])

  const validateRendition = () => {    
      if (MODE_BUILDING_OWNER === "building") {
        getRenditionBuildingOwner({iddepartment: selectedDepartment?.id, idbuilding: selectedBuildingOwner?.idbuilding})
      } else {
        getRenditionOwner(selectedDepartment?.id, idowner)
      }
    }

    const downloadPDF = async () => {
      setDownloading(true)
      let response;
      if (MODE_BUILDING_OWNER === "building") {
        response = await getRenditionDetailOwnerPDF(renditionList?.id, idowner)
      } else {
        response = await getRenditionOwnerPDF(renditionList?.id, idowner)
      }
        if (response !== false) {
            const blob = new Blob([response], { type: 'application/pdf' })
            const bloblURL = URL.createObjectURL(blob)
            window.open(bloblURL, '_blank')
        }
        setDownloading(false)
    }

    return (
        <>
        {
            renditionStatus === ApiStatus.FETCHED
            ?
            <Grid item container sx={{padding:'30px 50px 30px 50px'}}>
                {
                renditionList?.period && renditionList.message !== 'No hay deuda pendiente'
                ?
                <>
                    <Grid xs={12} display='flex' justifyContent='center' alignItems='center' pb={3}>
                        <Typography color='#808080' sx={{ fontSize:18, fontWeight:600}}>
                        {'Rendición de: ' + selectedDepartment.name}
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={10} lg={8} sx={{ margin: '0 auto' }}>
                        <Card sx={{ display:'flex', justifyContent:'center', alignItems:'center', border:'solid 1px #808080', padding:'30px', width:'100%' }}>
                            <Grid container display='flex' justifyContent='center' alignItems='center'>
                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <Grid item mb={2} display='flex' justifyContent='start' alignItems='center'>
                                        <Typography color='#212D39' sx={{ fontSize:18, fontWeight:600, textAlign: 'start'}}>
                                            {'Rendición de ' + renditionList?.period?.period}
                                        </Typography>
                                    </Grid>
                                    {
                                        renditionList?.data?.map(value => {
                                            return (
                                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='10px'>
                                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                        <Typography variant='body1' color='#000000' sx={{ fontWeight:500, pr:2, width:210 }}>{ value.category_name + ':' }</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                        <Typography variant='body1'>{moneyFormat(value.total_amount, '$')}</Typography>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='10px'>
                                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                            <Typography variant='h6' color='#808080' sx={{ fontWeight:600, pr:2, width:210 }}>Total de gastos:</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                            <Typography variant='body1' color='#101010'>{moneyFormat(renditionList?.total, '$')}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7} lg={7}>
                                    <Grid sx={{border: 'solid 1px #212D39', borderRadius:'40px', padding:'15px 5px 15px 5px'}}>
                                        <Grid display='flex' justifyContent='center' alignItems='center' mb={2}>
                                            <Typography color='#808080' sx={{ fontSize:18, fontWeight:600, textAlign: 'center'}}>
                                                {'TOTAL A PAGAR'}
                                            </Typography>
                                        </Grid>
                                        <Grid display='flex' justifyContent='center' alignItems='center'>
                                            <Typography variant='h2' color='#212D39' sx={{ fontWeight:700}}>{moneyFormat(renditionList?.total, '$')}</Typography>
                                        </Grid>
                                        {
                                            (renditionList.message === 'Aun no hay pago registrado' || renditionList.message === 'Su pago aun no ha sido aprobado'
                                            || renditionList.message === 'No hay deuda pendiente' || renditionList.message === 'No ha pagado toda la rendición') && (
                                                <Grid display='flex' justifyContent='center' alignItems='center'>
                                                    <Typography color='#000000' sx={{ fontSize:15, fontWeight:500, textAlign: 'center'}}>
                                                        { renditionList.pending_payment ? 'Su pago aun no ha sido aprobado' : renditionList.message }
                                                    </Typography>
                                                </Grid>
                                            )
                                        }
                                        {
                                            renditionList.message === 'Su pago ha sido rechazado' && (
                                                <Grid display='flex' justifyContent='center' alignItems='center'>
                                                    <Typography color='#05B705' sx={{ fontSize:15, fontWeight:500}}>
                                                        {renditionList.message || ''}
                                                    </Typography>
                                                </Grid>
                                            )
                                        }
                                        {/* 
                                        {
                                            data.state == 'refused' && (
                                                <>
                                                <Grid display='flex' justifyContent='center' alignItems='center'>
                                                    <Typography color='#FA2305' sx={{ fontSize:15, fontWeight:500}}>
                                                        {'La deuda fue rechazada'}
                                                    </Typography>
                                                </Grid>
                                                <Grid onClick={()=>{setShowModalRefusedDebt(true)}} display='flex' justifyContent='center' alignItems='center' sx={{cursor:'pointer'}}>
                                                    <Typography color='#808080' sx={{ fontSize:14, fontWeight:400}}>
                                                        {'Más información'}
                                                    </Typography>
                                                </Grid>
                                                </>
                                            )
                                        } */}
                                        
                                    </Grid>
                                    <Grid container spacing={2} padding='15px 20px 0px 20px' display='flex' justifyContent='center'>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Button onClick={()=>{ downloadPDF() }} size="small" variant="contained" sx={{background:'#73B2FF',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}} fullWidth>
                                                {
                                                    downloading 
                                                    ?
                                                    <CircularProgress color="inherit" size={20} sx={{ color: '#fff', marginRight: "10px" }} />
                                                    :
                                                    'Descargar recibo' 
                                                }
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Button onClick={()=>{setShowModalTransfer(true)}} size="small" variant="contained" sx={{background:'#73B2FF',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}} fullWidth
                                                disabled={renditionList.pending_payment || false}
                                            >
                                                {'Subir transferencia'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    </>
                    :
                    <Grid sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', marginBottom:'10px', width: '100%'}}>
                        <Div>{"NO HAY RENDICIÓN VIGENTE"}</Div>
                    </Grid>
                }
                <Grid xs={12} display='flex' justifyContent='end' alignItems='end' pt={3}>
                    <Button onClick={() => navigate({ pathname: RoutesMap.RENDITION_HISTORY})} size="small" variant="contained" sx={{background:'#fff',color:'#212D39', '&:hover':{bgcolor:'#808080', color:'#fff'}, border:'solid 1px #808080'}}>
                        {'Historial de rendiciones'}
                    </Button>
                </Grid>
            </Grid>
            :
            <>
                <Grid item container sx={{padding:'30px 50px 30px 50px'}}>
                    <Grid item xs={8} sm={5} md={3} sx={{ margin: '0 auto' }} >
                        <Skeleton width="100%" sx={{ fontSize: '1rem' }} variant="text"/>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={8} sm={8} md={8} sx={{ margin: '0 auto' }}>
                        <Skeleton variant="rectangular" width="100%" height={250} />
                    </Grid>
                </Grid>
                <Grid item container display='flex' justifyContent='end' alignItems='end' pt={3}>
                    <Grid item xs={8} sm={4} md={2} sx={{ marginRight: '40px'}}>
                        <Skeleton variant="rectangular" width="100%" height={20} />                    
                    </Grid>
                </Grid>
            </>
        }
        {
            showModalTransfer && (
              MODE_BUILDING_OWNER === "building" ? (
                <UploadTranfersBuildingModal
                  open={showModalTransfer}
                  closeModal={()=>{setShowModalTransfer(false)}}
                  isAdmin={false}
                />
              ) : (
                <UploadTranfersModal
                    open={showModalTransfer}
                    closeModal={()=>{setShowModalTransfer(false)}}
                    isAdmin={false}
                />
              )
            )
        }
        {
            showModalRefusedDebt && (
                <RefusedDebtModal
                    open={showModalRefusedDebt}
                    closeModal={()=>{setShowModalRefusedDebt(false)}}
                />
            )
        }
        </>
    )
}