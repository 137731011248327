import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, FormLabel, Autocomplete, InputAdornment, Checkbox, Typography, Button, FormControlLabel } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useDepartmentStore } from "../../hooks/useDepartmentStore"
import { useNavigate } from "react-router-dom"
import { Department } from "../../types/slices/departmentType"
import { useOwnerStore } from "../../hooks/useOwnerStore"
import { useServiceStore } from "../../hooks/useServiceStore"
import { Service } from "../../types/slices/serviceSlice"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { ApiStatus } from "../../types/api/status"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"

type DepartmentDataForm = {
    id: number
    name: string
    address: string
    location_url: string
    capacitance: number
    idsowner: number[] 
    num_rooms: number
    num_bathrooms: number 
    num_kitchen: number 
    dimensions: number
    check_in?: string | null
    check_out?: string | null
    idservices: number[]
    idcompany: number | null
    num_owner: number
}

export const DepartamentForm = (props) => {

    const navigate = useNavigate()

    const { status: statusDepartament, selectedDepartment, action, createDepartment, setSelectedDepartment, editDepartmentAll, setActionDepartment } = useDepartmentStore()
    const { status: statusOwnerApi, owners, getOwners }     = useOwnerStore()
    const { status: statusServiceApi, services, getServices } = useServiceStore()
    
    const [ selectedServices, setSelectedServices ] = useState<Service[]>([])
    const [ selectedOwners, setSelectedOwners ]     = useState<any>([])

    const [ownersToCustomizePercentage, setOwnersToCustomizePercentage] = useState<any>([]);
    const [checked, setChecked] = useState<string>('custom');
    const [previewLoading, setPreviewLoading] = useState<boolean>(false);
    const [showDistribution, setShowDistribution] = useState<any>('');
    const [btnSend, setBtnSend] = useState<any>('custom');

    const [percentagetaken, setpercentagetaken] = useState<any>(0)

    const [ data, setData ] = useState<DepartmentDataForm>({
        id: 0,
        name: "",
        address: "",
        location_url: "",
        capacitance: 0,
        idsowner: [],
        num_rooms: 0,
        num_bathrooms: 0,
        num_kitchen: 0,
        dimensions: 0,
        check_in: null,
        check_out: null,
        idservices: [],
        idcompany: null,
        num_owner: 1
    });

    useEffect(()=>{
        if(!selectedDepartment.id && action=='') {
            navigate({ pathname: RoutesMap.DEPARTAMENT}, { replace: true })
        }
    }, [!selectedDepartment.id])

    useEffect(()=>{
        getOwners()
        getServices()
        // if(owners.length > 0 ){
        //     // setSelectedOwners( owners.filter(item => item.is_admin === "1"))
        //     let arrayOwnerInput:any = [];
        //     owners.map((item)=>{
        //         const newOwner:any = {id: item.id, name: item.name, inputactive: false, percentage: '', is_admin: item.is_admin}
        //         arrayOwnerInput.push(newOwner)
        //     })
        //     setOwnersToCustomizePercentage(arrayOwnerInput);
        // }

    }, [])

    useEffect(()=>{
        if(selectedDepartment.id && owners.length > 0 && services.length > 0) {
            const split1 = selectedDepartment?.check_in?.split(' ')  || '' 
            const split2 = selectedDepartment?.check_out?.split(' ') || ''
            setData({
                id: selectedDepartment.id,
                name: selectedDepartment.name,
                address: selectedDepartment.address,
                location_url: selectedDepartment.location_url,
                capacitance: selectedDepartment.capacitance,
                idsowner: selectedDepartment.idsowner,
                num_rooms: selectedDepartment.num_rooms,
                num_bathrooms: selectedDepartment.num_bathrooms,
                num_kitchen: selectedDepartment.num_kitchen,
                dimensions: selectedDepartment.dimensions,
                check_in: split1[0],
                check_out: split2[0],
                idservices: selectedDepartment.idservices,
                idcompany: selectedDepartment.idcompany,
                num_owner: selectedDepartment.num_owner
            })
            setSelectedOwners(setCustomOwner(selectedDepartment.idsowner, owners) || [])
            setSelectedServices(setCustomService(selectedDepartment.idservices, services) || [])
        }
    },[selectedDepartment.id && owners.length > 0 && services.length > 0])

    /* custom owners */
    const setCustomOwner = (idowners, originalowners) => {
        const data = idowners ? idowners.map( idowner => originalowners.find(value => value.id == idowner)) : [];
        return getCustomOwners(data);
    }
    const getCustomOwners = (originalOwners) => {
        const res = originalOwners ? originalOwners.map(owner => ({...owner})) : [];
        return originalOwners ? originalOwners.map(owner => ({...owner})) : [];
    }
    const customOwners = getCustomOwners(owners || []);

    /* custom services */
    const setCustomService = (idservices, originalservices) => {
        const data = idservices ? idservices.map( idservice => originalservices.find(value => value.id == idservice)) : [];
        return getCustomOwners(data);
    }
    const getCustomServices = (originalServices) => {
        const res = originalServices ? originalServices.map(service => ({...service})) : [];
        return originalServices ? originalServices.map(service => ({...service})) : [];
    }
    const customServices = getCustomServices(services || []);

    const onCancel = ()  => {
        navigate({ pathname: RoutesMap.DEPARTAMENT })
    }

    const clearState = () => {
        setSelectedDepartment({} as Department)
        setSelectedServices([])
        setSelectedOwners([])
        setActionDepartment('')
    }

    const handleKeyDown = (event) => {
        const keyCode = event.keyCode || event.which;
        if (keyCode === 189 || keyCode === 109) {
          event.preventDefault();
        }
    };

    const handleKeyDownPercentage = (event) => {
        const keyCode = event.keyCode || event.which;
        if (keyCode === 189 || keyCode === 109 || parseInt(event.target.value + event.key) > 100) {
          event.preventDefault();
        }
    };

    const validateForm = (values) => {
        let errors:any = {};
        const regex = /^[0-9\b]+$/;
        if(!values.name) errors.name = "nombre requerido";
        if(!values.address) errors.address = "direccion requerido";
        if(!values.location_url) errors.location_url = "url requerido";
        if(!values.capacitance) errors.capacitance = "capacidad requerido";
        if(values.capacitance && !(regex.test(values.capacitance))) errors.capacitance = "cantidad no válida";
        if(!values.num_rooms) errors.num_rooms = "cantidad requerido";
        if(values.num_rooms && !(regex.test(values.num_rooms))) errors.num_rooms = "cantidad no válida";
        if(!values.num_bathrooms) errors.num_bathrooms = "cantidad requerido";
        if(values.num_bathrooms && !(regex.test(values.num_bathrooms))) errors.num_bathrooms = "cantidad no válida";
        if(!values.num_kitchen) errors.num_kitchen = "cantidad requerido";
        if(values.num_kitchen && !(regex.test(values.num_kitchen))) errors.num_kitchen = "cantidad no válida";
        if(!values.dimensions) errors.dimensions = "dimensiones requerido";
        if(!values.num_kitchen) errors.num_kitchen = "cantidad requerido";
        if(values.num_kitchen && !(regex.test(values.num_kitchen))) errors.num_kitchen = "cantidad no válida";
        if(!values.num_owner) errors.num_owner = "cantidad requerido";
        return errors;
    }

    const selectedOner = (owner, values) => {
        if(values.num_owner == 0) return CustomSnackbar('warning', 'El N° de propietarios no puede ser 0.')
        if(owner.length > values.num_owner) return CustomSnackbar('warning', 'La cantidad de propietarios seleccionados no puede ser mayor al n° de propietarios.')
        
        // if(!(owner.find(item => item.is_admin === "1"))) return CustomSnackbar('warnung', 'No se puede eliminar el propietario por defecto.')
        setSelectedOwners(owner);
        if(owner && owner.length > 0){
            let arrayOwnerInput:any = [];
            owner.map((item)=>{
                const newOwner:any = {id: item.idowner, name: item.name, inputactive: false, percentage: '10', is_admin: item.is_admin}
                arrayOwnerInput.push(newOwner)
            })
            setOwnersToCustomizePercentage(arrayOwnerInput);
        }
    }
    console.log(ownersToCustomizePercentage)

    const handleActiveInput = (e, element:any) => {
        e.preventDefault();
        setpercentagetaken('')
        if (ownersToCustomizePercentage.some(owner => owner.id == element.idowner)) {
            const currentOwner = ownersToCustomizePercentage.find(item => item.id === element.idowner);
            if(currentOwner){
                currentOwner.inputactive = currentOwner.inputactive ? false : true;
                currentOwner.percentage = !(currentOwner.inputactive) ? '' : 10;
                setOwnersToCustomizePercentage([...ownersToCustomizePercentage])
            }else{
                setOwnersToCustomizePercentage([...ownersToCustomizePercentage])
            }
        }
    };

    const activeChecked = (type) => {
        setShowDistribution('')
        setBtnSend('')
        if(type == 'equitable'){
            if(checked == 'equitable'){
                setChecked('')
                setBtnSend('')

            }else{
                setChecked('equitable')
                setBtnSend('equitable')

            }
        }else{
            if(checked == 'custom'){
                setChecked('')
                setBtnSend('')

            }else{
                setChecked('custom')
                setBtnSend('custom')

            }
        }
    }

    const customizeDistribution = (value, element) => {
        const updatedInputs = ownersToCustomizePercentage.map((input) => {
            console.log(input)
            console.log(element)
            if (input.id === element.id) {
              return { ...input, percentage: value };
            }
            return input;
        });
        console.log(updatedInputs)
      
        setOwnersToCustomizePercentage([...updatedInputs]);
    }

    const ShowEquitableDistribution = async(e, values) => {
        e.preventDefault();

        setPreviewLoading(true);
        if(values.num_owner == 0) { setChecked(''); return  CustomSnackbar('warning', 'El N° de propiertarios no puede ser 0.')}

        let isCompleted = false
        if(values.num_owner == selectedOwners.length - 1){
            isCompleted = true
        }
        
        let percentage =  parseFloat((100/(values.num_owner)).toFixed(2));

        let arrayPercentage:any = []

        for (let index = 0; index < values.num_owner; index++) {
            arrayPercentage.push(percentage)
        }

        if(isCompleted){
            arrayPercentage.unshift(0)
        }

        const a_idowner   = selectedOwners.map(owner => owner.id)
        const a_idservice = selectedServices.map(service => service.id)

        const formdata = {
            ...values,
            capacitance: (values.capacitance).toString(),
            dimensions: (values.dimensions).toString(),
            idsowner: JSON.stringify(a_idowner),
            idservices: JSON.stringify(a_idservice),
            distribution_percentage: arrayPercentage,
            distribution_example: true,
        }

        const response:any = await createDepartment(formdata);
        if(response.data){
            setShowDistribution(response.data.detail)
            setPreviewLoading(false);
            setBtnSend('equitable')
        }else{
            setShowDistribution('');
            setPreviewLoading(false);
            setBtnSend('')
            return CustomSnackbar('warning', 'Asegurese de completar todos los campos.')
        }
        setPreviewLoading(false);
    }

    const showCustomDistribution = async (e, values)  => {
        e.preventDefault();

        setPreviewLoading(true);
        const a_idowner   = selectedOwners.map(owner => owner.id)
        const a_idservice = selectedServices.map(service => service.id)

        const verify = ownersToCustomizePercentage.find(owner=> owner.inputactive && owner.percentage == '')
        console.log(verify)
        if(verify) return CustomSnackbar('warning', 'el campo de % no puede estar vacío.')

        //percentaje taken
        const percentaje_taken = ownersToCustomizePercentage.filter(owner=> owner.percentage != '');
        let sumpercentage = 0;
        if(percentaje_taken.length > 0){
            percentaje_taken.map((item, index) => {
                sumpercentage = sumpercentage + item.percentage;
            })
        }

        setpercentagetaken(sumpercentage)
      
        //array de %
        const arrayPercentage = ownersToCustomizePercentage.map(owner => owner.percentage)

        const formdata = {
            ...values,
            capacitance: (values.capacitance).toString(),
            dimensions: (values.dimensions).toString(),
            idsowner: JSON.stringify(a_idowner),
            idservices: JSON.stringify(a_idservice),
            distribution_percentage: arrayPercentage,
            distribution_example: true,
        }

        const response:any = await createDepartment(formdata);
        if(response.data){
            setShowDistribution(response.data.detail)
            setPreviewLoading(false);
            setBtnSend('custom')
        }else{
            setShowDistribution('');
            setPreviewLoading(false);
            setBtnSend('')
            return CustomSnackbar('warning', 'Asegurese de completar todos los campos.')
        }
        setPreviewLoading(false);

    }

    const onSubmit = async (values)  => {
        const a_idowner   = ownersToCustomizePercentage.map(owner => owner.id)
        const a_idservice = selectedServices.map(service => service.id)

        let formdata = {
            ...values,
            capacitance: (values.capacitance).toString(),
            dimensions: (values.dimensions).toString(),
            idsowner: JSON.stringify(a_idowner),
            idservices: JSON.stringify(a_idservice)
        }
        if(selectedDepartment.id){

            const a_idownerSelected = selectedOwners.map(owner => owner.id)
            formdata = {...formdata, idsowner: JSON.stringify(a_idownerSelected)}
            const response = await editDepartmentAll(selectedDepartment.id, formdata);
            if (response) {
                navigate({ pathname: RoutesMap.DEPARTAMENT })
                clearState()
            }
        }else{
            if(btnSend=='equitable'){
                if(values.num_owner == 0) { setChecked(''); return  CustomSnackbar('warning', 'El N° de propiertarios no puede ser 0.')}

                let isCompleted = false
                let divisor = a_idowner.lengt
                if(values.num_owner == a_idowner.length - 1){
                    isCompleted = true
                    divisor = a_idowner.lengt -1
                }
                
                let percentage =  parseFloat((100/divisor).toFixed(2));

                let arrayPercentage:any = []
                for (let index = 0; index < divisor.length; index++) {
                    arrayPercentage.push(percentage)
                }

                if(isCompleted){
                    arrayPercentage.unshift(0)
                } 
                const formdataEquitable = {
                    ...values,
                    capacitance: (values.capacitance).toString(),
                    dimensions: (values.dimensions).toString(),
                    idsowner: JSON.stringify(a_idowner),
                    idservices: JSON.stringify(a_idservice),
                    distribution_percentage: arrayPercentage,
                }
                const response:any = await createDepartment(formdataEquitable);
                if (response.status) {
                    navigate({ pathname: RoutesMap.DEPARTAMENT })
                    clearState()
                }
            }
            if(btnSend=='custom'){
                const verify = ownersToCustomizePercentage.find(owner=> owner.percentage == '')
                
                if( verify) return CustomSnackbar('warning', 'el campo de % no puede estar vacío.')


                let porcion = 100/Number(values.num_owner);
                if(porcion%1 !== 0)return CustomSnackbar('warning', 'Las porciones deben ser números enteros.')

                const percentageError = ownersToCustomizePercentage.find(owner => owner.percentage%porcion !== 0);

                if(percentageError) return CustomSnackbar('warning', 'el campo de % debe ser multiplo de 10.')
                //array de %

                const sumPercentageOwners = ownersToCustomizePercentage.reduce((accumulator, currentValue) => accumulator + Number(currentValue.percentage), 0);

                const percentageFractio = 100 - sumPercentageOwners
               
                const arrayPercentage = ownersToCustomizePercentage.map(owner => Number(owner.percentage))

                arrayPercentage.push(percentageFractio);

                const ownerFractio = customOwners.find(item => item.is_admin == "1");
                a_idowner.push(ownerFractio.id);

                const formdataCustom = {
                    ...values,
                    capacitance: (values.capacitance).toString(),
                    dimensions: (values.dimensions).toString(),
                    idsowner: JSON.stringify(a_idowner),
                    idservices: JSON.stringify(a_idservice),
                    distribution_percentage: arrayPercentage,
                }

                console.log(formdataCustom)

                const response:any = await createDepartment(formdataCustom);
                if (response.status) {
                    navigate({ pathname: RoutesMap.DEPARTAMENT })
                    clearState()
                }
            }
            if(btnSend==''){
                const response:any = await createDepartment(formdata);
                if (response.status) {
                    navigate({ pathname: RoutesMap.DEPARTAMENT })
                    clearState()
                }
            }
            
        }
    }

    return (
        <>
        { statusOwnerApi === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        { statusServiceApi === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        { statusDepartament === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        <CustomForm >

            <CustomFormHeader
                title={!selectedDepartment.id ? 'Nuevo Departamento' : 'Editar Departamento'}
                text={!selectedDepartment.id ? 'Ingrese los datos del nuevo Departamento' : 'Ingrese los datos del Departamento'}
                goBack={RoutesMap.DEPARTAMENT}
                clearState={clearState}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values)} onSubmit={onSubmit} >
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Nombre:</FormLabel>
                                            <TextField
                                                id="name"
                                                type="text" 
                                                name="name"
                                                fullWidth
                                                size="small"
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name && touched.name ? true : false}
                                                helperText={errors.name && touched.name ? errors.name : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Dirección:</FormLabel>
                                            <TextField
                                                id="address"
                                                type="text" 
                                                name="address"
                                                fullWidth
                                                size="small"
                                                value={values.address}
                                                onChange={handleChange}
                                                error={errors.address && touched.address ? true : false}
                                                helperText={errors.address && touched.address ? errors.address : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Locación:</FormLabel>
                                            <TextField
                                                id="location_url"
                                                type="text" 
                                                name="location_url"
                                                fullWidth
                                                size="small"
                                                value={values.location_url}
                                                onChange={handleChange}
                                                error={errors.location_url && touched.location_url ? true : false}
                                                helperText={errors.location_url && touched.location_url ? errors.location_url : ''}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container xs={12} spacing={2} sx={{marginTop:'5px'}}>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Capacidad:</FormLabel>
                                            <TextField
                                                id="capacitance"
                                                type="number"
                                                name="capacitance"
                                                fullWidth
                                                size="small"
                                                value={values.capacitance}
                                                onChange={handleChange}
                                                error={errors.capacitance && touched.capacitance ? true : false}
                                                helperText={errors.capacitance && touched.capacitance ? errors.capacitance : ''}
                                                onKeyDown={handleKeyDown}
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>N° Habitaciones:</FormLabel>
                                            <TextField
                                                id="num_rooms"
                                                type="number" 
                                                name="num_rooms"
                                                fullWidth
                                                size="small"
                                                value={values.num_rooms}
                                                onChange={handleChange}
                                                error={errors.num_rooms && touched.num_rooms ? true : false}
                                                helperText={errors.num_rooms && touched.num_rooms ? errors.num_rooms : ''}
                                                onKeyDown={handleKeyDown}
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>N° Baños:</FormLabel>
                                            <TextField
                                                id="num_bathrooms"
                                                type="number" 
                                                name="num_bathrooms"
                                                fullWidth
                                                size="small"
                                                value={values.num_bathrooms}
                                                onChange={handleChange}
                                                error={errors.num_bathrooms && touched.num_bathrooms ? true : false}
                                                helperText={errors.num_bathrooms && touched.num_bathrooms ? errors.num_bathrooms : ''}
                                                onKeyDown={handleKeyDown}
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>N° Cocinas:</FormLabel>
                                            <TextField
                                                id="num_kitchen"
                                                type="number" 
                                                name="num_kitchen"
                                                fullWidth
                                                size="small"
                                                value={values.num_kitchen}
                                                onChange={handleChange}
                                                error={errors.num_kitchen && touched.num_kitchen ? true : false}
                                                helperText={errors.num_kitchen && touched.num_kitchen ? errors.num_kitchen : ''}
                                                onKeyDown={handleKeyDown}
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Dimensión:</FormLabel>
                                            <TextField
                                            id="dimensions"
                                            type="number"
                                            name="dimensions"
                                            fullWidth
                                            size="small"
                                            value={values.dimensions}
                                            onChange={handleChange}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                            error={errors.dimensions && touched.dimensions ? true : false}
                                            helperText={errors.dimensions && touched.dimensions ? errors.dimensions : ''}
                                            onKeyDown={handleKeyDown}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Servicio:</FormLabel>
                                            <Autocomplete
                                                multiple={true}
                                                disablePortal
                                                id="combo-box-demo"
                                                value={selectedServices|| []}
                                                options={customServices || []}
                                                getOptionLabel={(option) => option?.name || ""}
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                                // renderInput={(params) => <TextField {...params} size="small" />}
                                                renderInput={(params) => <TextField 
                                                    {...params} 
                                                    size='small'
                                                    error={errors.idservices && touched.idservices ? true : false}
                                                    helperText={errors.idservices && touched.idservices ? errors.idservices : ''}
                                                />}
                                                onChange={(e, newValue) => {
                                                    setSelectedServices(newValue);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} value={option.id} {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={selected}
                                                        />
                                                        { option.name }
                                                    </li>
                                                )}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>N° Propietarios:</FormLabel>
                                            <TextField
                                                id="num_owner"
                                                type="number" 
                                                name="num_owner"
                                                fullWidth
                                                size="small"
                                                value={values.num_owner}
                                                onChange={(e)=>{handleChange(e); setChecked('custom'); setShowDistribution('')}}
                                                error={errors.num_owner && touched.num_owner ? true : false}
                                                helperText={errors.num_owner && touched.num_owner ? errors.num_owner : ''}
                                                InputProps={{
                                                    readOnly: !!selectedDepartment.id,
                                                    inputProps: {
                                                        min: 0
                                                    }
                                                }}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={8}>
                                            <FormLabel>Propietarios:</FormLabel>
                                            <Autocomplete
                                                multiple={true}
                                                disablePortal
                                                id="combo-box-demo"
                                                value={selectedOwners|| []}
                                                options={customOwners.filter(item => item.is_admin !== "1") || []}
                                                getOptionLabel={(option) => option?.name || ""}
                                                disabled={selectedDepartment.id ? true : false}
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                onChange={(e, newValue) => {
                                                    selectedOner(newValue, values);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} value={option.id} {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={selected}
                                                        />
                                                        { option.name }
                                                    </li>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    {
                                        !selectedDepartment.id && (
                                            <Grid item container xs={12} spacing={2} sx={{marginTop:'5px'}}>
                                                {
                                                    values.num_owner > 0 && (
                                                        <Grid item xs={12}>
                                                            <Grid xs={12}>
                                                                {/* <FormControlLabel control={<Checkbox 
                                                                    checked={checked == 'equitable' ? true : false} 
                                                                    onClick={(e)=>{activeChecked('equitable')}}/>} 
                                                                    label="Distribuir porcentajes equitativamente" 
                                                                /> */}
                                                                {/* {
                                                                    checked == 'equitable' && (
                                                                        <button onClick={(e)=>{ShowEquitableDistribution(e, values)}}>ver distribucion</button>
                                                                    )
                                                                } */}
                                                                {/* {
                                                                    !previewLoading && showDistribution.length > 0 && checked == 'equitable' && (
                                                                        <>
                                                                        <Grid sx={{paddingLeft:'20px'}}>
                                                                            <Typography color='#575454' sx={{ fontSize:15, fontWeight:100}}>
                                                                                {'cada propietario tendra una distribución del ' + showDistribution[showDistribution.length -1]  + '%'}
                                                                            </Typography>
                                                                        </Grid>
                                                                        </>
                                                                    )
                                                                } */}
                                                                {
                                                                    previewLoading && checked == 'equitable' && (
                                                                        <Grid sx={{paddingLeft:'20px'}}>
                                                                            <Typography color='#575454' sx={{ fontSize:15, fontWeight:100}}>
                                                                                {'Espere un momento...'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    )
                                                                }
                                                    
                                                            </Grid>
                                                            {
                                                                selectedOwners.length > 0 && (
                                                                <Grid xs={12} sx={{
                                                                    marginTop: { xs: '15px', sm: '15px', md: '0px', lg: '0px', xl: '0px'}
                                                                }}>
                                                                    {/* <FormControlLabel control={<Checkbox 
                                                                        checked={checked == 'custom' ? true : false} 
                                                                        onClick={(e)=>{activeChecked('custom')}}/>} 
                                                                        label="Personalizar distribución de porcentajes" 
                                                                    /> */}
                                                                    {
                                                                        checked == 'custom' && (
                                                                        <>
                                                                        <Grid item 
                                                                            alignItems='center' 
                                                                            xs={12} 
                                                                            sx={{
                                                                                display:{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex'}
                                                                            }}>
                                                                            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                                                                <>
                                                                                {ownersToCustomizePercentage.map((element, index) => (
                                                                                    <Grid key={index} display='flex' alignItems='center' mb={3} sx={{border:'solid 1px #f5e9e9', padding:'8px', width:'100%'}}>
                                                                                        <Typography color='#575454' sx={{ width: '60%', fontSize:15, fontWeight:100}}>
                                                                                            {element.name} 
                                                                                        </Typography>
                                                                                        {
                                                                                            (   ownersToCustomizePercentage.length > 0 
                                                                                                // &&  ownersToCustomizePercentage.some(owner => owner.id == element.idowner && owner.inputactive==true) 
                                                                                                ) &&  (
                                                                                                <TextField
                                                                                                    type="number"
                                                                                                    // onChange={handleChange}
                                                                                                    value={element.percentage}
                                                                                                    sx={{ 
                                                                                                        width: { xs: '130px', sm: '130px', md: '90px', lg: '90px', xl: '90px' }, 
                                                                                                        margin: '0px 10px'  
                                                                                                    }}
                                                                                                    onKeyDown={handleKeyDownPercentage}
                                                                                                    size="small"
                                                                                                    InputProps={{
                                                                                                        inputProps: {
                                                                                                            min: 0,
                                                                                                            max: 100,
                                                                                                            step: 10,
                                                                                                        }
                                                                                                    }}
                                                                                                    onChange={(e)=>{customizeDistribution(e.target.value, element)}}
                                                                                                    // placeholder={'10'}
                                                                                                    
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                        {/* <button onClick={(e) => {handleActiveInput(e, element)}}>personalizar %</button> */}
                                                                                    </Grid>
                                                                                ))}
                                                                                
                                                                                </>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{paddingLeft:'25px'}}>
                                                                                {
                                                                                    !previewLoading && showDistribution.length > 0 && checked == 'custom' && ownersToCustomizePercentage.find(item => item.inputactive==true) && percentagetaken!='' &&(
                                                                                        <>
                                                                                        <Grid sx={{
                                                                                            paddingLeft:{ xs: '0px', sm: '0px', md: '20px', lg: '20px', xl: '20px'}
                                                                                        }}>
                                                                                            <Typography color='#575454' sx={{ fontSize:15, fontWeight:100}}>
                                                                                                {'- Queda ' + (100 - percentagetaken) + '% para ser dividido con los propietarios restantes.'}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    previewLoading && checked == 'custom' && (
                                                                                        <Grid sx={{paddingLeft:'20px'}}>
                                                                                            <Typography color='#575454' sx={{ fontSize:15, fontWeight:100}}>
                                                                                                {'Espere un momento...'}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    )
                                                                                }
                                                                                
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid xs={12} sx={{
                                                                            marginTop: { xs: '15px', sm: '15px', md: '0px', lg: '0px', xl: '0px'}
                                                                        }}>
                                                                            {
                                                                                ownersToCustomizePercentage.find(item => item.inputactive==true) && (
                                                                                    <Grid xs={12}>
                                                                                        <Button onClick={(e)=>{showCustomDistribution(e, values)}} size="small" color={"primary"}>
                                                                                            {'Ver distribución'}
                                                                                        </Button>
                                                                                    </Grid>
                                                                                )
                                                                            }
                                                                        </Grid>
                                                                        </>
                                                                        )
                                                                    }
                                                                </Grid>
                                                                )
                                                            }
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                        )
                                    }

                                </Grid>

                                <Divider />
                                {
                                    btnSend=='custom' && (
                                        <CustomFormFooter 
                                            buttonType="submit"
                                            confirmText={ !selectedDepartment.id ? 'Guardar' :  'Actualizar' }
                                            cancelText={"Cancelar"}
                                            onConfirm={handleSubmit}
                                            onCancel={onCancel}
                                            clearState={clearState}
                                        />
                                    ) 
                                }

                                {
                                    (btnSend=='equitable' || btnSend=='') && checked=='equitable' && (
                                        <CustomFormFooter 
                                            buttonType="submit"
                                            confirmText={ !selectedDepartment.id ? 'Guardar con distribución equitativa' : 'Actualizar' }
                                            cancelText={"Cancelar"}
                                            onConfirm={handleSubmit}
                                            onCancel={onCancel}
                                            clearState={clearState}
                                        />
                                    )
                                }
                                
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
        </>
    )
}