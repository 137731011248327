import { Formik } from "formik";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { useDispatch } from "../../redux/store";
import { showBackdrop } from "../../toolbox/helpers/custom-action";
import { useState } from "react";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";

type Values = {
    onefield: string,
    twofield: string,
    threefield: string,
    fourfield: string,
    fiveField: string,
    sixfield: string,
}

export const PaymentConceptModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, onList, clearState } = props;
    const dispatch = useDispatch();

    const [data, setData] = useState<Values>({
        onefield: rowSelected ? rowSelected.column1 :'',
        twofield: rowSelected ? rowSelected.column2 :'',
        threefield: rowSelected ? rowSelected.column3 :'',
        fourfield: rowSelected ? rowSelected.column4 :'',
        fiveField: rowSelected ? rowSelected.column4 :'',
        sixfield: rowSelected ? rowSelected.column4 :'',
    });

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.onefield) errors.onefield = "onefield requerido";
        if(!values.twofield) errors.twofield = "twofield requerido";
        if(!values.threefield) errors.threefield = "threefield requerido";
        if(!values.fourfield) errors.fourfield = "fourfield requerido";
        if(!values.fiveField) errors.fiveField = "fiveField requerido";
        if(!values.sixfield) errors.sixfield = "sixfield requerido";
        return errors;
    }

    const onSubmit = async(values)  => {

        //prueba
        dispatch(showBackdrop(true));

        CustomSnackbar('success', 'creado con éxito');

        setTimeout(() => {
            dispatch(showBackdrop(false));
        }, 2000)
    }

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Detalle de propietario'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"onefield"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="onefield"
                                            variant="standard"
                                            type="text" 
                                            name="onefield"
                                            multiline
                                            fullWidth
                                            value={values.onefield}
                                            onChange={handleChange}
                                            placeholder={"onefield"}
                                            error={errors.onefield && touched.onefield ? true : false}
                                            helperText={errors.onefield && touched.onefield ? errors.onefield: ''}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"twofield"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="twofield"
                                            variant="standard"
                                            type="text" 
                                            name="twofield"
                                            multiline
                                            fullWidth
                                            value={values.twofield}
                                            onChange={handleChange}
                                            placeholder={"twofield"}
                                            error={errors.twofield && touched.twofield ? true : false}
                                            helperText={errors.twofield && touched.twofield ? errors.twofield: ''}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"threefield"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="threefield"
                                            variant="standard"
                                            type="text" 
                                            name="threefield"
                                            multiline
                                            fullWidth
                                            value={values.threefield}
                                            onChange={handleChange}
                                            placeholder={"threefield"}
                                            error={errors.threefield && touched.threefield ? true : false}
                                            helperText={errors.threefield && touched.threefield ? errors.threefield: ''}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"fourfield"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="fourfield"
                                            variant="standard"
                                            type="text" 
                                            name="onefifourfieldeld"
                                            multiline
                                            fullWidth
                                            value={values.fourfield}
                                            onChange={handleChange}
                                            placeholder={"fourfield"}
                                            error={errors.fourfield && touched.fourfield ? true : false}
                                            helperText={errors.fourfield && touched.fourfield ? errors.fourfield: ''}
                                        />
                                        </Grid>
                                    </Grid>
                                
                                </Grid>

                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={'Guardar Cambios'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={closeModal}
                                />
                            </form>
                        )
                    }} 
                </Formik>
            </ModalBody>
        </Modal>
    )
}