import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, Autocomplete, FormLabel } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import { useSpecialDateStore } from "../../hooks/useSpecialDateStore"
import { useNavigate } from "react-router-dom"
import { specialdate } from "../../types/slices/specialdateType"
import { ApiStatus } from "../../types/api/status"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { useDispatch } from "../../redux/store"
import moment from "moment"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"
import { useDepartmentStore } from "../../hooks/useDepartmentStore"
import { Department } from "../../types/slices/departmentType"

type Values = {
    id: number | undefined,
    name: string
    arrival_date: string
    exit_date: string
    iddepartment: number | undefined
    departments: any
    status: string | undefined
}

export const SpecialDateForm = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { specialdateSelected, status:statusSpecialdate, action, setSelectedSpecialdate, createSpecialDate, createSpecialDateMassive, editSpecialDate, updateMassive, setActionSpecialDate } = useSpecialDateStore();
    const { departments, getDepartments, setSelectedDepartment, selectedDepartment } = useDepartmentStore();

    const [data, setData] = useState<Values>({
        id: specialdateSelected.id ? specialdateSelected.id : undefined,
        name: specialdateSelected.name ? specialdateSelected.name : '',
        arrival_date: specialdateSelected.arrival_date ? specialdateSelected.arrival_date : '',
        exit_date: specialdateSelected.exit_date ? specialdateSelected.exit_date : '',
        iddepartment: specialdateSelected.iddepartment ? specialdateSelected.iddepartment : undefined,
        departments: [],
        status: specialdateSelected.status ? specialdateSelected.status : undefined,
    });

    const [selectDepartments, setSelectDepartments] = useState<any>([]);

    useEffect(()=>{
        if(!specialdateSelected.id && action=='') {
            navigate({ pathname: RoutesMap.SPECIAL_DATES}, { replace: true })
        }
    }, [!specialdateSelected.id])

    useEffect(() => {
        setSelectedDepartment({} as Department)
        getDepartments()
    },[])

    useEffect(() => {
        if(departments && specialdateSelected.id){
            getCustomDepartment(specialdateSelected.departments)
        }
    },[departments, specialdateSelected.id])

    function getCustomDepartment(originaldepartements): any {
        const resp = originaldepartements ? originaldepartements.map(department => ({id: department.id, name: department.name})) : [];
        setSelectDepartments(resp)
        return resp;
    }

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.name) errors.name = "nombre requerido";
        if(!values.arrival_date) errors.arrival_date = "fecha inicio requerido";
        if(!values.exit_date) errors.exit_date = "fecha cierre requerido";
        return errors;
    }

    const onCancel = ()  => {
        setSelectedSpecialdate({} as specialdate)
        setSelectedDepartment({} as Department)
        navigate({ pathname: RoutesMap.SPECIAL_DATES })
    }

    const clearState = () => {
        setSelectedSpecialdate({} as specialdate)
        setSelectedDepartment({} as Department)
        setActionSpecialDate('')
    }

    const onSubmit = async(values)  => {
        let dateStart = moment(values.arrival_date, "YYYY-MM-DD");
        let dateEnd = moment(values.exit_date, "YYYY-MM-DD");

        if(dateStart > dateEnd) return CustomSnackbar('error', 'La fecha final tiene que ser mayor a la inicial.')
        if(selectDepartments.length == 0) return CustomSnackbar('warning', 'Seleccione al menos un departamento.')

        const iddepartments = selectDepartments.map(department => department.id)

        if(specialdateSelected.id){
            const response = await updateMassive({...values, iddepartments: iddepartments, id:values.id});
            if (response){ 
                clearState();
                navigate({ pathname: RoutesMap.SPECIAL_DATES })
            }else{
                return false;
            }
        }else{
            const response = await createSpecialDateMassive({...values, iddepartments: iddepartments});
            if (response){
                clearState();
                navigate({ pathname: RoutesMap.SPECIAL_DATES })
            }else{
                return false;
            }
        }
    }

    return (
        <>

        {statusSpecialdate == ApiStatus.FETCHING && <CustomBackdrop open={true}/>}
        
        <CustomForm >

            <CustomFormHeader
                title={!specialdateSelected.id ? 'Nueva fecha especial' : 'Editar fecha especial'}
                text={!specialdateSelected.id ? 'Ingrese los datos de la nueva fecha especial' : 'Ingrese los datos de la fecha especial'}
                goBack={RoutesMap.SPECIAL_DATES}
                clearState={clearState}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={errors.name && touched.name ? true : false}
                                            helperText={errors.name && touched.name ? errors.name : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Fecha de inicio:</FormLabel>
                                        <TextField
                                            id="arrival_date"
                                            type="date" 
                                            name="arrival_date"
                                            fullWidth
                                            size="small"
                                            value={values.arrival_date}
                                            onChange={handleChange}
                                            error={errors.arrival_date && touched.arrival_date ? true : false}
                                            helperText={errors.arrival_date && touched.arrival_date ? errors.arrival_date : ''}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Fecha de cierre:</FormLabel>
                                        <TextField
                                            id="exit_date"
                                            type="date" 
                                            name="exit_date"
                                            fullWidth
                                            size="small"
                                            value={values.exit_date}
                                            onChange={handleChange}
                                            error={errors.exit_date && touched.exit_date ? true : false}
                                            helperText={errors.exit_date && touched.exit_date ? errors.exit_date : ''}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} spacing={2} sx={{marginTop:'5px'}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Departamentos:</FormLabel>
                                        <Autocomplete
                                            id="disable-portal"
                                            disablePortal
                                            multiple
                                            value={selectDepartments || []}
                                            options={departments || []}
                                            getOptionLabel={(option) => option.name || ""}
                                            fullWidth
                                            renderInput={(params) => <TextField 
                                                {...params} 
                                                size='small'
                                            />}
                                            onChange={(e, newValue) => { 
                                                setSelectDepartments(newValue || []) 
                                                setFieldValue("departments", newValue);
                                            }}
                                            filterSelectedOptions 
                                        />
                                    </Grid>
                                </Grid>
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={'Guardar Cambios'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
        </>
    )
}