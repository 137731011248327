
import { Grid, Typography, CircularProgress } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";


export const ModalReglas: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, arrayRules, width } = props;

    return (
      <>

      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size='xs'>
        <ModalHeader
            text={"Reglas de Reservación"}
            className="positionElements"
            onCancel={closeModal}
        />
        <ModalBody>
        {
            arrayRules.map((item)=> {
                return(
                    <Typography fontSize={10}> - {item}</Typography>
                )
            })
        }
        </ModalBody>
      </Modal>
      </>
    )
}