import { Box, Button, Grid, Typography } from "@mui/material"
import { useState } from "react"
import CustomTable from "../../../components/common/CustomTable/CustomTable"
import { ModalConfirm } from "../../../components/common/ModalConfirm/ModalConfirm"
import { RenditionAddCostModal } from "./RenditionAddCostModal"
import { useRenditionSpentStore } from "../../../hooks/useRenditionSpentStore"
import { useRenditionStore } from "../../../hooks/useRenditionStore"
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { useRenditionSpentIndividualStore } from "../../../hooks/useRenditionSpentIndividual"
import { moneyFormat } from "../../../toolbox/helpers/money-helpers"
import { CustomBackdrop } from "../../../components/common/CustomBackdrop/CustomBackdrop"
import { ApiStatus } from "../../../types/api/status"

const columns_general = [
    { type: 'options', field: 'options', label: 'Opciones', align: 'center'},
    { type: 'text', field: 'document_name', label: 'Archivo', align: 'center' },
    { type: 'text', field: 'concept_spent', label: 'Concepto de gasto', align: 'center' },
    { type: 'text', field: 'amount', label: 'Monto', align: 'center',
    format: (row) => moneyFormat(row.amount, '$')},
    { type: 'detail', field: 'detail', label: '', align:'center' }
]

const columns_individual = [
    { type: 'options', field: 'options', label: 'Opciones', align: 'center'},
    { type: 'text', field: 'document_name', label: 'Archivo', align: 'center' },
    { type: 'text', field: 'concept_spent', label: 'Concepto de gasto', align: 'center' },
    { type: 'text', field: 'name', label: 'Propietario', align: 'center' },
    { type: 'text', field: 'amount', label: 'Monto', align: 'center',
    format: (row) => moneyFormat(row.amount, '$') },
    { type: 'detail', field: 'detail', label: '', align:'center' }
]

export const RenditionAdminTableDetail = (props) => {

    const { handleBack, departmentSelected } = props

    const { status: renditionStatus, getRenditionById, closeRendition } = useRenditionStore()
    const { renditionSpents, deleteRenditionSpent } = useRenditionSpentStore()
    const { renditionSpentIndividuals, deleteRenditionSpentIndividual } = useRenditionSpentIndividualStore()
    const { renditions } = useRenditionStore()

    const [ rowSelected, setRowSelected ]           = useState<any>('')
    const [ showModal, setShowModal ]               = useState<boolean>(false)
    const [ showModalConfirmGeneral, setShowModalConfirmGeneral ] = useState<boolean>(false)
    const [ showModalConfirmIndividual, setShowModalConfirmIndividual ] = useState<boolean>(false)
    const [ showModalCloseRendition, setShowModalCloseRendition ] = useState<boolean>(false)
    const [ isEdit, setIsEdit ]                     = useState<boolean>(false)
    const [ tab, setTab ] = useState('1');

    const onEdit = (rowSelected) => {
        setRowSelected(rowSelected)
        setIsEdit(true)
        setShowModal(true)
    }
    
    const onDeleteGeneral = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirmGeneral(true)
    }

    const onDeleteIndividual = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirmIndividual(true)
    }

    // const onUpload = (rowSelected) => {
    //     console.log('onUpload')
    // }

    const onDetails = (rowSelected) => {
        const url = rowSelected.document
        const win:any = window.open(`${process.env.REACT_APP_ROOT_URL}/` + url, '_blank')
        win.focus()
    }

    const onCloseModal = () => {
        setShowModal(false)
        if (isEdit) {
            setIsEdit(false)
        }
    }

    const onDeleteConfirmGeneral = async () => {
        setShowModalConfirmGeneral(false)
        const response = await deleteRenditionSpent(rowSelected.id)
        if (response === true) {
            getRenditionById(renditions[0].id)
        }
    }

    const onDeleteConfirmIndividual = async () => {
        setShowModalConfirmIndividual(false)
        const response = await deleteRenditionSpentIndividual(rowSelected.id)
        if (response === true) {
            getRenditionById(renditions[0].id)
        }
    }

    const handleCloseRendition = async () => {
        setShowModalCloseRendition(false)
        const response = await closeRendition(renditions[0].id)
        if (response === true) {
          handleBack()
          getRenditionById(renditions[0].id)
        }
    }

    const returnTotal = () => {
        let total = 0
        switch (tab) {
            case '1':
                total = renditions[0]?.amount_general || 0
                break;
            
            case '2':
                total = renditions[0]?.amount_individual || 0
            break;

            default:
                break;
        }
        return moneyFormat(total, '$')
    }

    return(
        <>
        { renditionStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        <Grid item xs={12}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e, newValue) => setTab(newValue) } aria-label="lab API tabs example">
                            <Tab value="1" label="Generales"/>
                            <Tab value="2" label="Individuales"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                        <CustomTable
                            columns={columns_general} 
                            rows={renditionSpents || []}
                            onRowClick={() => {}}
                            hasOptions
                            onDelete={onDeleteGeneral}
                            onEdit={onEdit}
                            // onUpload={onUpload}
                            onDetails={onDetails}
                        />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                        <CustomTable
                            columns={columns_individual} 
                            rows={renditionSpentIndividuals || []}
                            onRowClick={() => {}}
                            hasOptions
                            onDelete={onDeleteIndividual}
                            onEdit={onEdit}
                            // onUpload={onUpload}
                            onDetails={onDetails}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
            <Grid container>
                <Grid item xs={6} md={8}>                            
                    <Typography sx={{ fontWeight: 'bolder', fontSize: '18px', float: 'right' }}>TOTAL:</Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Typography sx={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>
                        { returnTotal() || '' }
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '15px' }} spacing={1}>
                <Grid item xs={10} sm={8} md={8}>
                    <Button
                        onClick={()=>setShowModalCloseRendition(true)} 
                        sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px' , padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                    >
                        Cerrar rendición
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={4} sx={{ display: 'flex', justifyContent: 'center'}}>
                    <Button
                        onClick={ () => handleBack() }
                        style={{ color:'#73B2FF', border: '1px solid #73B2FF', borderRadius: '5px', height: '25px', padding: '6px 16px', textTransform: 'none', width: 160  }}
                    >
                        Volver
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        {
            showModalConfirmGeneral && (
                <ModalConfirm
                    open={showModalConfirmGeneral}
                    closeModal={()=>{setShowModalConfirmGeneral(false)}}
                    onConfirm={onDeleteConfirmGeneral}
                    onCancel={()=>{setShowModalConfirmGeneral(false)}}
                />
            )
        }
        {
            showModalConfirmIndividual && (
                <ModalConfirm
                    open={showModalConfirmIndividual}
                    closeModal={()=>{setShowModalConfirmIndividual(false)}}
                    onConfirm={onDeleteConfirmIndividual}
                    onCancel={()=>{setShowModalConfirmIndividual(false)}}
                />
            )
        }
        {
            showModal && (
                <RenditionAddCostModal
                    open={showModal}
                    edit={isEdit}
                    rowSelected={rowSelected}
                    closeModal={ ()=>{onCloseModal()} }
                    departmentSelected={departmentSelected}
                />
            )
        }
        {
            showModalCloseRendition && (
                <ModalConfirm
                    open={showModalCloseRendition}
                    closeModal={()=>{setShowModalCloseRendition(false)}}
                    onConfirm={handleCloseRendition}
                    onCancel={()=>{setShowModalCloseRendition(false)}}
                    title="¿Esta seguro de cerrar la rendición?"
                    text='Una vez cerrada no podrá agregar más gastos. Si estás seguro, haz click en el boton "aceptar"'
                />
            )
        }
        </>
    )
}