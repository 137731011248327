import { useEffect, useState } from 'react';
import { Grid, IconButton, Typography, Card, CardContent, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, RoutesMap } from '../../types';
import { ModalCalendar } from '../../components/common/ModalCalendar/ModalCalendar';
import { useCalendar } from '../../hooks/useCalendar';
import { ReservationDataForm, ReservationBlockedDataForm } from '../../types/api/Reservation.type';
import { useReservationStore } from '../../hooks/useReservationStore';
import { ApiStatus } from '../../types/api/status';
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop';
import moment from 'moment';
import { CustomForm } from '../../components/common/CustomForm/CustomForm';
import { CustomFormHeader } from '../../components/common/CustomForm/CustomFormHeader';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { readLocalStorage } from '../../toolbox/helpers/local-storage-helpers';

export const ReservationBlockedConfirm = (props) => {
    const navigate = useNavigate();
    const [showModalCalendar, setShowModalCalendar] = useState<boolean>(false);

    const userData = readLocalStorage(LocalStorageKey.USER_DATA)

    const { arrayDates, getCurrentRange, departament, owner, resetCalendar } = useCalendar();
    const { status: statusReservation, createReservationBlocked } = useReservationStore()

    const currentRange = getCurrentRange();
    const arrivalDate = currentRange ? moment(currentRange.startDate).format('LL') : '-----';
    const returnDate = currentRange ? moment(currentRange.endDate).format('LL') : '-----';

    useEffect(()=>{
        if(!departament) {
            clearState();
            navigate({ pathname: RoutesMap.RESERVATION_ADMIN_BLOCKED}, { replace: true })
        }
    }, [!departament])

    const clearState = () => {
        resetCalendar(); 
    }
    
    const onConfirmBlocked = async() =>{

        if(!currentRange?.startDate && !currentRange?.endDate) return CustomSnackbar('warning', 'Aún no ha seleccionado su estadía.') 

        const data:ReservationBlockedDataForm = {
            start_date: moment(currentRange.startDate).format('YYYY-MM-DD'),
            end_date: moment(currentRange.endDate).format('YYYY-MM-DD'),
            iddepartment: departament.id
        }

        if(moment(currentRange.startDate).format('YYYY/MM/DD') === moment(currentRange.endDate).format('YYYY/MM/DD')){
            return CustomSnackbar('warning', 'Debe de seleccionar un rango de fechas.');
    }

        const response = await createReservationBlocked(data);
        if (response === true){
            navigate({ pathname: RoutesMap.RESERVATION_ADMIN_BLOCKED })
            resetCalendar(); 
        }else{
            return false;
        }
    }

    return (
        <>
        { statusReservation === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }

        <CustomForm>

            <CustomFormHeader
                title={'Resumen del Bloqueo'}
                text={'Asegurese de verificar los datos del Bloqueo'}
                goBack={RoutesMap.RESERVATION_ADMIN_BLOCKED}
                clearState={clearState}
            >
            </CustomFormHeader>

            <Grid item padding='20px 150px 20px 150px' justifyContent='center' alignContent='center' alignItems='center' sx={{width:'100%'}}>
                <Grid display='flex' mb={1}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Departamento:</Typography>
                    <Typography sx={{fontSize:'14px'}}>{departament ? departament.name : '-----'}</Typography>
                </Grid>
            </Grid>
            <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%'}} pb={3}>
                <Card onClick={()=>{setShowModalCalendar(true)}} sx={{ width: '700px', display:'flex', justifyContent:'center', alignItems:'center', border:'solid 1px #808080', cursor:'pointer' }}>
                    <Grid sx={{borderRight: '.5px solid #808080', padding:'0px 40px 0px 40px'}}>
                        <CardContent>
                            <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                                LLegada:
                            </Typography>
                            <Grid sx={{padding:'0px 30px 0px 30px', textAlign:'center'}}>
                                <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'20px'}}>
                                    {arrivalDate}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Grid>
                    <Grid sx={{borderLeft: '.5px solid #808080', padding:'0px 40px 0px 40px'}}>
                        <CardContent>
                            <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                                Salida :
                            </Typography>
                            <Grid sx={{padding:'0px 30px 0px 30px', textAlign:'center'}}>
                                <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'20px'}}>
                                    {returnDate}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%'}}>
                <Button onClick={onConfirmBlocked} size="small" variant="contained" sx={{background:'#212D39',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}}>
                    {'Confirmar bloqueo'}
                </Button>
            </Grid>
        </CustomForm>
        {
            showModalCalendar && (
                <ModalCalendar
                    open={showModalCalendar}
                    closeModal={()=>{setShowModalCalendar(false)}}
                    datesRegister={arrayDates}
                    onReservation={() => {navigate(RoutesMap.RESERVATION_ADMIN_CONFIRM)}}
                />
            )
        }
        </>
    )
}