import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import logo from '../../assets/image/Support.jpg';
import { useAuthStore } from '../../hooks';
import { useUserStore } from '../../hooks/UseUserStore';
const themeAvenir = createTheme({
   typography: {
       fontFamily: 'Poppins, sans-serif',
       fontSize: 15
   },
})


export const RecoveryAccountOwner: FC = () => {

   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
   const [loading, setLoading] = useState(false)
  //  const {  user, forgotPassword } = useAuthStore()
   const {status:statusUser, forgotPassword } = useUserStore();
   const [data, setData] = useState({
      rut: '',
      perfil: 3,
      textError: '',
      showPassword: false
    })

   useEffect(() => {
       const handleResize = () => {
           setIsMobile(window.innerWidth <= 768);
       };

       window.addEventListener('resize', handleResize);

       return () => {
           window.removeEventListener('resize', handleResize);
       };
   }, []);

   const handleInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = event.target
      switch (name) {
        case 'rut':
          // eslint-disable-next-line no-case-declarations
          // const foo = value.split('-').join('')
          if (value.length > 0 && value.length < 11) {
            // foo = foo.match(new RegExp('.{1,8}', 'g')).join('-')
            setData(prev => ({ ...prev, rut: value, textError: '' }))
          } else if (value.length == 0) {
            setData(prev => ({ ...prev, rut: '', textError: '' }))
          }
          break
        default:
          break
      }
    }

    const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim()
      const name = event.target.name
      setData(prev => ({ ...prev, [name]: newVal }))
    }

    const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault()
      const { rut, perfil } = data
      forgotPassword({ rut: rut, iduser_type: perfil})
    }


  return (
   <>
  <ThemeProvider theme={themeAvenir}>
       <Box sx={{
           margin: 0, padding: 0, height: '100vh',
           minHeight: '100vh',
           bgcolor: '#fff'
       }}>
           <Grid container className="container" sx={{ paddingTop: { xs: '5vh', md: '7vh' } }} flexDirection='column' justifyContent={'center'}>
               <Grid container sx={{
                   display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }, // Cambio importante: usa flex para centrar
                   alignItems: 'center',  // Centra verticalmente
                   justifyContent: 'center',  // Centra horizontalmente
               }}>
                   {isMobile && <img style={{ width: '50%' }} src={logo} />}
                   {!isMobile && <img style={{ width: '20%' }} src={logo} />}

               </Grid>
               <Grid item>
                   <Typography
                       sx={{
                           fontSize: { xs: '3vh', md: '5vh' },
                           fontWeight: 'bold',
                           color: "#000",
                           textAlign: 'center'
                       }}
                       variant="h5"
                   >
                     RECUPERACIÓN DE CUENTA
                   </Typography>
               </Grid>
               <Grid item>
                   <Typography
                       sx={{
                           fontSize: { xs: '1.8vh', md: '2.8vh' },
                           color: "#000",
                           textAlign: 'center'
                       }}
                       variant="h5"
                   >
                      Ingresa tu rut y recibirás al correo que proporcionaste al registrarte tus accesos
                   </Typography>
               </Grid>
           </Grid>


           <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'center'}
               spacing={2} >
               <Grid item container flexDirection={'column'} xs={8} md={12} sx={{ mt: 3 }} justifyContent={'center'} alignItems={'center'}>
                   <Grid >
                   <TextField
                        fullWidth
                        label="RUT"
                        variant='outlined'
                        id="outlined-adornment-rut"
                        name='rut'
                        value={data.rut}
                        onChange={handleInput}
                        onBlur={handleInputBlur}
                        placeholder='7777777-7 o 88888888-8'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonIcon sx={{ color: '#29333B' }} />
                            </InputAdornment>)
                        }
                        }
                      />
               </Grid>
                  <Grid item sx={{mt:2}}>
                  <Button
                        type="submit"
                        disabled={loading}
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={handleSubmit}
                        className='buttonAgenda'
                        sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#F15A29' }}
                      >
                        {
                          loading ?
                            <CircularProgress size={28} /> :

                            'Solicitar recuperación'}
                      </Button>
                   </Grid>
               </Grid>

           </Grid>
       </Box>
   </ThemeProvider>
</>
  )
}
