import moment from 'moment';

export const daysBetweenDates = (dateStart, dateEnd) => {
    let current_day = moment(dateStart);
    let arrayDates:any = [];

  	while (current_day.isSameOrBefore(moment(dateEnd))) {
    	arrayDates.push(current_day.format('YYYY/MM/DD'));
        current_day.add(1, 'days');
  	}

  	return arrayDates;
}

export const countWeekends = (dateStart, dateEnd, daySlack) => {
	const start_date = moment(dateStart, 'YYYY-MM-DD');
	const end_date = moment(dateEnd, 'YYYY-MM-DD').subtract(daySlack, 'days');

	const weekends:any = [];
	let numWeekends: number = 0;
	const fechaActual:any = start_date.clone();

	while (fechaActual.isSameOrBefore(end_date)) {
		// Verificar si el día actual es un domingo
		if (fechaActual.day() === 0) {
			weekends.push(fechaActual.clone());
		}

		// Avanzar al siguiente día
		fechaActual.add(1, 'day');
	}

	const date_days:any = [];

	weekends.forEach((fecha) => {
		numWeekends = numWeekends + 1;
		date_days.push(fecha.format('YYYY-MM-DD'));
	});

	return {
		days: date_days,
		numWeekends: numWeekends
	}
}

export const countDays = (dateStart, dateEnd, daySlack) => {
	const start_date = moment(dateStart, 'YYYY-MM-DD');
	const end_date = moment(dateEnd, 'YYYY-MM-DD');

	// Calcular la diferencia en días
	const duracion = moment.duration(end_date.subtract(daySlack,'days').diff(start_date));
	const numeroDias = duracion.asDays();

	return numeroDias;
}

export const formatDateTimeToDate = (date:string) => {
	const dateTimeSplit = date.split(' ')
    const dateSplit = dateTimeSplit[0].split('-')
    const final_date = [dateSplit[2], dateSplit[1], dateSplit[0]].join('-')
    return final_date
}

export const formatDate = (date:string) => {
    const dateSplit = date.split('-')
    const final_date = [dateSplit[2], dateSplit[1], dateSplit[0]].join('-')
    return final_date
}

export const formatHour = (hour:string) => {
	const hourSplit = hour.split(':')
	const final_hour = [hourSplit[0], hourSplit[1]].join(':')
	return final_hour
}