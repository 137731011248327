import { Formik } from "formik"
import { Modal } from "../../../components/common/Modal/Modal"
import { ModalBody } from "../../../components/common/Modal/ModalBody"
import { useEffect, useState } from "react"
import { Grid, TextField, FormLabel, Autocomplete, Button,Checkbox, InputAdornment, Typography } from '@mui/material'
import { ApiStatus } from "../../../types/api/status"
import { CustomBackdrop } from "../../../components/common/CustomBackdrop/CustomBackdrop"
import { useOwnerStore } from "../../../hooks/useOwnerStore"
import { Owner } from "../../../types/slices/ownerType"
import { useRenditionSpentStore } from "../../../hooks/useRenditionSpentStore"
import { useRenditionStore } from "../../../hooks/useRenditionStore"
import { useDepartmentSpentStore } from "../../../hooks/useDepartmentSpentStore"
import { DepartmentSpent } from "../../../types/slices/departmentSpentType"
import { useRenditionSpentIndividualStore } from "../../../hooks/useRenditionSpentIndividual"

import FileUploadIcon from '@mui/icons-material/FileUpload'
import { readLocalStorage } from "../../../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../../../types"
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar"
import { MODE_BUILDING_OWNER } from "../../../toolbox/defaults/app"

type costData = {
    id: number
    iddepartment_spent: number|null
    department_spent: string|null
    amount: number|null
    file: string|null
    idowner: number|null
    owner: string|null
}

export const RenditionAddCostModal : React.FC<any> = (props): JSX.Element | any => {
  const { open, edit, closeModal, rowSelected, departmentSelected } = props
  
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

    const { status: ownerStatus, owners, getOwners } = useOwnerStore()
    const { status: renditionStatus, renditions, getOrCreateRendition } = useRenditionStore()
    const { status: renditionSpents, createRenditionSpent, editRenditionSpent } = useRenditionSpentStore()
    const { status: departmentSpentStatus, departmentSpents, getDepartmentSpents } = useDepartmentSpentStore()
    const { status: spentIndividualStatus, createRenditionSpentIndividual, editRenditionSpentIndividual } = useRenditionSpentIndividualStore()

    const [ selectedPaymentConcept, setSelectedPaymentConcept ] = useState<any>(null)
    const [ nameFile, setNameFile ]             = useState<string>('')
    const [ updateFile,  setUpdateFile  ]       = useState<any>(null)
    const [ addIndividual, setAddIndividual ]   = useState<boolean>(false)
    const [ selectedOwner, setSelectedOwner ]   = useState<Owner|null>(null)
    const [ isGeneral, setIsGeneral ]           = useState<boolean>(false)
    const [ amount, setAmount ]                 = useState<any>('')
    const [ data, setData ]                     = useState<costData>({ 
        id: 0,
        iddepartment_spent: null,
        department_spent: null,
        amount: null,
        file: null,
        idowner: null,
        owner: null 
    })

    useEffect(()=>{
        getDepartmentSpents({ iddepartment: departmentSelected.id, idlevel: MODE_BUILDING_OWNER === "building" ? 1 : 2 })
    },[])

    useEffect(()=>{
        if (addIndividual === true) {
            getOwners({ iddepartment: departmentSelected.id })
        }
    },[addIndividual])

    useEffect(()=>{
        if(open && edit) {
            setData({
                id: rowSelected.id || 0, 
                iddepartment_spent: rowSelected.iddepartment_spent || null,
                department_spent: rowSelected.concept_spent || null,
                amount: parseInt(rowSelected.amount) || null, 
                file: rowSelected.document || null,
                idowner: rowSelected.idowner || null,
                owner: rowSelected.name || null
            })
            const payment = setCustomPaymentConcept(rowSelected.iddepartment_spent, departmentSpents)
            setSelectedPaymentConcept(payment[0])
            setNameFile(rowSelected.document_name || '')
            if (rowSelected.idowner && rowSelected.name) {
                setAddIndividual(true)
                const owner = setCustomOwner(rowSelected.idowner, owners)
                setSelectedOwner(owner[0] || null)
            } else {
                setIsGeneral(true)
            }
        }
    },[open, edit])

    useEffect(()=>{
        validateValueConceptSpent()
    },[selectedPaymentConcept])

    //paymentConcept
    const setCustomPaymentConcept = (idpayment_concept, original_payment_concepts) => {
        const data = idpayment_concept ? original_payment_concepts.filter(value => value.id === idpayment_concept) : null;
        return getCustomPaymentConcept(data);
    }

    const getCustomPaymentConcept = (original_payment_concept) => {
        const res = original_payment_concept ? original_payment_concept.map(paymentConcept => ({...paymentConcept})) : null;
        return res;
    }

    const customPaymentConcepts = getCustomPaymentConcept(departmentSpents || []);
    
    //owners
    const setCustomOwner = (idowner, original_owners) => {
        const data = idowner ? original_owners.filter(value => value.id === idowner) : [];
        return getCustomOwner(data);
    }

    const getCustomOwner = (original_owner) => {
        const res = original_owner ? original_owner.map(owner => ({...owner})) : null;
        return res;
    }

    const customOwner = getCustomOwner(owners || []);

    const onSubmit = async (values) => {
        if (selectedPaymentConcept?.idspent_type === 1 && (selectedPaymentConcept?.amount === '' || selectedPaymentConcept?.amount === null)) {
            return CustomSnackbar('warning', 'El gasto fijo seleccionado no tiene un monto definido.')
        }
        if (nameFile === null || nameFile === '') {
            return CustomSnackbar('warning', 'No se ha seleccionado algún documento.')
        }
        if(amount === null || amount === '' || amount === 'MONTO NO DEFINIDO') {
            return CustomSnackbar('warning', 'No se ha insertado un monto.')
        }
        const amount_value:any = parseInt(amount)
        const formData = new FormData()
        formData.append('idreport', renditions[0]?.id.toString())
        formData.append('iddepartment_spent', selectedPaymentConcept?.id.toString() || '')
        formData.append('amount', amount_value)
        formData.append('idcompany', idcompany.toString() || '')
        if (updateFile != null) {
            formData.append('document', updateFile || '')
        }
        if (addIndividual) { 
            formData.append('idowner', selectedOwner?.id.toString() || '')
        }
        const create_method = !addIndividual ? createRenditionSpent : createRenditionSpentIndividual
        const edit_method   = !addIndividual ? editRenditionSpent : editRenditionSpentIndividual
        const method        = !edit ? create_method(formData) : edit_method(data.id, formData)
        const response = await method
        if (response === true) {
            await getOrCreateRendition({ month: renditions[0].month, year: renditions[0].year, iddepartment: renditions[0].iddepartment})
            closeModal()
        }
    }

    const setUpdateArchFile = (event) => {
        const files = (event.target && event.target.files) || []
        const titleCert = files[0]
        if (titleCert) {
          setUpdateFile(titleCert)
          setNameFile(titleCert.name)
        }
    }

    const handleChangeCheckBox = (e) => {
        const { checked } = e.target
        if (checked === true) {
            setAddIndividual(true)
        } else {
            setAddIndividual(false)
            setSelectedOwner(null)
        }
    }

    const validateValueConceptSpent = () => {
        if (selectedPaymentConcept?.idspent_type === 1) {
            if (selectedPaymentConcept.amount === null || selectedPaymentConcept.amount === '') {
                setAmount('MONTO NO DEFINIDO')
            } else {
                setAmount(selectedPaymentConcept.amount)
            }
        } else {
            setAmount('')
        }
    }

    return(
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            { departmentSpentStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { ownerStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { renditionSpents === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { spentIndividualStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { renditionStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            <ModalBody>
                <Typography sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder'}}>
                    {!edit ? 'AGREGAR GASTO' : 'ACTUALIZAR GASTO'}
                </Typography>
                <Formik initialValues={data} enableReinitialize onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (<>
                        <Grid container sx={{ margin: '15px 0px'}}>
                            <Grid item xs={6}>
                                <FormLabel sx={{ color: '#000000' }}>Concepto de gasto:</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-payment-concept"
                                    value={selectedPaymentConcept}
                                    options={customPaymentConcepts || []}
                                    getOptionLabel={(option) => option.concept_spent || ""}
                                    isOptionEqualToValue={(option, value) => option?.concept_spent === value?.concept_spent}
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} size="small" variant="standard"/>}
                                    onChange={(e, newValue) => {
                                        setSelectedPaymentConcept(newValue)
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ margin: '15px 0px'}}>
                            <Grid item xs={6}>
                                <FormLabel sx={{ color: '#000000' }}>Monto:</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                fullWidth
                                size='small'
                                value={ amount }
                                // error={!!errors.amount}
                                onChange={(e) => {
                                    const regex = /^[0-9\b]+$/
                                    const { value } = e.target
                                    if (value === "" || regex.test(value)) {
                                        // setFieldValue('amount', value)
                                        setAmount(parseInt(value))
                                    }
                                }}
                                variant="standard"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                disabled={selectedPaymentConcept?.idspent_type === 1 ? true : false}
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ margin: '15px 0px'}}>
                            <Grid item xs={6}>
                                <FormLabel sx={{ color: '#000000' }}>Seleccione el archivo:</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                variant="contained"
                                component="label"
                                style={{ maxWidth: '100%', width: '100%' }}
                                sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#212D39', "&:hover": {backgroundColor: "#212D39" } }}
                            >
                                <FileUploadIcon fontSize="small" />
                                {nameFile}
                                <input
                                style={{ display: 'none' }}
                                type='file'
                                name='file'
                                onChange={(e) => setUpdateArchFile(e)}
                                />
                            </Button>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ margin: '15px 0px'}}>
                            <Grid item xs={6}>
                                <Checkbox color="primary" onChange={ (e) => handleChangeCheckBox(e)} checked={addIndividual} disabled={isGeneral}/>
                                <FormLabel>Gasto individual:</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-individual"
                                    disabled={ addIndividual ? false : true }
                                    value={selectedOwner}
                                    options={customOwner || []}
                                    getOptionLabel={(option) => option.name || ""}
                                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} size="small" variant="standard"/>}
                                    onChange={(e, newValue) => {
                                        setSelectedOwner(newValue);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ margin: '15px 0px'}}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                onClick={ () => handleSubmit() }
                                sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px' , margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                                >
                                    {
                                        edit
                                        ?
                                        'Actualizar'
                                        :
                                        'Agregar'
                                    }
                                </Button>
                                <Button
                                onClick={ () => closeModal() }
                                sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px' , margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </>)}}
                </Formik>
            </ModalBody>
      </Modal>
    )
}