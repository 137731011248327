import { ReservationParams, ReservationDataForm, ReservationBlockedDataForm } from '../types/api/Reservation.type'
import { api } from './configs/axiosConfigs'

export const ReservationAPI = {

    getReservations: async (params:ReservationParams|null = null) => {

        const response = await api.get(
            '/reservations',
            {
                params: params
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    getReservationsExcel: async (params:ReservationParams|null = null) => {

        const response = await api.get(
            '/reservations/excel',
            {
                params: params
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    getSpecificReservation: async (idreservation:number) => {
        const response = await api.get(
            `/reservations/${idreservation}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    getBlocked: async (idcompany:number) => {
        const response = await api.get(
            `/reservationsBlocked/${idcompany}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    getRules:async(idowner, iddepartment) => {
        const response = await api.get(
            `/reservations/rules?idowner=${idowner}&iddepartment=${iddepartment}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    createReservation: async (data: ReservationDataForm) => {
        const response = await api.post(
            '/reservations/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    createReservationBlocked: async (data: ReservationBlockedDataForm) => {
        const response = await api.post(
            '/reservationsBlocked/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    editReservation: async (idreservation: number, data: ReservationDataForm) => {
        const response = await api.patch(
        `/reservations/${idreservation}`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    deleteReservation: async (idreservation: number) => {
        const response = await api.delete(
        `/reservations/${idreservation}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    deleteBlocked: async (id: number) => {
        const response = await api.delete(
        `/reservationsBlocked/${id}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    cancelReservation: async (idreservation: number) => {
        const response = await api.delete(
        `/reservations/cancel/${idreservation}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        console.log(response)
        return response
    },

    getReservationGuests: async (idreservation:number) => {
        const response = await api.get(
            `/reservationsInvited/${idreservation}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    addGuestsReservation: async (idreservation:number, data:any) => {
        const response = await api.post(
            `/reservationsInvited/addInvitedOwners/${idreservation}`, {idinvited : data}
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    deleteReservationGuest: async (idreservationinvited: number) => {
        const response = await api.delete(
        `/reservationsInvited/${idreservationinvited}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
}