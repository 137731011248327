import React, { useEffect, useState } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { useSeasonStore } from "../../hooks/useSeasonStore";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Card, Grid, Skeleton, Slider, Typography } from "@mui/material";
import { ApiStatus } from "../../types/api/status";

export const ModalDetails: React.FC<any> = (props): JSX.Element | any => {
  const { open, closeModal, ownerSelected } = props;

  const { status:statusSeason, seasonSelectedByOwner, getSeasonsByOwner } = useSeasonStore();
  const { selectedDepartment} = useDepartmentStore();

  useEffect(()=>{
    getSeasonsByOwner(ownerSelected.idowner)
  },[])


  return ( 
    <>
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
        <ModalHeader
          text={"Dias Disponibles"}
          className="positionElements"
          onCancel={closeModal}
        />
        <ModalBody>
          
        <Card sx={{ textAlign: 'center', padding: '5px', marginX: '10px', border: 'solid 1px #ebebeb' }}>
              {
                
                statusSeason === ApiStatus.FETCHING ? (
                  <Grid item container xs={12} spacing={2} justifyContent='center'>
                    <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
                      <Skeleton width="60%" />
                    </Grid>
                    <Grid item xs={12} display='flex' alignItems='center' justifyContent='center' sx={{ padding: '10px 0px 0px 0px' }}>
                      <Skeleton width="60px" />
                    </Grid>
                    <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
                      <Skeleton width="30px" />
                    </Grid>
                    <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
                      <Skeleton width="60px" />
                    </Grid>
                  </Grid>
                ) : (
                  seasonSelectedByOwner && seasonSelectedByOwner.length > 0 ? (
                    <>
                      <Grid>
                        {
                          seasonSelectedByOwner.map((value:any, index:number) => {
                            return (
                              value.season_iddepartment == selectedDepartment.id && (
                                <Grid sx={{ borderRight: `${index === seasonSelectedByOwner.length -1 ? "" : "solid 1px #101010"}`,textAlign:'center', padding:'10px 60px 20px 60px'}} justifyContent='center'>
                                  <div><Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px'}}>{value?.season_name || ""}</Typography></div>
                                  <div><Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>{value?.current_avaibality+' días' || '0 días'}</Typography></div>
                                  <div><Typography color='#808080' sx={{ fontWeight:400, fontSize:'15px'}}>{'disponibles'}</Typography></div>
                                </Grid>
                              )
                            )
                          })
                        }
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Typography gutterBottom color='#808080' sx={{ fontWeight:400, fontSize:'16px' }}>
                        {selectedDepartment.name}
                      </Typography>
                      <Grid sx={{textAlign:'center', padding:'20px 60px 20px 60px'}}>
                        <div><Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px'}}>{"Sin temporas disponibles"}</Typography></div>
                      </Grid>
                    </>
                  )
                )
              }
            </Card>
        </ModalBody>
      </Modal>
    </>
  )
}