import { FC, useEffect, useState } from "react";
import { usePaymentConceptStore } from "../../hooks/usePaymentConceptStore";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useDepartmentSpentStore } from "../../hooks/useDepartmentSpentStore";
import { DepartmentSpent } from "../../types/slices/departmentSpentType";
import { PaymentConceptDetailFormModal } from "./PaymentConceptDetailFormModal";
import { ApiStatus } from "../../types/api/status";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { moneyFormat } from "../../toolbox/helpers/money-helpers";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { useBuildingSpentStore } from "../../hooks/useBuildingSpentStore";
import { PaymentConceptBuildingDetailFormModal } from "./PaymentConceptBuildingDetailFormModal";

const columns = [
    { type: 'options', field: 'options', align:'center', label: 'OPCIONES' },
    { type: 'text', field: 'building', label: 'EDIFICIO', align: 'center' },
    { type: 'text', field: 'amount', label: 'MONTO', format:(row) => row.amount ? moneyFormat(row.amount, '$')  : '-----' , align: 'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const PaymentConceptDetail2 :  FC = () => {

  const navigate = useNavigate()
  const { status: apiStatus, buildingSpents, selectedBuildingSpent, getBuildingSpent, deleteBuildingSpent, setSelectedBuildingSpent, setActionBuildingSpent } = useBuildingSpentStore();

    const { selectedDetail } = usePaymentConceptStore()
    
    const [ rows , setRows ]            = useState<any[]>([])
    const [rowSelected, setRowSelected] = useState<any>('');
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [ showModal, setShowModal ] = useState<boolean>(false)
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(!selectedDetail.id) {
            navigate({ pathname: RoutesMap.PAYMENT_CONCEPT}, { replace: true })
        }
    }, [!selectedDetail.id])

    useEffect(()=>{
        getBuildingSpentsApi({ idconcept_spent: selectedDetail.id })
    },[])

    useEffect(() => {
        if (buildingSpents) {
          setRows(buildingSpents)
          return
        }
        setRows([])
    }, [buildingSpents])
  
    const getBuildingSpentsApi = async(data) => {
      try {
        setLoading(true);
        const response = await getBuildingSpent(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    const onAdd = () => {
        setShowModal(true)
    }

  const onEdit = (rowSelected) => {
      
      console.log(rowSelected);
      setRowSelected(rowSelected)
      setSelectedBuildingSpent(rowSelected)
      setShowModal(true)
    }
    
    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        const response = await deleteBuildingSpent(rowSelected.id)
        if(response){
            setShowModalConfirm(false)
            getBuildingSpentsApi({ idconcept_spent: selectedDetail.id })
        }else{
            return false;
        }
    }

    return (
        <Grid container sx={{ margin: '0px 20px' }}>
            {/* { apiStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />} */}

            <CustomFormHeader
                title={selectedDetail.description || ''}
                text={`Concepto de tipo ${selectedDetail.name_spent_type}`}
                goBack={RoutesMap.PAYMENT_CONCEPT}
            >
            </CustomFormHeader>


            <Grid item xs={12} md={12}>
              {
                selectedDetail.name_spent_type == "Fijos" ? (
                  <CustomTable
                    disabled_title
                    columns={columns} 
                    loading={loading}
                    rows={rows || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                    onEdit={onEdit}
                  />
                ) : (
                  <CustomTable
                    disabled_title
                    columns={columns} 
                    loading={loading}
                    rows={rows || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                  />
                )
              }
                
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{setShowModalConfirm(false)}}
                        rowSelected={rowSelected}
                        onConfirm={onDeleteConfirm}
                        onCancel={()=>{setShowModalConfirm(false)}}
                        status2={apiStatus == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModal && (
                    <PaymentConceptBuildingDetailFormModal
                        open={showModal}
                        closeModal={ () => {
                            setShowModal(false) 
                            setSelectedBuildingSpent({} as any)
                        }}
                        rowSelected={selectedBuildingSpent}
                        loadBuildingSpents={getBuildingSpentsApi}
                    />
                )
            }
        </Grid>
    )
}