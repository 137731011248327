import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { RoutesMap } from "../../types";
import { useAssignmentStore } from "../../hooks/useAssignmentStore";
import { useNavigate } from "react-router-dom";
import { Assignment } from "../../types/slices/assignmentType";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { DetailModal } from "./components/DetailModal";

const columns = [
  { type: 'options', field: 'options', label: 'Opciones' },
  { type: 'text', field: 'name_assignor', label: 'Cedente' },
  { type: 'text', field: 'name_assignee', label: 'Cesionario'},
  { type: 'text', field: 'name_assignee', label: 'Tipo',
  format: (row) =>  row?.idowner_assignee ? 'Propietario' : 'Externo'},
  { type: 'text', field: 'name_department', label: 'Departamento' },
  { type: 'text', field: 'created_date', label: 'Fecha' },
  { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const CessionList : FC = () => {
  const navigate = useNavigate();
  const { search } = useSelector(selectHeaderState);

  const { assignments, selectedAssignment, getAssignment, acceptAssignment, deleteAssignment, setSelectedAssignment } = useAssignmentStore();
  const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false);
  const [ showModalApproved, setShowModalApproved ] = useState<boolean>(false);
  const [ showModalDetails, setShowModalDetails] = useState<boolean>(false);
  const [ rowSelected, setRowSelected ]           = useState<any>(null)

  const [loading, setLoading] = useState(false); 

  useEffect(()=>{
      getAssignmentApi();
  },[search])

  const getAssignmentApi = async() => {
    try {
      setLoading(true);
      const response = await getAssignment({search});
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onCreate = () => {
    setSelectedAssignment({} as Assignment);
    navigate({ pathname: RoutesMap.CESSION_FORM });
  }

  const onEdit = (rowSelected) => {
    setSelectedAssignment(rowSelected);
    if (rowSelected.isconfirmed == "1") {
      CustomSnackbar("warning","No se puede editar una cesión aprobada")
    } else {
      navigate({ pathname: RoutesMap.CESSION_FORM });      
    }
  }
  const onDelete = (rowSelected) => {
      setRowSelected(rowSelected)
      setShowModalConfirm(true);
  }
  const onDeleteConfirm = async () => {
    setLoading(true);
    setShowModalConfirm(false)
    const response = await deleteAssignment(rowSelected.id)
    if (response === true) {
      getAssignmentApi()
    }
  }

  const saveRow = (rowSelected) => {
    setShowModalApproved(true)
    setSelectedAssignment(rowSelected);
  }

  const viewDetails = (rowSelected) => {
    setLoading(true);
    setShowModalDetails(true);
    setSelectedAssignment(rowSelected);
    setLoading(false)
  }

  const onApprovedConfirm = async() => {
    setLoading(true);
    setShowModalApproved(false)
    const response = await acceptAssignment(selectedAssignment.id);
    if (response === true) {
      getAssignmentApi()
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <CustomTable
          title={'Lista de cesiones'}
          columns={columns} 
          loading={loading}
          rows={assignments || []}
          onRowClick={() => {}}
          hasOptions
          onAddFn={onCreate}
          onDelete={onDelete}
          onEdit={onEdit}
          onHelper={saveRow}
          onDetails={viewDetails}
          tooltip_helper={'Aprobar'}
          icon_helper={<CheckCircleIcon fontSize="small" />}
        />
      </Grid>
      {
          showModalConfirm && (
              <ModalConfirm
                  open={showModalConfirm}
                  closeModal={()=>{setShowModalConfirm(false)}}
                  onConfirm={onDeleteConfirm}
                  onCancel={()=>{setShowModalConfirm(false)}}
              />
          )
      }
      {
        showModalApproved && (
          <ModalConfirm
            open={showModalApproved}
            title={"Aprobar la cesión de una reserva"}
            text={"Si aprueba la cesión, ya no podra modificarlo."}
            closeModal={()=>{setShowModalApproved(false)}}
            onConfirm={onApprovedConfirm}
            onCancel={()=>{setShowModalApproved(false)}}
          />
        )
      }
      {
        showModalDetails && (
          <DetailModal
           open={showModalDetails}
           closeModal={()=>setShowModalDetails(false)}
          />
        )
      }
    </>
  )
}