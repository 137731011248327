import { useEffect, useState } from "react"
import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, Select, MenuItem } from "@mui/material"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import { useUserStore } from "../../hooks/UseUserStore"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { ApiStatus } from "../../types/api/status"
import { User } from "../../types/slices/userType"
import { useNavigate } from "react-router-dom"

type Values = {
    id: number | undefined,
    rut: string,
    email: string,
    iduser_type: number | undefined,
    password: string,
}

export const UserForm = (props) => {

    const navigate = useNavigate()
    const { getUserType, createUser, editUser, setSelectedUser, setActionUser, typeUsers, status:statusUser, userSelected, action  } = useUserStore()

    const [data, setData] = useState<Values>({
        id: userSelected.id ? userSelected.id : undefined,
        rut: userSelected.rut ? userSelected.rut : '',
        email: userSelected.email ? userSelected.email : '',
        iduser_type: userSelected.iduser_type ? userSelected.iduser_type : 2,
        password: '',
    });

    const validateForm = (values) => {
        let errors:any = {};
        let regRut = /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/;
        if(!values.rut) errors.rut = "rut requerido";
        if(values.rut && !(regRut.test(values.rut))) errors.rut = "rut no válido";
        if(!values.email) errors.email = "email requerido";
        if(!values.iduser_type) errors.iduser_type = "tipo de usuario requerido";
        if(!values.password && !userSelected.iduser_type) errors.password = "password requerido";
        return errors;
    }

    useEffect(()=>{
        if(!userSelected.id && action=='') {
            navigate({ pathname: RoutesMap.USERS}, { replace: true })
        }
    }, [!userSelected.id])

    useEffect(() => {
        getUserType()
    },[])

    const onCancel = ()  => {
        setSelectedUser({} as User)
        navigate({ pathname: RoutesMap.USERS })
    }

    const onSubmit = async(values)  => {

        if(userSelected.id){
            const response = await editUser(userSelected.id, values);
            if (response === true) {
                clearState()
                navigate({ pathname: RoutesMap.USERS })
            }
        }else{
            const response = await createUser(values);
            if (response === true) {
                clearState()
                navigate({ pathname: RoutesMap.USERS })
            }
        }

    }

    const clearState = () => {
        setActionUser('')
        setSelectedUser({} as User)
    }

    return (
        <>
        {statusUser === ApiStatus.FETCHING && <CustomBackdrop open={true}/>}

        <CustomForm >

            <CustomFormHeader
                title={'Nuevo Usuario'}
                text={'Ingrese los datos de la nueva Empresa'}
                goBack={RoutesMap.USERS}
                clearState={clearState}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="rut"
                                            type="text" 
                                            name="rut"
                                            fullWidth
                                            size="small"
                                            value={values.rut}
                                            onChange={handleChange}
                                            placeholder={"rut"}
                                            error={errors.rut && touched.rut ? true : false}
                                            helperText={errors.rut && touched.rut ? errors.rut : ''}
                                            InputProps={{
                                                inputProps: {
                                                    maxLength: 10
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="email"
                                            type="text" 
                                            name="email"
                                            fullWidth
                                            size="small"
                                            value={values.email}
                                            onChange={handleChange}
                                            placeholder={"email"}
                                            error={errors.email && touched.email ? true : false}
                                            helperText={errors.email && touched.email ? errors.email : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Select
                                            id="iduser_type"
                                            size="small"
                                            fullWidth
                                            value={values.iduser_type}
                                            onChange={(event) => { 
                                                setFieldValue("iduser_type", event.target.value);
                                            }}
                                            error={ errors.iduser_type && touched.iduser_type ? true : false }
                                        >
                                            {
                                                typeUsers && typeUsers.map((type, i) => {
                                                    return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>
                                {
                                    !userSelected.id
                                    ?
                                    <Grid item container xs={12} spacing={2} sx={{marginTop:'10px'}}>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="password"
                                                type="text" 
                                                name="password"
                                                fullWidth
                                                size="small"
                                                value={values.password}
                                                onChange={handleChange}
                                                placeholder={"password"}
                                                error={errors.password && touched.password ? true : false}
                                                helperText={errors.password && touched.password ? errors.password : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    :
                                    null
                                }
                                
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={'Guardar Cambios'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
        </>
    )
}