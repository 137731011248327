import { Grid } from '@mui/material';
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Department } from "../../types/slices/departmentType";
import { useEffect, useState } from 'react';
import { OwnerByDepartamentModal } from './OwnerByDepartmentModal';
import { useOwnerStore } from '../../hooks/useOwnerStore';
import { useNavigate } from 'react-router-dom';
import { Owner } from '../../types/slices/ownerType';
import { EditPercentageModal } from './EditPercentageModal';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';
import { ApiStatus } from '../../types/api/status';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { ModalDetails } from './ModalDetails';

const columns = [
  { type: 'options',  field: 'options', align:'center', label: 'Opciones' },
  { type: 'text', field: 'rut', label: 'Rut', align:'center'},
  { type: 'text', field: 'name', label: 'Nombre'},
  { type: 'text', field: 'email', label: 'Correo', align:'center'},
  { type: 'text', field: 'participation_percentage', label: 'Porcentaje departamento', 
      format:(row) => row.participation_percentage ? `${row.participation_percentage} %` : '-----', align:'center'
  },
  { type: 'detail',   field: 'detail',         align:'center',     label: '' }
];

export const OwnerByDepartament = () => {

  const navigate = useNavigate();
  const { status: statusApiDepartment, setSelectedDepartment, selectedDepartment, departmentByOwner, getDepartmentsByOwner, deleteDepartmentsByOwner} = useDepartmentStore();

  const { setSelectedOwnerAdd } = useOwnerStore();

  const [rowSelected, setRowSelected] = useState<any>('');
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [showModalDetails, setShowModalDetails] = useState<boolean>(false);

  useEffect(() => {
    if (selectedDepartment?.id) {
      getDepartmentsByOwnerApi();
    } else {
      navigate({ pathname: RoutesMap.DEPARTAMENT }) 
    }
  }, []);

  const getDepartmentsByOwnerApi = async() => {
    try {
      setLoadingTable(true);
      const response = await getDepartmentsByOwner(selectedDepartment?.id);
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
    }
  }

  const clearState = () => {
    setSelectedDepartment({} as Department);
  }

  const onEdit = (rowSelected) => {
    setRowSelected(rowSelected)
    console.log(rowSelected)

    if(rowSelected.is_admin === "1") { return CustomSnackbar("warning","No se puede editar directamente el porcentaje de propietarios.")}
    setShowModalEdit(true);
  }

  const onDelete = async(rowSelected) => {
    setRowSelected(rowSelected)
    setShowModalConfirm(true);
  }

  const onDeleteConfirm = async() => {
    if (rowSelected.id) {
      const response = await deleteDepartmentsByOwner(rowSelected.id)
      if(response){
        // setSelectedSeason({} as Season)
        setShowModalConfirm(false)
        getDepartmentsByOwnerApi()
      }else{
        return false;
      }
    }
  }
  
  const onCreate = () => {
    setShowModalCreate(true);
  }

  const onCloseModal = (onSubmit?: boolean) => {
    if (onSubmit) {
      getDepartmentsByOwnerApi();
    }
    setShowModalCreate(false);
    setSelectedOwnerAdd({} as Owner);
  }

  const onCloseModalEdit = (onSubmit?: boolean) => {
    if (onSubmit) {
      getDepartmentsByOwnerApi();
    }
    setShowModalEdit(false);
    setSelectedOwnerAdd({} as Owner);
  }

  const onDetails = (rowSelected) => {
    setRowSelected(rowSelected)
    setShowModalDetails(true)
  }

  return (
    <>
      <Grid item xs={12}>
        <CustomFormHeader
          title={selectedDepartment.id ? `Propietarios de ${selectedDepartment.name}` : '....'}
          goBack={RoutesMap.DEPARTAMENT}
          clearState={clearState}
        >
        </CustomFormHeader>

        <CustomTable 
          disabled_title
          columns={columns} 
          loading={loadingTable}
          rows={departmentByOwner ||  []}
          onAddFn={onCreate}
          onDelete={onDelete}
          onDetails={onDetails}
          onRowClick={() => {}}
          hasOptions
          onEdit={onEdit}
        />
      </Grid>
      {
        showModalCreate && (
          <OwnerByDepartamentModal
            open={showModalCreate}
            closeModal={onCloseModal}
            row={selectedDepartment}
            owners={departmentByOwner}
          />
        )
      }
      {
        showModalEdit && (
          <EditPercentageModal
            open={showModalEdit}
            closeModal={onCloseModalEdit}
            row={selectedDepartment}
            ownerSelected={rowSelected}
            owners={departmentByOwner}
          />
        )
      }
      {
        showModalConfirm && (
            <ModalConfirm
                open={showModalConfirm}
                closeModal={()=>{
                  setShowModalConfirm(false)
                }}
                onConfirm={onDeleteConfirm}
                status2={statusApiDepartment == ApiStatus.FETCHING ? true : false}
            />
        )
      }
      {
        showModalDetails && (
          <ModalDetails
              open={showModalDetails}
              closeModal={()=>{
                  setShowModalDetails(false)
                }}
              ownerSelected={rowSelected}
          />
        )
      }
    </>
  )
}