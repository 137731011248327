import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, FormLabel, Select, MenuItem } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import { useNavigate } from "react-router-dom"
import { useGuestStore } from "../../hooks/useGuestStore"
import { useAuthStore } from "../../hooks"
import { Guest } from "../../types/slices/guestType"
import { ApiStatus } from "../../types/api/status"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"

type Values = {
    id: number | undefined
    rut: string
    name: string
    phone: string
    email: string
    idowner: number | undefined
    idguest_type: number | undefined
}

export const AffiliatedFamilyForm = (props) => {

    const navigate = useNavigate();

    const { user } = useAuthStore()
    const { typeGuest, selectedGuest, status:statusGuest, action, setSelectedGuest, createGuest, editGuest, getGuestsType, setActionGuest } = useGuestStore();
    
    const sessionOwner:any = user?.data

    const [data, setData] = useState<Values>({
        id: selectedGuest.id ? selectedGuest.id : undefined,
        rut: selectedGuest.rut ? selectedGuest.rut : '',
        name: selectedGuest.name ? selectedGuest.name : '',
        phone: selectedGuest.phone ? selectedGuest.phone : '',
        email: selectedGuest.email ? selectedGuest.email : '',
        idowner: selectedGuest.idowner ? selectedGuest.idowner : undefined,
        idguest_type: selectedGuest.idguest_type ? selectedGuest.idguest_type : undefined,
    });

    const validateForm = (values) => {
        let errors:any = {};
        // let regRut = /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/;
        let regPhone = /^(\+\d{1,3}( )?)?((\(\d{1,3}\))|\d{1,3})[- .]?\d{3,4}[- .]?\d{4}$/;
        let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!values.rut) errors.rut = "rut requerido";
        // if(values.rut && !(regRut.test(values.rut))) errors.rut = "rut no válido";
        if(!values.name) errors.name = "nombre requerido";
        if(!values.phone) errors.phone = "telefono requerido";
        if(values.phone && !(regPhone.test(values.phone))) errors.phone = "telefono no válido";
        if(!values.email) errors.email = "email requerido";
        if(values.email && !(regEmail.test(values.email))) errors.email = "email no válido";
        return errors;
    }

    useEffect(() => {
        getGuestsType()
    },[])

    const onCancel = ()  => {
        setSelectedGuest({} as Guest)
        navigate({ pathname: RoutesMap.AFFILIATED_FAMILY })
    }

    const clearState = () => {
        setSelectedGuest({} as Guest)
        setActionGuest('')
    }

    const onSubmit = async(values)  => {

        if(selectedGuest.id){
            const response = await editGuest(selectedGuest.id, values);
            clearState()
            if (response) navigate({ pathname: RoutesMap.AFFILIATED_FAMILY })
        }else{
            const response = await createGuest({...values, idowner:sessionOwner.id});
            clearState()
            if (response) navigate({ pathname: RoutesMap.AFFILIATED_FAMILY })
        }
    }

    return (
        <>
        {statusGuest == ApiStatus.FETCHING && <CustomBackdrop open={true}/>}
        <CustomForm >

            <CustomFormHeader
                title={!selectedGuest.id ? 'Nuevo invitado' : 'Editar invitado'}
                text={!selectedGuest.id ? 'Ingrese los datos del nuevo invitado' : 'Ingrese los datos del invitado'}
                goBack={RoutesMap.AFFILIATED_FAMILY}
                clearState={clearState}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2} >
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Rut:</FormLabel>
                                        <TextField
                                            id="rut"
                                            type="text" 
                                            name="rut"
                                            fullWidth
                                            size="small"
                                            value={values.rut}
                                            onChange={handleChange}
                                            error={errors.rut && touched.rut ? true : false}
                                            helperText={errors.rut && touched.rut ? errors.rut : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={errors.name && touched.name ? true : false}
                                            helperText={errors.name && touched.name ? errors.name : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Teléfono:</FormLabel>
                                        <TextField
                                            id="phone"
                                            type="text" 
                                            name="phone"
                                            fullWidth
                                            size="small"
                                            value={values.phone}
                                            onChange={(e) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (e.target.value === "" || regex.test(e.target.value)) {
                                                  setFieldValue('phone', e.target.value)
                                                }
                                            }}
                                            error={errors.phone && touched.phone ? true : false}
                                            helperText={errors.phone && touched.phone ? errors.phone : ''}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} spacing={2} sx={{marginTop:'5px'}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Correo:</FormLabel>
                                        <TextField
                                            id="email"
                                            type="text" 
                                            name="email"
                                            fullWidth
                                            size="small"
                                            value={values.email}
                                            onChange={handleChange}
                                            error={errors.email && touched.email ? true : false}
                                            helperText={errors.email && touched.email ? errors.email : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Tipo de invitado:</FormLabel>
                                        <Select
                                            id="idguest_type"
                                            value={values.idguest_type}
                                            size="small"
                                            onChange={(event) => { 
                                            setFieldValue("idguest_type", event.target.value);
                                            }}
                                            error={ errors.idguest_type && touched.idguest_type ? true : false }
                                            fullWidth
                                        >
                                            {
                                                typeGuest.length > 0 && typeGuest.map((type, i) => {
                                                    return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>

                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={'Guardar Cambios'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>

        </>
    )
}