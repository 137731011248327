import { useDispatch, useSelector } from "react-redux"
import { onFetchConfiguration, changeStatus, onSetWaitingReservation, onSetCheckIn, onSetCheckOut, onSetDontBookWeekends, onSetMaximumSelectionDays, selectConfiguracionCompanyState, onSetBillingCycleCutoffTime, onSetStartCycle, onSetEndCycle, onSetDayIssuanceDocument, onSetLastDayPayment } from "../redux/slices/configurationCompanySlice"
import { ApiStatus } from "../types/api/status"
import { ConfigurationCompanyAPI } from "../apis/ConfigurationCompanyAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { CompanyAPI } from "../apis/CompanyAPI"
import { ConfigurationDepartmentAPI } from "../apis/ConfigurationDepartmentAPI"

export const useConfigurationCompanyStore = () => {
  const dispatch = useDispatch();

  const { status, configurationCompany } = useSelector(selectConfiguracionCompanyState)
  
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

  const getConfigurations = async (iddepartment:number) => {
    try {
      const response = await ConfigurationDepartmentAPI.getConfigurationDepartment(iddepartment, 'check')
      if(response?.data?.detail?.length === 0) {
        const response2 = await ConfigurationCompanyAPI.getConfigurationCompany(idcompany)
        return response2?.data?.detail
      }else{
        const validate = response?.data?.detail.find(item => item.iddepartment === iddepartment)
        if(validate){
            return validate
        }else{
          const response2 = await ConfigurationCompanyAPI.getConfigurationCompany(idcompany)
          return response2?.data?.detail
        }
      }
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.error(error)
    }
  }

  const getConfigurationCompany = async () => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ConfigurationCompanyAPI.getConfigurationCompany(idcompany)
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        const { detail } = response.data
        dispatch(onFetchConfiguration(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
    }
  }

  const getConfigurationCompanyByOwner = async (data?:any) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ConfigurationCompanyAPI.getConfigurationCompanyByOwner(idcompany,data)
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        const { detail } = response.data
        dispatch(onFetchConfiguration(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.error(error)
    }
  }

  const editConfigurationCompany = async (data: any) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await ConfigurationCompanyAPI.updateConfigurationCompany(data, idcompany)
        if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || '')
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return true
    } catch (error) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        console.log(error)
    }
  }

  const setWaitingReservation = (waitingReservation: number) => {
    try{
        dispatch(onSetWaitingReservation(waitingReservation))
    }catch(error){
        console.log(error)
    }
  }

  const setCheckIn = (checkIn: any) => {
    try{
        dispatch(onSetCheckIn(checkIn))
    }catch(error){
        console.log(error)
    }
  }

  const setCheckOut = (checkOut: any) => {
    try{
        dispatch(onSetCheckOut(checkOut))
    }catch(error){
        console.log(error)
    }
  }

  const setDontBookWeekends = (dontBookWeekend: number) => {
    try{
        dispatch(onSetDontBookWeekends(dontBookWeekend))
    }catch(error){
        console.log(error)
    }
  }

  const setMaximumSelectionDays = (maximumSelectionDays: number) => {
    try{
        dispatch(onSetMaximumSelectionDays(maximumSelectionDays))
    }catch(error){
        console.log(error)
    }
  }

  const setBillingCycleCutoffTime = (maximumSelectionDays: number) => {
    try{
        dispatch(onSetBillingCycleCutoffTime(maximumSelectionDays))
    }catch(error){
        console.log(error)
    }
  }

  const setStartCycle = (startCycle: number) => {
    try{
        dispatch(onSetStartCycle(startCycle))
    }catch(error){
        console.log(error)
    }
  }

  const setEndCycle = (endCycle: number) => {
    try{
        dispatch(onSetEndCycle(endCycle))
    }catch(error){
        console.log(error)
    }
  }

  const setDayIssuanceDocument = (dayIssuanceDocument: number) => {
    try{
        dispatch(onSetDayIssuanceDocument(dayIssuanceDocument))
    }catch(error){
        console.log(error)
    }
  }

  const setLastDayPayment = (lastDayPayment: number) => {
    try{
        dispatch(onSetLastDayPayment(lastDayPayment))
    }catch(error){
        console.log(error)
    }
  }



  return {
    status,
    configurationCompany,
    setWaitingReservation,
    setCheckIn,
    setCheckOut,
    setDontBookWeekends,
    setMaximumSelectionDays,
    getConfigurationCompany,
    getConfigurationCompanyByOwner,
    editConfigurationCompany,
    setBillingCycleCutoffTime,
    setStartCycle,
    setEndCycle,
    setDayIssuanceDocument,
    setLastDayPayment,
    getConfigurations
  }
}