import { Formik } from "formik";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { Autocomplete, Divider, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDepartmentStore } from "../../../hooks/useDepartmentStore";
import { Department } from "../../../types/slices/departmentType";
import { useConfigurationDepartmentStore } from "../../../hooks/useConfigurationDepartmentStore";

type Values = {
    start_cycle: number | undefined,
    end_cycle: number | undefined,
    day_issuance_document: number | undefined,
    last_day_payment: number | undefined,
    iddepartment: number | undefined,
    idcompany: number | undefined
}

export const ModalCycleRendition: React.FC<any> = (props): JSX.Element | any => {

  const { open, closeModal, clearState, editMode } = props;

  const { departments, getDepartments, setSelectedDepartment, selectedDepartment } = useDepartmentStore();
  const {getConfigurationByDepartment, getConfigurationDepartment, configurationDepartment, selectedConfigurationDepartment, setStartCycle, setEndCycle, setDayIssuanceDocument, setLastDayPayment , editConfigurationCompany} = useConfigurationDepartmentStore();
  
  const [ loadingDepartment, setLoadingDepartment ] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [data, setData] = useState<Values>({
      start_cycle: undefined,
      end_cycle: undefined,
      day_issuance_document: undefined,
      last_day_payment: undefined,
      iddepartment: undefined,
      idcompany: undefined,
  });

  useEffect(() => {
    if (editMode) {
      setData({
        idcompany: selectedConfigurationDepartment.idcompany,
        iddepartment: selectedConfigurationDepartment.iddepartment,
        start_cycle: selectedConfigurationDepartment.start_cycle,
        end_cycle: selectedConfigurationDepartment.end_cycle,
        day_issuance_document: selectedConfigurationDepartment.day_issuance_document,
        last_day_payment: selectedConfigurationDepartment.last_day_payment,
      })
    } else {
      getDepartmentsAPI()
    }
  },[])
  
  const getDepartmentsAPI = async() => {
    setLoadingDepartment(true);
    const response = await getDepartments()
    setLoadingDepartment(false);
  }

  const validateForm = (values) => {
    let errors:any = {};
    if(!values.start_cycle) errors.start_cycle = "campo requirido";
    if(!values.end_cycle) errors.end_cycle = "campo requirido";
    if(!values.day_issuance_document) errors.day_issuance_document = "campo requirido";
    if(!values.last_day_payment) errors.last_day_payment = "campo requirido";
    if(!values.iddepartment) errors.iddepartment = "campo requirido";
    return errors;
  }

  const onSubmit = async(values)  => {
    setLoadingSubmit(true);
    let success:any;
    if (editMode) {
      success = await editConfigurationCompany(values.iddepartment, {
        start_cycle: values.start_cycle,
        end_cycle: values.end_cycle,
        day_issuance_document: values.day_issuance_document,
        last_day_payment: values.last_day_payment
      })
    } else {
      success = await editConfigurationCompany(values.iddepartment, {
        start_cycle: values.start_cycle,
        end_cycle: values.end_cycle,
        day_issuance_document: values.day_issuance_document,
        last_day_payment: values.last_day_payment,
        status_rendition: "1"
      })
    }
    setLoadingSubmit(false);

    if (success == true) {
      closeModal(true);
    }
  }

  return (
    <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
      <ModalHeader
          text={'Configuración de Departamento'}
          className='positionElements'
          onCancel={closeModal}
          clearState={clearState}
      >
      </ModalHeader>
      <ModalBody>
        <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
          {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container sx={{padding:'20px'}}>
                  {
                    !editMode && (
                      <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>{"Departamento"}:</b></Typography>
                        </Grid>
                        <Grid item  xs={12} sm={12} md={8} lg={8} xl={8}>
                          <Autocomplete
                            id="iddepartment"
                            size="small"
                            placeholder="Seleccione un Departamento"
                            options={departments || []}
                            loading={loadingDepartment}
                            getOptionLabel={(departament) => departament?.name}
                            onChange={(e, newValue: any) => {
                              setSelectedDepartment(newValue || {} as Department);
                              setFieldValue('iddepartment', newValue ? newValue.id : "");
                              // !newValue && setWaitingReservation(0)
                              newValue && getConfigurationByDepartment(newValue.id)
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                label=""
                                size="small"
                                error={errors.iddepartment && touched.iddepartment ? true : false}
                                helperText={errors.iddepartment && touched.iddepartment ? errors.iddepartment : ''}
                              />
                            }
                          />
                        </Grid>
                      </Grid> 
                    )
                  }
                  <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Inicio de ciclo:</Typography>
                    <TextField
                      size="small"
                      sx={{ width: '60px' }}
                      type="number"
                      name="start_cycle"
                      value={values.start_cycle}
                      disabled={!!!values.iddepartment}
                      onChange={(e)=> {setStartCycle(parseInt(e.target.value)); setFieldValue('start_cycle', parseInt(e.target.value))}}
                      error={errors.start_cycle && touched.start_cycle ? true : false}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>de cada mes.</Typography>
                  </Grid>  
                  <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Fin de ciclo:</Typography>
                    <TextField
                      size="small"
                      sx={{ width: '60px' }}
                      type="number"
                      name="end_cycle"
                      value={values.end_cycle}
                      disabled={!!!values.iddepartment}
                      onChange={(e)=> {setEndCycle(parseInt(e.target.value)); setFieldValue('end_cycle', parseInt(e.target.value))}}
                      error={errors.end_cycle && touched.end_cycle ? true : false}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>de cada mes.</Typography>
                  </Grid>  <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Emisión de documento:</Typography>
                    <TextField
                      size="small"
                      sx={{ width: '60px' }}
                      type="number"
                      name="day_issuance_document"
                      value={values.day_issuance_document}
                      disabled={!!!values.iddepartment}
                      onChange={(e)=> {setDayIssuanceDocument(parseInt(e.target.value)); setFieldValue('day_issuance_document', parseInt(e.target.value))}}
                      error={errors.day_issuance_document && touched.day_issuance_document ? true : false}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>de cada mes.</Typography>
                  </Grid>  <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Último día de pago:</Typography>
                    <TextField
                      size="small"
                      sx={{ width: '60px' }}
                      type="number"
                      name="last_day_payment"
                      value={values.last_day_payment}
                      disabled={!!!values.iddepartment}
                      onChange={(e)=> {setLastDayPayment(parseInt(e.target.value)); setFieldValue('last_day_payment', parseInt(e.target.value))}}
                      error={errors.last_day_payment && touched.last_day_payment ? true : false}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>de cada mes.</Typography>
                  </Grid>    
                </Grid>
                <Divider />
                <ModalFooter 
                  buttonType="submit"
                  cancelText={"Cancelar"}
                  onCancel={closeModal}
                  onConfirm={handleSubmit}
                  confirmText={"Actualizar"}
                  loadingConfirmText={loadingSubmit}
                />
              </form>
            )
          }} 
        </Formik>
      </ModalBody>
    </Modal>
  )
}