import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DescriptionIcon from '@mui/icons-material/Description';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import logo from '../../assets/image/Support.jpg';
import { useAuthStore } from '../../hooks';
const themeAvenir = createTheme({
   typography: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 15
   },
})


export const SupportOwner: FC = () => {

   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
   const [loading, setLoading] = useState(false)
   const { user } = useAuthStore()
   const [ updateFile,  setUpdateFile  ] = useState<any>(null)
   const [ nameFile, setNameFile   ] = useState<string>('')
   const [data, setData] = useState({
      rut: '',
      perfil: 3,
      asunto:'',
      description:'',
      textError: '',
      showPassword: false
   })

   useEffect(() => {
      const handleResize = () => {
         setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   const handleInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = event.target
      switch (name) {
         case 'rut':
            // eslint-disable-next-line no-case-declarations
            // const foo = value.split('-').join('')
            if (value.length > 0 && value.length < 11) {
               // foo = foo.match(new RegExp('.{1,8}', 'g')).join('-')
               setData(prev => ({ ...prev, rut: value, textError: '' }))
            } else if (value.length == 0) {
               setData(prev => ({ ...prev, rut: '', textError: '' }))
            }
            break
            case 'asunto':
              setData(prev => ({ ...prev, asunto: value, textError: '' }))
            break
            case 'description':
              setData(prev => ({ ...prev, description: value, textError: '' }))
            break
         default:
            break
      }
   }

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim()
      const name = event.target.name
      setData(prev => ({ ...prev, [name]: newVal }))
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault()
      const { rut, perfil, asunto, description } = data
      // RecoveryPassword(perfil, rut)
   }

   const setUpdateArchFile = (event) => {
      const files = event.target && event.target.files || []
      const titleCert = files[0]
      if (titleCert) {
        setUpdateFile(titleCert)
        setNameFile(titleCert.name)
      }
    }


   return (
      <>
         <ThemeProvider theme={themeAvenir}>
            <Box sx={{
               margin: 0, padding: 0, height: '100vh',
               minHeight: '100vh',
            }}>
               <Grid container className="container"  flexDirection='column' justifyContent={'center'}>
                  <Grid container sx={{
                     display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }, // Cambio importante: usa flex para centrar
                     alignItems: 'center',  // Centra verticalmente
                     justifyContent: 'center',  // Centra horizontalmente
                  }}>
                  {isMobile && <img style={{ width: '50%' }} src={logo} />}
                     {!isMobile && <img style={{ width: '20%' }} src={logo} />}

                  </Grid>
                  <Grid item>
                     <Typography
                        sx={{
                           fontSize: { xs: '3vh', md: '5vh' },
                           fontWeight: 'bold',
                           color: "#000",
                           textAlign: 'center'
                        }}
                        variant="h5"
                     >
                        Enviar una solicitud
                     </Typography>
                  </Grid>
                  <Grid item>
                     <Typography
                        sx={{
                           fontSize: { xs: '1.8vh', md: '2.8vh' },
                           fontWeight:'bold',
                           color: "#000",
                           textAlign: 'center',
                        }}
                        variant="h5"
                     >
                        ¡estamos para ayudarte!
                     </Typography>
                  </Grid>
                  <Grid item>
                     <Typography
                        sx={{
                           fontSize: { xs: '1.8vh', md: '2.8vh' },
                           color: "#000",
                           textAlign: 'center'
                        }}
                        variant="h5"
                     >
                        envianos una solicitud a través del formulario</Typography>
                  </Grid>

                  <Grid item>
                     <Typography
                        sx={{
                           fontSize: { xs: '1.8vh', md: '2.8vh' },
                           color: "#000",
                           textAlign: 'center'
                        }}
                        variant="h5"
                     >
                        en minutos nos estaremos comunicando por los datos</Typography>
                  </Grid>
                  <Grid item>
                     <Typography
                        sx={{
                           fontSize: { xs: '1.8vh', md: '2.8vh' },
                           color: "#000",
                           textAlign: 'center'
                        }}
                        variant="h5"
                     >
                        de contacto que proporcionaste al registrarte</Typography>
                  </Grid>
               </Grid>


               <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'center'}
                  spacing={2} >
                  <Grid item container flexDirection={'column'} xs={8} md={12} sx={{ mt: 3 }} justifyContent={'center'} alignItems={'center'}>
                     <Grid >
                        <TextField
                           fullWidth
                           label="RUT"
                           variant='outlined'
                           id="outlined-adornment-rut"
                           name='rut'
                           value={data.rut}
                           onChange={handleInput}
                           onBlur={handleInputBlur}
                           placeholder='7777777-7 o 88888888-8'
                           InputProps={{
                              startAdornment: (
                                 <InputAdornment position='start'>
                                    <PersonIcon sx={{ color: '#29333B' }} />
                                 </InputAdornment>)
                           }
                           }
                        />
                     </Grid>
                     <Grid sx={{mt:2}}>
                   <TextField
                        fullWidth
                        label="Asunto"
                        variant='outlined'
                        id="outlined-adornment-motivo"
                        name='asunto'
                        value={data.asunto}
                        onChange={handleInput}
                        onBlur={handleInputBlur}
                        placeholder=''
                        InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <ContentPasteSearchIcon sx={{ color: '#29333B' }} />
                             </InputAdornment>)
                         }}
                      />
               </Grid>

               <Grid sx={{mt:2}}>
                   <TextField
                        fullWidth
                        label="descripción"
                        variant='outlined'
                        id="outlined-adornment-motivo"
                        name='description'
                        value={data.description}
                        onChange={handleInput}
                        onBlur={handleInputBlur}
                        placeholder=''
                        InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <DescriptionIcon sx={{ color: '#29333B' }} />
                             </InputAdornment>)
                         }}
                      />
               </Grid>
               {isMobile &&
                <Grid sx={{ mt: 2 }}>
                <Button
                fullWidth
                   variant="contained"
                   component="label"
                   style={{width: '100%' }}
                   className='buttonAgendaGray'
                   sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#536b82' }}
                >
                   <FileUploadIcon style={{ marginRight: '12px' }} />
                   {nameFile ? nameFile : ' Adjunte un archivo '}

                   <input
                      style={{ display: 'none' }}
                      type='file'
                      name='file'
                      onChange={(e) => setUpdateArchFile(e)}
                   />
                </Button>
             </Grid>
               }
                     {!isMobile &&
                      <Grid sx={{ mt: 2 }}>
                      <Button
                      fullWidth
                         variant="contained"
                         component="label"
                         style={{width: '280px' }}
                         className='buttonAgendaGray'
                         sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#536b82' }}
                      >
                         <FileUploadIcon style={{ marginRight: '12px' }} />
                         {nameFile ? nameFile : ' Adjunte un archivo '}

                         <input
                            style={{ display: 'none' }}
                            type='file'
                            name='file'
                            onChange={(e) => setUpdateArchFile(e)}
                         />
                      </Button>
                   </Grid>
                     }

                     <Grid item sx={{ mt: 2 }}>
                        <Button
                           type="submit"
                           disabled={loading}
                           fullWidth
                           size="large"
                           variant="contained"
                           onClick={handleSubmit}
                           className='buttonAgenda'
                           sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#F15A29' }}
                        >
                           {
                              loading ?
                                 <CircularProgress size={28} /> :

                                 'Enviar solicitud'}
                        </Button>
                     </Grid>
                  </Grid>

               </Grid>
            </Box>
         </ThemeProvider>
      </>
   )
}
