import { useEffect, useState } from 'react'
import { Grid, IconButton, Skeleton, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { ModalCalendar } from '../ModalCalendar/ModalCalendar'
import { useNavigate } from 'react-router-dom'
import { useCalendar } from '../../../hooks/useCalendar'
import { CustomSnackbar } from '../CustomSnackbar/CustomSnackbar'
import { useReservationStore } from '../../../hooks/useReservationStore'
import { ApiStatus } from '../../../types/api/status'
import moment from 'moment'
import './CustomSearchStyle.css'

export const CustomSearch = (props:any)=> {

  const navigate = useNavigate();

  const { status: statusCalendar, getCurrentRange, departament, onChangeIsActionBlocked } = useCalendar();
  const { status: statusReservation, reservations, getReservations, getBlocked } = useReservationStore()

  const [showModalCalendar, setShowModalCalendar] = useState<boolean>(false);

  const currentRange = getCurrentRange();
  const arrivalDate = currentRange ? moment(currentRange.startDate).format('DD [de] MMMM') : 'Fecha de llegada';
  const returnDate = currentRange ? moment(currentRange.endDate).format('DD [de] MMMM') : 'Fecha de salida';

  const showCalendar = () => {
    if(!departament) return CustomSnackbar('warning', 'seleccione un departamento.');
    const params:any = {
        iddepartment: departament.id,
        status: '1'
    }
    getReservations(params)
    onChangeIsActionBlocked(false);
    getBlocked();
    setShowModalCalendar(true);
  }

  const onReservation = () => {
    const dates = getCurrentRange();
    if(!dates) return CustomSnackbar('warning', 'Aún no ha seleccionado su estadía.') 

    if(moment(dates.startDate).format('YYYY/MM/DD') === moment(dates.endDate).format('YYYY/MM/DD')){
      return CustomSnackbar('warning', 'Debe de seleccionar un rango de fechas.');
    }
    navigate('/reservation/nameDepartament')
  }

  return (
    <>
      <Paper elevation={5} className='paper-search'>
        <Grid className='main-container'>
          <Grid display='flex' justifyContent='center'>
            <IconButton>
              <SearchIcon sx={{fontSize:'25px', color:'#808080'}} />
            </IconButton>
          </Grid>
          <span className='divider'></span>

          <Grid className='main-container-text'>
            {
              statusReservation === ApiStatus.FETCHED || statusCalendar === ApiStatus.FETCHING  ? (
                <div className='text-departament'>{ departament ? departament.name : 'Reserva tu nueva estadía' }</div>
              ) : (
                <Grid sx={{ paddingLeft:'10px', paddingRight: '10px'}}>
                  <Skeleton sx={{ height:18, width: '180px', paddingLeft:'10px'}} animation="wave" variant="rectangular"/>
                </Grid>
              )
            }
          </Grid>
          <span className='divider'></span>

          <Grid className='main-container-date' onClick={showCalendar}>
            <div className='text'>{'Llegada'}</div>
            <div className='text-option'>{arrivalDate}</div>
          </Grid>
          <span className='divider'></span>

          <Grid className='main-container-date' onClick={showCalendar}>
            <div className='text' >{'Salida'} </div>
            <div className='text-option'>{returnDate}</div>
          </Grid>
        </Grid>
      </Paper>
      
      {
        (showModalCalendar) && (
          <ModalCalendar
            open={showModalCalendar}
            closeModal={()=>{setShowModalCalendar(false)}}
            datesRegister={reservations}
            onReservation={onReservation}
          />
        )
      }
    </>
  )
}