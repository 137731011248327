import { Visibility, VisibilityOff } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import logo from '../../assets/image/Support.jpg';
import { useAuthStore } from '../../hooks';
const themeAvenir = createTheme({
   typography: {
       fontFamily: 'Poppins, sans-serif',
       fontSize: 15
   },
})


export const DeleteAccountOwner: FC = () => {

   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
   const [loading, setLoading] = useState(false)
   const {  user, Delete_Account} = useAuthStore()
   const [data, setData] = useState({
      rut: '',
      perfil: 3,
      motivo:'',
      password: '',
      textError: '',
      showPassword: false
    })

   useEffect(() => {
       const handleResize = () => {
           setIsMobile(window.innerWidth <= 768);
       };

       window.addEventListener('resize', handleResize);

       return () => {
           window.removeEventListener('resize', handleResize);
       };
   }, []);

   const handleInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = event.target
      switch (name) {
         case 'password':
        if (/^.{0,15}$/.test(value)) {
          setData(prev => ({ ...prev, password: value, textError: '' }))
        }
        break
        case 'rut':
          // eslint-disable-next-line no-case-declarations
          // const foo = value.split('-').join('')
          if (value.length > 0 && value.length < 11) {
            // foo = foo.match(new RegExp('.{1,8}', 'g')).join('-')
            setData(prev => ({ ...prev, rut: value, textError: '' }))
          } else if (value.length == 0) {
            setData(prev => ({ ...prev, rut: '', textError: '' }))
          }
          break

          case 'motivo':
              setData(prev => ({ ...prev, motivo: value, textError: '' }))
            break
        default:
          break
      }
    }

    const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim()
      const name = event.target.name
      setData(prev => ({ ...prev, [name]: newVal }))
    }

    const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault()
      const { rut, perfil, password,motivo } = data
      const res:any = Delete_Account(perfil, rut, password, motivo, 'false')
      if (res) {
         setData({
            rut: '',
            perfil: 3,
            motivo: '',
            password: '',
            textError: '',
            showPassword: false
         })
      }
    }

    const handleClickShowPassword = () => {
      setData({ ...data, showPassword: !data.showPassword })
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
    }


  return (
   <>
   <ThemeProvider theme={themeAvenir}>
       <Box sx={{
           margin: 0, padding: 0, height: '100vh',
           minHeight: '100vh',
       }}>
           <Grid container className="container" sx={{ paddingTop: { xs: '5vh', md: '7vh' } }} flexDirection='column' justifyContent={'center'}>
               <Grid container sx={{
                   display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }, // Cambio importante: usa flex para centrar
                   alignItems: 'center',  // Centra verticalmente
                   justifyContent: 'center',  // Centra horizontalmente
                   paddingBottom: { xs: '5vh', md: '5vh' },
               }}>
                   {isMobile && <img style={{ width: '50%' }} src={logo} />}
                   {!isMobile && <img style={{ width: '20%' }} src={logo} />}

               </Grid>
               <Grid item>
                   <Typography
                       sx={{
                           fontSize: { xs: '3vh', md: '5vh' },
                           fontWeight: 'bold',
                           color: "#000",
                           textAlign: 'center'
                       }}
                       variant="h5"
                   >
                     ELIMINAR CUENTA
                   </Typography>
               </Grid>
               <Grid item>
                   <Typography
                       sx={{
                           fontSize: { xs: '1.8vh', md: '2.8vh' },
                           fontWeight:'bold',
                           color: "#000",
                           textAlign: 'center'
                       }}
                       variant="h5"
                   >
                     ¿está seguro en eliminar su cuenta?
                   </Typography>
               </Grid>
                 <Grid item>
                    <Typography
                       sx={{
                          fontSize: { xs: '1.8vh', md: '2.8vh' },
                          color: "#000",
                          textAlign: 'center'
                       }}
                       variant="h5"
                    >
                      Si la respuesta es <strong>SI</strong> porfavor llene el siguiente
                    </Typography>
                 </Grid>
                 <Grid item>
                     <Typography
                        sx={{
                           fontSize: { xs: '1.8vh', md: '2.8vh' },
                           color: "#000",
                           textAlign: 'center'
                        }}
                        variant="h5"
                     >
                        formulario, para luego pasar a eliminar su cuenta</Typography>
                  </Grid>
           </Grid>


           <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
               <Grid item container flexDirection={'column'} xs={8} md={12} sx={{ mt: 3 }} justifyContent={'center'} alignItems={'center'}>
                   <Grid >
                   <TextField
                        fullWidth
                        label="RUT"
                        variant='outlined'
                        id="outlined-adornment-rut"
                        name='rut'
                        value={data.rut}
                        onChange={handleInput}
                        onBlur={handleInputBlur}
                        placeholder='7777777-7 o 88888888-8'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonIcon sx={{ color: '#29333B' }} />
                            </InputAdornment>)
                        }
                        }
                      />
               </Grid>

               <Grid sx={{mt:2}}>
                      <TextField
                        fullWidth
                        label="Contraseña"
                        variant='outlined'
                        id="outlined-adornment-password"
                        type={data.showPassword ? 'text' : 'password'}
                        value={data.password}
                        name="password"
                        onChange={handleInput}
                        onBlur={handleInputBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sx={{ color: '#29333B' }}
                              >
                                {data.showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>

               <Grid sx={{mt:2}}>
                   <TextField
                        fullWidth
                        label="Motivo"
                        variant='outlined'
                        id="outlined-adornment-motivo"
                        name='motivo'
                        value={data.motivo}
                        onChange={handleInput}
                        onBlur={handleInputBlur}
                        placeholder=''
                        InputProps={{
                           startAdornment: (
                             <InputAdornment position='start'>
                               <SearchIcon sx={{ color: '#29333B' }} />
                             </InputAdornment>)
                         }}
                      />
               </Grid>

                  <Grid item sx={{mt:2}}>
                  <Button
                        type="submit"
                        disabled={loading}
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={handleSubmit}
                        className='buttonAgenda'
                        sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#F15A29' }}
                      >
                        {
                          loading ?
                            <CircularProgress size={28} /> :

                            'Solicitar eliminar cuenta'}
                      </Button>
                   </Grid>
               </Grid>

           </Grid>
       </Box>
   </ThemeProvider>
</>
  )
}
