import { useEffect, useState } from 'react';
import { Grid, IconButton, Typography, Card, CardContent, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, RoutesMap } from '../../types';
import { ModalCalendar } from '../../components/common/ModalCalendar/ModalCalendar';
import { useCalendar } from '../../hooks/useCalendar';
import { ReservationDataForm } from '../../types/api/Reservation.type';
import { useReservationStore } from '../../hooks/useReservationStore';
import { ApiStatus } from '../../types/api/status';
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop';
import moment from 'moment';
import { CustomForm } from '../../components/common/CustomForm/CustomForm';
import { CustomFormHeader } from '../../components/common/CustomForm/CustomFormHeader';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { readLocalStorage } from '../../toolbox/helpers/local-storage-helpers';
import { useConfigurationCompanyStore } from '../../hooks/useConfigurationCompanyStore';
import { formatHour } from '../../toolbox/helpers/dates-helpers';

export const ReservationAdminConfirm = (props) => {
    const navigate = useNavigate();
    const [showModalCalendar, setShowModalCalendar] = useState<boolean>(false);

    const userData = readLocalStorage(LocalStorageKey.USER_DATA)
    const idcompany = userData?.data?.idcompany

    const { arrayDates, getCurrentRange, departament, owner, resetCalendar } = useCalendar();
    const { status: statusReservation, createReservation } = useReservationStore()
    const {getConfigurations} = useConfigurationCompanyStore();
    const currentRange = getCurrentRange();
    const arrivalDate = currentRange ? moment(currentRange.startDate).format('LL') : '-----';
    const returnDate = currentRange ? moment(currentRange.endDate).format('LL') : '-----';

    // const checkIn = (departament?.detail && departament?.detail?.check_in) ? departament.detail.check_in + ' horas.' : '-----'
    // const checkOut = (departament?.detail && departament?.detail?.check_out) ? departament.detail.check_out + ' horas.' : '-----'

    const [configuration, setConfiguration] = useState({
        check_in : '00:00',
        check_out : '00:00'
    })
    const [statusConfiguration, setStatusConfiguration] = useState<boolean>(false)

    useEffect(()=>{
        if(!departament && !owner) {
            clearState();
            navigate({ pathname: RoutesMap.RESERVATION_ADMIN}, { replace: true })
        }
    }, [!departament, !owner])

    const clearState = () => {
        resetCalendar(); 
    }

    const getConfigurationApi = async (iddepartment) => {
        setStatusConfiguration(true)
        const response = await getConfigurations(iddepartment)
        setConfiguration(response)
        setStatusConfiguration(false)
    }

    useEffect(()=>{
        if(departament) getConfigurationApi(departament.id)
    },[departament])
    
    const onConfirmReservation = async() =>{

        if(!currentRange?.startDate && !currentRange?.endDate) return CustomSnackbar('warning', 'Aún no ha seleccionado su estadía.') 

        if(moment(currentRange.startDate).format('YYYY/MM/DD') === moment(currentRange.endDate).format('YYYY/MM/DD')){
            return CustomSnackbar('warning', 'Debe de seleccionar un rango de fechas.');
    }
        const data:ReservationDataForm = {
            arrival_date: moment(currentRange.startDate).format('YYYY-MM-DD'),
            exit_date: moment(currentRange.endDate).format('YYYY-MM-DD'),
            iddepartment: departament.id,
            idowner: owner.idowner,
            idlabel: 3, // 1:Externo, 2:Standar, 3:admin
            reservation_date: moment().format('YYYY-MM-DD'),
            idcompany: idcompany
        }

        const response = await createReservation(data);
        if (response === true){
            navigate({ pathname: RoutesMap.RESERVATION_ADMIN_LIST })
            resetCalendar(); 
        }else{
            return false;
        }
    }

    return (
        <>
        { statusReservation === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        { statusConfiguration && <CustomBackdrop open={true}/> }
        <CustomForm>

            <CustomFormHeader
                title={'Resumen de la Reservación'}
                text={'Asegurese de verificar los datos de la reservación'}
                goBack={RoutesMap.RESERVATION_ADMIN}
                clearState={clearState}
            >
            </CustomFormHeader>

            <Grid item justifyContent='center' alignContent='center' alignItems='center' 
                sx={{
                    width:'100%',
                    padding:{ xs: '20px', sm: '20px', md:'20px 150px 20px 150px', lg:'20px 150px 20px 150px', xl:'20px 150px 20px 150px'},
                }}
            >
                <Grid display='flex' mb={1}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Departamento:</Typography>
                    <Typography sx={{fontSize:'14px'}}>{departament ? departament.name : '-----'}</Typography>
                </Grid>
                <Grid display='flex' mb={1}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Reservador:</Typography>
                    <Typography sx={{fontSize:'14px'}}>{owner ? owner.name : '-----'}</Typography>
                </Grid>
                <Grid display='flex' mb={1}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Propietario:</Typography>
                    <Typography  sx={{fontSize:'14px'}}>{owner ? owner.name : '-----'}</Typography>
                </Grid>
            </Grid>
            <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%'}} pb={3}>
                <Card onClick={()=>{setShowModalCalendar(true)}} sx={{ width: '700px', display:'flex', justifyContent:'center', alignItems:'center', border:'solid 1px #808080', cursor:'pointer' }}>
                    <Grid sx={{borderRight: '.5px solid #808080', padding:'0px 40px 0px 40px'}}>
                        <CardContent>
                            <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                                LLegada:
                            </Typography>
                            <Grid sx={{padding:'0px 30px 0px 30px', textAlign:'center'}}>
                                <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'20px'}}>
                                    {arrivalDate}
                                </Typography>
                                <Typography color='#212D39' sx={{ fontWeight:400, fontSize:'14px'}}>
                                    {formatHour(configuration?.check_in)}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Grid>
                    <Grid sx={{borderLeft: '.5px solid #808080', padding:'0px 40px 0px 40px'}}>
                        <CardContent>
                            <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                                Salida :
                            </Typography>
                            <Grid sx={{padding:'0px 30px 0px 30px', textAlign:'center'}}>
                                <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'20px'}}>
                                    {returnDate}
                                </Typography>
                                <Typography color='#212D39' sx={{ fontWeight:400, fontSize:'14px'}}>
                                    {formatHour(configuration?.check_out)}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%'}}>
                <Button onClick={onConfirmReservation} size="small" variant="contained" sx={{background:'#212D39',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}}>
                    {'Confirmar reservación'}
                </Button>
            </Grid>
        </CustomForm>
        {
            showModalCalendar && (
                <ModalCalendar
                    open={showModalCalendar}
                    closeModal={()=>{setShowModalCalendar(false)}}
                    datesRegister={arrayDates}
                    onReservation={() => {navigate(RoutesMap.RESERVATION_ADMIN_CONFIRM)}}
                />
            )
        }
        </>
    )
}