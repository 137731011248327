import { ChangeEvent, useEffect, useState } from "react"
import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, FormLabel, InputAdornment } from "@mui/material"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import { useDispatch } from "../../redux/store"
import { useOwnerStore } from "../../hooks/useOwnerStore"
import { ApiStatus } from "../../types/api/status"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { useNavigate } from "react-router-dom"
import { Owner } from "../../types/slices/ownerType"

type OwnerDataForm = {
    id: number
    name: string
    rut: string
    email: string
    phone: string
}

export const OwnerForm = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { status: statusOwnerApi, selectedOwner, action, createOwner, setSelectedOwner, editOwner, setActionOner } = useOwnerStore()
    
    const [ isEdit, setIsEdit ] = useState<boolean>(false)
    const [data, setData] = useState<OwnerDataForm>({
        id: 0,
        name: '',
        rut: '',
        email: '',
        phone: ''
    });

    useEffect(()=>{
        if(!selectedOwner.id && action=='') {
            navigate({ pathname: RoutesMap.OWNER}, { replace: true })
        }
    }, [!selectedOwner.id])

    useEffect(()=>{
        if(Object.entries(selectedOwner).length > 0) {
            setIsEdit(true)
            setData({
                id: selectedOwner.id,
                rut: selectedOwner.rut,
                name: selectedOwner.name,
                email: selectedOwner.email,
                phone: selectedOwner.phone
            })
            setSelectedOwner({} as Owner)
        }
    },[])

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.name) errors.name = "Nombre es requerido";
        if(!values.rut) errors.rut = "RUT es requerido";
        if(!values.email) errors.email = "Email es requerido";
        if(!values.phone) errors.phone = "Celular es requerido";
        return errors;
    }

    const onCancel = async()  => {
        navigate({ pathname: RoutesMap.OWNER })
    }

    const onSubmit = async (values)  => {
        const method = !isEdit ? createOwner(values) : editOwner(values.id, {...values})
        const response = await method
        if (response === true) {
            setActionOner('')
            navigate({ pathname: RoutesMap.OWNER })
        }
    }

    return (
        <CustomForm >
            {
                statusOwnerApi === ApiStatus.FETCHING && <CustomBackdrop open={true} />
            }

            <CustomFormHeader
                title={!isEdit ? 'Nuevo Propietario' : 'Editar Propietario' }
                text={!isEdit ? 'Ingrese los datos del nuevo Propietario' : 'Ingrese los datos del Propietario'}
                goBack={RoutesMap.OWNER}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={errors.name && touched.name ? true : false}
                                            helperText={errors.name && touched.name ? errors.name : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>RUT:</FormLabel>
                                        <TextField
                                            id="rut"
                                            type="text" 
                                            name="rut"
                                            fullWidth
                                            size="small"
                                            value={values.rut}
                                            onChange={handleChange}
                                            error={errors.rut && touched.rut ? true : false}
                                            helperText={errors.rut && touched.rut ? errors.rut : ''}
                                            InputProps={{
                                                inputProps: {
                                                    maxLength: 10
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Email:</FormLabel>
                                        <TextField
                                            id="email"
                                            type="email" 
                                            name="email"
                                            fullWidth
                                            size="small"
                                            value={values.email}
                                            onChange={handleChange}
                                            error={errors.email && touched.email ? true : false}
                                            helperText={errors.email && touched.email ? errors.email : ''}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} spacing={2} sx={{marginTop:'10px'}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Celular:</FormLabel>
                                        <TextField
                                            id="phone"
                                            type="text" 
                                            name="phone"
                                            fullWidth
                                            size="small"
                                            value={values.phone}
                                            onChange={(e) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (e.target.value === "" || regex.test(e.target.value)) {
                                                  setFieldValue('phone', e.target.value)
                                                }
                                            }}
                                            error={errors.phone && touched.phone ? true : false}
                                            helperText={errors.phone && touched.phone ? errors.phone : ''}                                       
                                        />
                                    </Grid>
                                    {/* {
                                        isEdit && (
                                            <Grid item xs={12} md={4}>
                                                <FormLabel>Porcentaje departamento:</FormLabel>
                                                <TextField
                                                    id="percentage"
                                                    type="text" 
                                                    name="percentage"
                                                    fullWidth
                                                    size="small"
                                                    value={'----'}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    } */}
                                </Grid>
                                
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={ !isEdit ? 'Guardar' : 'Actualizar' }
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
    )
}