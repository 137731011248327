import { useEffect, useState} from 'react';
import { DateRangePicker } from 'react-date-range';
import { Box, Card, Chip, Grid, Paper, Stack, Typography } from '@mui/material';
import { useCalendar } from '../../../hooks/useCalendar';
import { useSpecialDateStore } from '../../../hooks/useSpecialDateStore';
import Skeleton from '@mui/material/Skeleton';
import { ApiStatus } from '../../../types/api/status';
import { es } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';  
import 'react-date-range/dist/theme/default.css'; 
import { useDepartmentStore } from '../../../hooks/useDepartmentStore';
import { DateParams, SpecialDateAvailableParams } from '../../../types/api/SpecialDate.type';
import { useReservationStore } from '../../../hooks/useReservationStore';
import moment from 'moment';
import { C } from '@fullcalendar/core/internal-common';
import { useOwnerStore } from '../../../hooks/useOwnerStore';

export const Calendar: React.FC<any> = (props): JSX.Element | any => {

    const { datesRegister, open} = props;

    const { arrayDates, refreshCalendar, addCurrentRange, getCurrentRange} = useCalendar();
    const { selectedDepartment } = useDepartmentStore()
    const { selectedOwner } = useOwnerStore()
    const { specialdates, status:statusSpecialdate, getSpecialDatesForCalendar } = useSpecialDateStore();
    const { status: statusReservation, blocked } = useReservationStore()
    const currentRange = getCurrentRange();

    const today = new Date();
    const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const [year, setYear] = useState(today.getFullYear());
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if(statusReservation === ApiStatus.FETCHED) refreshCalendar(datesRegister);
        if(selectedDepartment.id){
            getSpecialDatesForCalendar({iddepartment: selectedDepartment.id, idowner: selectedOwner.idowner} as SpecialDateAvailableParams)
        }else{
            getSpecialDatesForCalendar()
        }
    },[open, statusReservation === ApiStatus.FETCHED, blocked]);

    const getMonthsToShow = () => {
        if (windowSize.width >= 1200) {
          return 2; // xl, lg
        } else {
          return 1; // sm, md
        }
    };

    const selectedSpecialDates = (value) => {

        const startDate = new Date(value.startDate)
        const endDate = new Date(value.endDate)
        
        const data = {
            startDate: startDate,
            endDate: endDate,
        }
        addCurrentRange(data);
    }

    const selectedRange = (item) => {
        console.log(item)
        let value:any = Object.values(item)[0];
        addCurrentRange(value);
    }

    return (
        <>

        <Grid xs={12} display='flex' justifyContent='center' padding='5px' sx={{textAlign:'center', color :'#b0a7a7', border: 'solid 1px #e9e2e2', borderRadius:'10px', marginBottom:'5px'}}>
            {
                (!currentRange || currentRange=='') && (
                    <span>arrastre para seleccionar rango de fechas</span>
                )
            }
            {
                currentRange && (
                    <>
                    <Grid sx={{display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}}>
                        <Card sx={{ minWidth: 300, padding:'2px', border:'solid 1px #3d91ff'}}>
                            <Typography sx={{color:'#101010', fontSize:'14px'}}>
                                {currentRange ? moment(currentRange.startDate).format('MMMM Do YYYY') : ''}
                            </Typography>
                        </Card>
                        <span style={{width:'10px'}}></span>
                        <Card sx={{ minWidth: 300, padding:'2px', border:'solid 1px #3d91ff'}}>
                            <Typography sx={{color:'#101010', fontSize:'14px'}}>
                                {currentRange ? moment(currentRange.endDate).format('MMMM Do YYYY') : ''}
                            </Typography>
                        </Card>
                    </Grid>
                    </>
                )
            }
        </Grid>

        <Grid xs={12} sx={{display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }, border: 'solid 1px #e9e2e2', borderRadius:'10px'}}>

            { 
                statusSpecialdate === ApiStatus.FETCHING && (
                    <>
                    <Box sx={{ p:2 }}>
                        <Skeleton width={110}/>
                        <Skeleton width={150}/>
                        <Skeleton width={150}/>
                        <Skeleton width={150}/>
                    </Box>
                    </>
                )
            }

            {
                (statusSpecialdate === ApiStatus.FETCHED && specialdates && specialdates.length > 0) && (
                    <Grid container alignItems="start" display='block' justifyContent="start" sx={{width:'300px', padding:'10px'}}>
                        <Grid item mb={2}>
                            <Typography sx={{color:'#101010', fontSize:'14px'}}>Fechas especiales:</Typography>
                        </Grid>
                        <Grid item pr={2}>
                            <Stack direction="column" spacing={1} >
                                {
                                    specialdates.map((item)=>(
                                        <Chip label={item.name + ' - ' + item.status_name} variant="outlined" onClick={()=>{item.status === '1' && selectedSpecialDates(item)}}/>
                                    ))
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }
            <Grid sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }, borderRight: 'solid 1px #e9e2e2'}}></Grid>
            <Grid sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, borderBottom: 'solid 1px #e9e2e2'}}></Grid>
            {
                statusReservation === ApiStatus.FETCHED && (
                    <DateRangePicker
                        onChange={item=> selectedRange(item)}
                        showSelectionPreview={false}
                        moveRangeOnFirstSelection={false}
                        // months={2}
                        months={getMonthsToShow()}
                        ranges={arrayDates}
                        direction="horizontal"
                        preventSnapRefocus={true}
                        calendarFocus="backwards"
                        staticRanges={[]}
                        inputRanges={[]}
                        minDate={minDate}
                        locale={es}
                    />
                )
            }
            
        </Grid>
        </>
    )
}