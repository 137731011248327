import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, FormLabel, Autocomplete, Select, MenuItem } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import { ConceptCategory } from "../../types/slices/conceptCategoryType"
import { SpentType } from "../../types/slices/spentTypeType"
import { useSpentTypeStore } from "../../hooks/useSpentTypeStore"
import { useConceptCategoryStore } from "../../hooks/useConceptCategoryStore"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { ApiStatus } from "../../types/api/status"
import { usePaymentConceptStore } from "../../hooks/usePaymentConceptStore"
import { useNavigate } from "react-router-dom"
import { PaymentConcept } from "../../types/slices/paymentConceptType"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"
import { MODE_BUILDING_OWNER } from "../../toolbox/defaults/app"

type PaymentConceptDataForm = {
    id: number
    description: string,
    idspent_type: number,
    idconcept_category: number,
    idlevel: number,
}

export const PaymentConceptForm = (props) => {

    const nagivate = useNavigate()

    const { status: statusPaymentConcept, selectedPaymentConcept, action, spentLevel, createPaymentConcept, setSelectedPaymentConcept, editPaymentConcept, setActionPayment, getSpentLevel } = usePaymentConceptStore()
    const { status: statusSpentType, spentTypes, getSpentTypes } = useSpentTypeStore()
    const { status: statusConceptCategory,conceptCategories, getConceptCategories } = useConceptCategoryStore()

    const [ isEdit, setIsEdit ] = useState<boolean>(false)
    const [ selectedSpentType, setSelectedSpentType ] = useState<SpentType|null>(null)
    const [ selectedCategory, setSelectedCategory ]   = useState<ConceptCategory|null>(null)
    const [data, setData] = useState<PaymentConceptDataForm>({
        id: 0,
        description: '',
        idspent_type: 0,
        idconcept_category: 0,
        idlevel: 0
    })

    useEffect(()=>{
        if(!selectedPaymentConcept.id && action=='') {
            nagivate({ pathname: RoutesMap.PAYMENT_CONCEPT}, { replace: true })
        }
    }, [!selectedPaymentConcept.id])

    useEffect(()=>{
        getSpentTypes()
        getConceptCategories()
        getSpentLevel()
    },[])

    useEffect(()=>{
        if(selectedPaymentConcept.id && spentTypes.length > 0 && conceptCategories.length > 0) {
            setIsEdit(true)
            setData({
                id: selectedPaymentConcept.id,
                description: selectedPaymentConcept.description,
                idconcept_category: selectedPaymentConcept.idconcept_category,
                idspent_type: selectedPaymentConcept.idspent_type,
                idlevel: selectedPaymentConcept.idlevel,
            })
            const categorySelect = setCustomConceptCategories(selectedPaymentConcept.idconcept_category, conceptCategories)
            const spentTypeSelect = setCustomSpentType(selectedPaymentConcept.idspent_type, spentTypes) 
            setSelectedCategory(categorySelect[0] || null)
            setSelectedSpentType(spentTypeSelect[0]|| null)
            setSelectedPaymentConcept({} as PaymentConcept)
        }
    },[selectedPaymentConcept.id && spentTypes.length > 0 && conceptCategories.length > 0])

    // categories
    const setCustomConceptCategories = (idconcept_categories, original_concept_categories) => {
        const data = idconcept_categories ? original_concept_categories.filter(value => value.id === idconcept_categories) : [];
        return getCustomConceptCategories(data);
    }

    const getCustomConceptCategories = (original_concept_categories) => {
        const res = original_concept_categories ? original_concept_categories.map(categorie => ({...categorie})) : null;
        return res;
    }

    const customConceptCategories = getCustomConceptCategories(conceptCategories || []);

    //spentTypes
    const setCustomSpentType = (idspent_type, original_spent_types) => {
        const data = idspent_type ? original_spent_types.filter(value => value.id === idspent_type) : [];
        return getCustomConceptCategories(data);
    }

    const getCustomSpentTypes = (original_spent_types) => {
        const res = original_spent_types ? original_spent_types.map(spentType => ({...spentType})) : null;
        return res;
    }

    const customSpentTypes = getCustomSpentTypes(spentTypes || []);

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.description) errors.description = "Descripción es requerido"
        return errors;
    }

    const onCancel = async ()  => {
        nagivate({ pathname: RoutesMap.PAYMENT_CONCEPT })
    }

    const onSubmit = async (values)  => {
        if(!selectedSpentType) return CustomSnackbar('warning', 'Seleccione un tipo de gasto.')
        if(!selectedCategory) return CustomSnackbar('warning', 'Seleccione una categoría.')
        
        const method = !isEdit ? createPaymentConcept({
            ...values,
            idlevel: MODE_BUILDING_OWNER === "building" ? 1 : 2,
            idspent_type: selectedSpentType?.id,
            idconcept_category: selectedCategory?.id
        }) : editPaymentConcept(data.id, {
            ...values,
            idlevel: MODE_BUILDING_OWNER === "building" ? 1 : 2,
            idspent_type: selectedSpentType?.id,
            idconcept_category: selectedCategory?.id
        })
        const response = await method
        if (response === true) {
            setActionPayment('')
            nagivate({ pathname: RoutesMap.PAYMENT_CONCEPT })
        }
    }

    return (
        <CustomForm >
            {
                statusConceptCategory === ApiStatus.FETCHING && <CustomBackdrop open={true} />
            }
            {
                statusSpentType === ApiStatus.FETCHING && <CustomBackdrop open={true} />
            }
            {
                statusPaymentConcept === ApiStatus.FETCHING && <CustomBackdrop open={true} />
            }
            <CustomFormHeader
                title={ !isEdit ? 'Nuevo concepto de gasto' : 'Editar concepto de gasto'}
                text={ !isEdit  ? 'Ingrese los datos del nuevo concepto de gasto' : 'Actualice los datos del concepto de gasto'}
                goBack={RoutesMap.PAYMENT_CONCEPT}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Descripción:</FormLabel>
                                        <TextField
                                            id="description"
                                            type="text" 
                                            name="description"
                                            fullWidth
                                            size="small"
                                            value={values.description}
                                            onChange={handleChange}
                                            error={errors.description && touched.description ? true : false}
                                            helperText={errors.description && touched.description ? errors.description : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Tipo de gasto:</FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-spent-type"
                                            value={selectedSpentType}
                                            options={customSpentTypes || []}
                                            getOptionLabel={(option) => option.name || ""}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                            onChange={(e, newValue) => {
                                                setSelectedSpentType(newValue);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Categoría:</FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-category"
                                            value={selectedCategory}
                                            options={customConceptCategories || []}
                                            getOptionLabel={(option) => option.name || ""}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                            onChange={(e, newValue) => {
                                                setSelectedCategory(newValue);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {/* <Grid item container xs={12} spacing={2} sx={{marginTop:'5px'}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nivel de gasto:</FormLabel>
                                        <Select
                                            id="idlevel"
                                            value={values.idlevel}
                                            size="small"
                                            onChange={(event) => { 
                                            setFieldValue("idlevel", event.target.value);
                                            }}
                                            error={ errors.idlevel && touched.idlevel ? true : false }
                                            fullWidth
                                        >
                                            {
                                                spentLevel.length > 0 && spentLevel.map((type, i) => {
                                                    return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                </Grid> */}
                    
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={ !isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
    )
}