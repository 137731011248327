import { Autocomplete, Grid, TextField, Typography, Button } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search'
import { Calendar } from "../../components/common/Calendar/Calendar"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { RoutesMap } from "../../types"
import { useCalendar } from "../../hooks/useCalendar"
import { useDepartmentStore } from "../../hooks/useDepartmentStore"
import { useOwnerStore } from "../../hooks/useOwnerStore"
import { ApiStatus } from "../../types/api/status"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { Department } from "../../types/slices/departmentType"
import { useReservationStore } from "../../hooks/useReservationStore"
import { Owner } from "../../types/slices/ownerType"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"
import { ModalInfo } from "../../components/common/ModalInfo/ModalInfo"
import { ModalReglas } from "./components/ModalReglas"
import { ReservationAPI } from "../../apis/ReservationAPI"
import moment from "moment"
  
export const ReservationAdminView = () => {

    const navigate = useNavigate();
    
    const { addPeopleInvolved, resetCalendar, onChangeIsActionBlocked, getCurrentRange } = useCalendar();
    const { selectedOwner, setSelectedOwner } = useOwnerStore()
    const { status: statusDepartment, departments, selectedDepartment, getDepartments, setSelectedDepartment } = useDepartmentStore()
    const { status: statusReservation, reservations, getReservations, getBlocked, blocked } = useReservationStore()

    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [ownersByDepartament, setOwnersByDepartament] = useState<any>([]);
    const [openModalReglas,setOpenModalReglas] = useState<boolean>(false)
    const [arrayRules, setArrayRules] = useState([])
    useEffect(()=>{
        setSelectedDepartment({} as Department)
        setSelectedOwner({} as Owner)
        getDepartments();
        onChangeIsActionBlocked(false)
        getBlocked();
    }, [])

    const getRules = async(idowner, iddepartment) => {
        const response = await ReservationAPI.getRules(idowner, iddepartment);
        const { detail } = response.data
        console.log(detail)
        setArrayRules(detail)
    }

    const onSearchAvailability = (e) => {
        e.preventDefault();
        resetCalendar(); 

        if (!selectedDepartment.id || !selectedOwner.id) return CustomSnackbar("warning",'Seleccione todos los campos')
        const params:any = {
            iddepartment: selectedDepartment.id,
            status: '1'
        }
        getRules(selectedOwner.idowner, selectedDepartment.id)
        getReservations(params);
        setShowCalendar(true); 
    }

    const onSubmit = () => {
        if (!selectedDepartment.id || !selectedOwner.id) return CustomSnackbar("warning","Seleccione todos los campos");

        const dates = getCurrentRange();
        if(!dates) return CustomSnackbar('warning', 'Aún no ha seleccionado su estadía.') 
        
        if(moment(dates.startDate).format('YYYY/MM/DD') === moment(dates.endDate).format('YYYY/MM/DD')){
                return CustomSnackbar('warning', 'Debe de seleccionar un rango de fechas.');
        }
        const data = {
            departament: selectedDepartment,
            owner: selectedOwner,
        }

        addPeopleInvolved(data)
        navigate({ pathname: RoutesMap.RESERVATION_ADMIN_CONFIRM}, { replace: true })
    }

    return (
      <>
        { statusDepartment === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        { statusReservation === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        <Grid container>
            <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '20px' }}>
                <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600 }}>
                    CREACION MANUAL
                </Typography>
            </Grid>
            <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center" sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px'}}>
                    <Autocomplete
                        id="combo-box-department"
                        size="small"
                        options={departments || []}
                        getOptionLabel={(departament:any) => departament.name}
                        renderInput={(params) => <TextField {...params} label="Departamento" size="small"/>}
                        sx={{width:'300px', padding: '8px'}}
                        onChange={(e, newValue:any) => {
                            setShowCalendar(false);
                            setSelectedOwner({} as Owner);
                            setOwnersByDepartament(newValue ? newValue.owners : []);
                            setSelectedDepartment(newValue || {} as Department);
                        }}
                    />
                    <Autocomplete
                        id="combo-box-department"
                        size="small"
                        options={ownersByDepartament || []}
                        getOptionLabel={(owner:any) => owner.name}
                        renderInput={(params) => <TextField {...params} label="Propietario" size="small"/>}
                        sx={{width:'300px', padding: '8px'}}
                        value={selectedOwner && selectedOwner.id ? selectedOwner : null}
                        onChange={(e, newValue) => {
                            setShowCalendar(false);
                            setSelectedOwner(newValue || {} as Owner);
                        }}
                    />
                <Grid item display='flex'>
                    <Button 
                        startIcon={<SearchIcon/>} 
                        onClick={onSearchAvailability} 
                        size="small" 
                        variant="contained" 
                        sx={{ backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '8px', fontSize:"12px", "&:hover": {backgroundColor: "#212D39" } }}>
                            Buscar disponibilidad
                    </Button>
                </Grid>
            </Grid>
            {
                showCalendar && statusReservation === ApiStatus.FETCHED &&  (
                    <>
                    <Grid>
                        <Typography>La creación de las reservas están regidas por reglas.</Typography>
                        <Button variant="text" onClick={() => setOpenModalReglas(true)}>ver aquí.</Button>
                    </Grid>
                    <Grid item container display='flex' justifyContent='center' sx={{padding:'15px 0px 3px 0px'}}>
                        <Calendar 
                            open={showCalendar}
                            datesRegister= {reservations}
                        />
                    </Grid>
                    <Grid item container display='flex' justifyContent='end'>
                        <Button 
                            onClick={onSubmit} 
                            size="small"
                            variant="contained" 
                            sx={{ background:'#73B2FF',color:'#fff', '&:hover':{bgcolor:'#007ea7'} }}>
                                Reservar
                        </Button>
                    </Grid>
                    </>
                )
            }
        </Grid>
        {
            openModalReglas && <ModalReglas
             open={openModalReglas}
             closeModal={()=>setOpenModalReglas(false)}
             arrayRules = {arrayRules}
            />
        }
        </>
    )
}