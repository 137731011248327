import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { PaymentConceptModal } from "./PaymentConceptModal";
import { usePaymentConceptStore } from "../../hooks/usePaymentConceptStore";
import { PaymentConcept } from "../../types/slices/paymentConceptType";
import { ApiStatus } from "../../types/api/status";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { MODE_BUILDING_OWNER } from "../../toolbox/defaults/app";

const columns = [
    { type: 'options', field: 'options', align:'center', label: 'Opciones' },
    { type: 'text', field: 'description', align:'center', label: 'Nombres' },
    { type: 'text', field: 'name_spent_type', align:'center', label: 'Tipo' },
    { type: 'text', field: 'name_concept_category', align:'center', label: 'Categoria' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const PaymentConceptView = () => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const { status: statusPaymentConceptAPI, paymentConcepts, getPaymentConcepts, setSelectedPaymentConcept, deletePaymentConcept, setSelectedDetail, setActionPayment } = usePaymentConceptStore()

    const [rows, setRows] = useState<PaymentConcept[]>([])
    const [rowSelected, setRowSelected] = useState<any>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getPaymentConceptsApi()
    },[search])

    useEffect(() => {
        if (paymentConcepts) {
          setRows(paymentConcepts)
          return
        }
        setRows([])
    }, [paymentConcepts])

    const getPaymentConceptsApi = async() => {
        try {
          setLoading(true);
          const idlevel = MODE_BUILDING_OWNER === "building" ? 1 : 2;
          const response = await getPaymentConcepts({search, idlevel});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionPayment('add')
        navigate({ pathname: RoutesMap.PAYMENT_CONCEPT_FORM })
    }
    const onEdit = (rowSelected) => {
        setActionPayment('edit')
        setSelectedPaymentConcept(rowSelected)
        navigate({ pathname: RoutesMap.PAYMENT_CONCEPT_FORM })
    }
    const onDelete = (rowSelected) => {
        setActionPayment('delete')
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }
    const onDetails = (rowSelected) => {
        setActionPayment('detail')
        setRowSelected(rowSelected)
      setSelectedDetail(rowSelected)
      if (MODE_BUILDING_OWNER === "building") {
        navigate({ pathname: RoutesMap.PAYMENT_CONCEPT_DETAIL2 })
      } else {
        navigate({ pathname: RoutesMap.PAYMENT_CONCEPT_DETAIL })
      }
    }

    const onDeleteConfirm = async() => {
        const response = await deletePaymentConcept(rowSelected.id)
        if(response){
            setSelectedPaymentConcept({} as PaymentConcept)
            setShowModalConfirm(false)
            getPaymentConceptsApi()
        }else{
            return false;
        }
    }

    return (
        <>
            {/* { statusPaymentConceptAPI === ApiStatus.FETCHING && <CustomBackdrop open={true} /> } */}
            
            <Grid item xs={12}>
                <CustomTable 
                    title={'Concepto de Gastos'}
                    columns={columns} 
                    loading={loading}
                    rows={rows || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDetails={onDetails}
                    onDelete={onDelete}
                    onEdit={onEdit}
                />
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{setShowModalConfirm(false)}}
                        rowSelected={rowSelected}
                        onConfirm={onDeleteConfirm}
                        onCancel={()=>{setShowModalConfirm(false)}}
                        status2={statusPaymentConceptAPI == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModal && (
                    <PaymentConceptModal
                        open={showModal}
                        closeModal={()=>{setShowModal(false)}}
                        rowSelected={rowSelected}
                    />
                )
            }
        </>
    )
}