import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Building, BuildingByDepartment, BuildingType } from "../../types/slices/buildingType";

const initialState: BuildingType =
{
    status: ApiStatus.FETCHED,
    buildings: [],
    buildingsFilter: [],
    buildingByDepartment: [],
    errorMessage: undefined,
    selectedBuilding: {} as Building,
    selectedBuildingByDepartment: {} as BuildingByDepartment,
}

const buildingSlice = createSlice({
    name: 'building',
    initialState,
    reducers: {
        onFetchBuilding (state, { payload }: { payload: Building[] }) {
            state.status       = ApiStatus.FETCHED
            state.buildings  = payload
            state.errorMessage = undefined
        },
        onFetchBuildingByDepartment (state, { payload }: { payload: BuildingByDepartment[] }) {
            state.status       = ApiStatus.FETCHED
            state.buildingByDepartment  = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedBuilding (state, { payload }: { payload: Building }) {
            state.selectedBuilding = payload
        },
        onSetSelectedBuildingByDepartment (state, { payload }: { payload: BuildingByDepartment }) {
            state.selectedBuildingByDepartment = payload
        },
        onFetchBuildingFilter (state, { payload }: { payload: Building[] }) {
            state.status       = ApiStatus.FETCHED
            state.buildingsFilter  = payload
            state.errorMessage = undefined
        },
    }
})

export const selectBuildingState = (state: RootState) => state.buildingSlice

export default buildingSlice.reducer

export const {
  onFetchBuilding,
  onFetchBuildingFilter,
  onFetchBuildingByDepartment,
  changeStatus,
  onSetSelectedBuilding,
  onSetSelectedBuildingByDepartment
} = buildingSlice.actions