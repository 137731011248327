import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AddCommentIcon from '@mui/icons-material/AddComment'
import AddToQueueIcon from '@mui/icons-material/AddToQueue'
import ArticleIcon from '@mui/icons-material/Article'
import BadgeIcon from '@mui/icons-material/Badge'
import BlockIcon from '@mui/icons-material/Block'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import ChatIcon from '@mui/icons-material/Chat'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GroupIcon from '@mui/icons-material/Group'
import HailIcon from '@mui/icons-material/Hail'
import HandymanIcon from '@mui/icons-material/Handyman'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PaidIcon from '@mui/icons-material/Paid'
import PeopleIcon from '@mui/icons-material/People'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import RememberMeIcon from '@mui/icons-material/RememberMe'
import SegmentIcon from '@mui/icons-material/Segment'
import SettingsIcon from '@mui/icons-material/Settings'
import ThreePIcon from '@mui/icons-material/ThreeP'
import TimelineIcon from '@mui/icons-material/Timeline'
import TodayIcon from '@mui/icons-material/Today'
import PersonIcon from '@mui/icons-material/Person'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import PollIcon from '@mui/icons-material/Poll'

import { RoutesMap } from '../../types'
import { routeAdminType } from '../../types/typeComponents/NavSection'

export const icons = {
  AddCommentIcon,
  AddToQueueIcon,
  ArticleIcon,
  BadgeIcon,
  ChatIcon,
  ContentPasteIcon,
  DashboardIcon,
  EqualizerIcon,
  FormatListBulletedIcon,
  GroupIcon,
  HailIcon,
  HandymanIcon,
  PaidIcon,
  PeopleIcon,
  PeopleAltIcon,
  PermContactCalendarIcon,
  SegmentIcon,
  SettingsIcon,
  ThreePIcon,
  TimelineIcon,
  ManageAccountsIcon,
  AccountCircleIcon,
  NotificationsIcon,
  RememberMeIcon,
  CardMembershipIcon,
  TodayIcon,
  BlockIcon,
  ReceiptLongIcon
}

const rutasAdmin: routeAdminType[] = [
  {
    id: 1,
    name: 'Maestros',
    icon: <PersonIcon />,
    rutas: [
      {
        name_ruta: 'Propietarios',
        location: RoutesMap.OWNER,
        icon: null,
      },
      {
        name_ruta: 'Departamentos',
        location: RoutesMap.DEPARTAMENT,
        icon: null,
      },
      {
        name_ruta: 'Gastos',
        location: RoutesMap.PAYMENT_CONCEPT,
        icon: null,
      },
      {
        name_ruta: 'Concepto de Categorias',
        location: RoutesMap.CONCEPT_CATEGORY,
        icon: null,
      },
      {
        name_ruta: 'Temporadas',
        location: RoutesMap.SEASON,
        icon: null,
      },
      {
        name_ruta: 'Fechas Especiales',
        location: RoutesMap.SPECIAL_DATES,
        icon: null,
      },
      {
        name_ruta: 'Servicios',
        location: RoutesMap.SERVICES,
        icon: null,
      }
     
    ]
  },
  {
    id: 2,
    name: 'Reservas',
    icon: <CalendarMonthIcon />,
    rutas: [
      {
        name_ruta: 'Creacion Manual',
        location: RoutesMap.RESERVATION_ADMIN,
        icon: null,
      },
      {
        name_ruta: 'Listado de reservas',
        location: RoutesMap.RESERVATION_ADMIN_LIST,
        icon: null,
      },
      {
        name_ruta: 'Creación de Bloqueos',
        location: RoutesMap.RESERVATION_ADMIN_BLOCKED,
        icon: null,
      },
      {
        name_ruta: 'Lista de Bloqueos',
        location: RoutesMap.BLOCKED_LIST,
        icon: null,
      }
    ]
  },
  {
    id: 3,
    name: 'Rendiciones',
    icon: <PaidIcon />,
    rutas: [
      {
        name_ruta: 'Apertura',
        location: RoutesMap.RENDITION_ADMIN_OPEN,
        icon: null,
      },
      {
        name_ruta: 'Listado',
        location: RoutesMap.RENDITION_ADMIN_LIST,
        icon: null,
      }
    ]
  },
  {
    id: 4,
    name: 'Cesiones',
    icon: <ChangeCircleIcon />,
    rutas: [
      {
        name_ruta: 'Cesiones',
        location: RoutesMap.CESSION_LIST,
        icon: null,
      },
      {
        name_ruta: 'Historial',
        location: RoutesMap.CESSION_HISTORY,
        icon: null,
      }
    ]
  },
  {
    id: 5,
    name: 'Reportes',
    icon: <PollIcon />,
    rutas: [
      {
        name_ruta: 'Pago de rendiciones',
        location: RoutesMap.REPORT_ADMIN_RENDITION_PAY,
        icon: null,
      },
      {
        name_ruta: 'Rendiciones',
        location: RoutesMap.REPORT_ADMIN_RENDITION,
        icon: null,
      },
      {
        name_ruta: 'Reservas',
        location: RoutesMap.REPORT_ADMIN_DEPARTMENT,
        icon: null,
      },
      // {
      //   name_ruta: 'Disponibilidad',
      //   location: RoutesMap.REPORT_ADMIN_AVAILABILITY,
      //   icon: null,
      // },
      // {
      //   name_ruta: 'Caja',
      //   location: RoutesMap.REPORT_ADMIN_RENDITION,
      //   icon: null,
      // }
    ]
  },
  {
    id: 6,
    name: 'Ajustes',
    icon: <SettingsIcon />,
    rutas: [
      {
        name_ruta: 'Política de cancelación',
        location: RoutesMap.SETTING_ADMIN_CANCELLATION,
        icon: null,
      },
      // {
      //   name_ruta: 'Política de cesión',
      //   location: RoutesMap.SETTING_ADMIN_CESSION,
      //   icon: <ChatIcon />,
      // },
      {
        name_ruta: 'Política de temporada',
        location: RoutesMap.SETTING_ADMIN_SEASON,
        icon: null,
      },
      {
        name_ruta: 'Política de checking',
        location: RoutesMap.SETTING_ADMIN_CHECKING,
        icon: null,
      },
      {
        name_ruta: 'Política de corte o suspención',
        location: RoutesMap.SETTING_ADMIN_CESSATION,
        icon: null,
      },
      {
        name_ruta: 'Política de ciclo de rendiciones',
        location: RoutesMap.SETTING_ADMIN_CYLE_RENDITION,
        icon: null,
      },
      // {
      //   name_ruta: 'Política de fechas esp.',
      //   location: RoutesMap.SETTING_ADMIN_SPECIAL_DATE,
      //   icon: <ChatIcon />,
      // }
    ]
  },
]

const rutasAdminBuilding: routeAdminType[] = [
  {
    id: 1,
    name: 'Maestros',
    icon: <PersonIcon />,
    rutas: [
      {
        name_ruta: 'Propietarios',
        location: RoutesMap.OWNER,
        icon: null,
      },
      {
        name_ruta: 'Edificios',
        location: RoutesMap.BUILDING,
        icon: null,
      },
      {
        name_ruta: 'Departamentos',
        location: RoutesMap.DEPARTAMENT,
        icon: null,
      },
      {
        name_ruta: 'Gastos',
        location: RoutesMap.PAYMENT_CONCEPT,
        icon: null,
      },
      {
        name_ruta: 'Concepto de Categorias',
        location: RoutesMap.CONCEPT_CATEGORY,
        icon: null,
      },
      {
        name_ruta: 'Temporadas',
        location: RoutesMap.SEASON,
        icon: null,
      },
      {
        name_ruta: 'Fechas Especiales',
        location: RoutesMap.SPECIAL_DATES,
        icon: null,
      },
      {
        name_ruta: 'Servicios',
        location: RoutesMap.SERVICES,
        icon: null,
      }
     
    ]
  },
  {
    id: 2,
    name: 'Reservas',
    icon: <CalendarMonthIcon />,
    rutas: [
      {
        name_ruta: 'Creacion Manual',
        location: RoutesMap.RESERVATION_ADMIN,
        icon: null,
      },
      {
        name_ruta: 'Listado de reservas',
        location: RoutesMap.RESERVATION_ADMIN_LIST,
        icon: null,
      },
      {
        name_ruta: 'Creación de Bloqueos',
        location: RoutesMap.RESERVATION_ADMIN_BLOCKED,
        icon: null,
      },
      {
        name_ruta: 'Lista de Bloqueos',
        location: RoutesMap.BLOCKED_LIST,
        icon: null,
      }
    ]
  },
  {
    id: 3,
    name: 'Rendiciones Edificios',
    icon: <PaidIcon />,
    rutas: [
      {
        name_ruta: 'Apertura',
        location: RoutesMap.RENDITION_ADMIN_BUILDING_OPEN,
        icon: null,
      },
      {
        name_ruta: 'Listado',
        location: RoutesMap.RENDITION_ADMIN_BUILDING_LIST,
        icon: null,
      }
    ]
  },
  {
    id: 4,
    name: 'Cesiones',
    icon: <ChangeCircleIcon />,
    rutas: [
      {
        name_ruta: 'Cesiones',
        location: RoutesMap.CESSION_LIST,
        icon: null,
      },
      {
        name_ruta: 'Historial',
        location: RoutesMap.CESSION_HISTORY,
        icon: null,
      }
    ]
  },
  {
    id: 5,
    name: 'Reportes',
    icon: <PollIcon />,
    rutas: [
      {
        name_ruta: 'Pago de rendiciones',
        location: RoutesMap.REPORT_ADMIN_RENDITION_PAY,
        icon: null,
      },
      {
        name_ruta: 'Rendiciones',
        location: RoutesMap.REPORT_ADMIN_RENDITION,
        icon: null,
      },
      {
        name_ruta: 'Reservas',
        location: RoutesMap.REPORT_ADMIN_DEPARTMENT,
        icon: null,
      },
      // {
      //   name_ruta: 'Disponibilidad',
      //   location: RoutesMap.REPORT_ADMIN_AVAILABILITY,
      //   icon: null,
      // },
      // {
      //   name_ruta: 'Caja',
      //   location: RoutesMap.REPORT_ADMIN_RENDITION,
      //   icon: null,
      // }
    ]
  },
  {
    id: 6,
    name: 'Ajustes',
    icon: <SettingsIcon />,
    rutas: [
      {
        name_ruta: 'Política de cancelación',
        location: RoutesMap.SETTING_ADMIN_CANCELLATION,
        icon: null,
      },
      // {
      //   name_ruta: 'Política de cesión',
      //   location: RoutesMap.SETTING_ADMIN_CESSION,
      //   icon: <ChatIcon />,
      // },
      {
        name_ruta: 'Política de temporada',
        location: RoutesMap.SETTING_ADMIN_SEASON,
        icon: null,
      },
      {
        name_ruta: 'Política de checking',
        location: RoutesMap.SETTING_ADMIN_CHECKING,
        icon: null,
      },
      {
        name_ruta: 'Política de corte o suspención',
        location: RoutesMap.SETTING_ADMIN_CESSATION,
        icon: null,
      },
      {
        name_ruta: 'Política de ciclo de rendiciones',
        location: RoutesMap.SETTING_ADMIN_CYLE_RENDITION,
        icon: null,
      },
      // {
      //   name_ruta: 'Política de fechas esp.',
      //   location: RoutesMap.SETTING_ADMIN_SPECIAL_DATE,
      //   icon: <ChatIcon />,
      // }
    ]
  },
]

const rutasSuperAdmin: routeAdminType[] = [
  {
    id: 1,
    name: 'Maestros',
    icon: <PersonIcon />,
    rutas: [
      {
        name_ruta: 'Empresas',
        location: RoutesMap.COMPANIES,
        icon: null,
      },
      {
        name_ruta: 'Administradores',
        location: RoutesMap.ADMINISTRATOR,
        icon: null,
      },

    ]
  },
  {
    id: 2,
    name: 'Ajustes',
    icon: <SettingsIcon />,
    rutas: [
      {
        name_ruta: 'Política de reservación',
        location: RoutesMap.SETTING_SUPERADMIN_RESERVATION,
        icon: null,
      },
    ]
  },
]

export const navSections = {
  'Superadmin': rutasSuperAdmin,
  'Admin': rutasAdmin,
  'AdminBuilding': rutasAdminBuilding,
}
