import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, Autocomplete, FormLabel, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import { useNavigate } from "react-router-dom"
import { useSeasonStore } from "../../hooks/useSeasonStore"
import { useDepartmentStore } from "../../hooks/useDepartmentStore"
import { Season } from "../../types/slices/seasonType"
import { Department } from "../../types/slices/departmentType"
import { ApiStatus } from "../../types/api/status"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import DateRangeIcon from '@mui/icons-material/DateRange'
import moment from "moment"
import { countDays, countWeekends } from "../../toolbox/helpers/dates-helpers"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"

type Values = {
    id: number | undefined,
    name: string
    idtype_season: number
    start_date: string
    end_date: string
    iddepartment: number | undefined
    departments: any
    idcompany: number | undefined
    day_slack: number
}

const TypeSeason = [
    {id:1, name: 'Temporada baja'},
    {id:2, name: 'Temporada alta'}
]

export const SeasonForm = (props) => {

    const navigate = useNavigate();

    const { seasonSelected, status:statusSeason, action, setSelectedSeason, createSeasonMassive, editSeason, updateMassive, setActionSeason } = useSeasonStore();
    const { departments, getDepartments, setSelectedDepartment, selectedDepartment } = useDepartmentStore();

    const [selectDepartments, setSelectDepartments] = useState<any>([]);
    const [ selectedTypeSeason, setSelectedTypeSeason ] = useState<any|null>(null)

    const [data, setData] = useState<Values>({
        id: seasonSelected.id ? seasonSelected.id : undefined,
        name: seasonSelected.name ? seasonSelected.name : '',
        idtype_season: seasonSelected.idtype_season ?  seasonSelected.idtype_season : 0,
        start_date: seasonSelected.start_date ? seasonSelected.start_date : '',
        end_date: seasonSelected.end_date ? seasonSelected.end_date : '',
        iddepartment: seasonSelected.iddepartment ? seasonSelected.iddepartment : undefined,
        departments: [],
        idcompany: seasonSelected.idcompany ? seasonSelected.idcompany : 1,
        day_slack: seasonSelected.day_slack ? seasonSelected.day_slack : 0
    });

    const [weekends, setWeekends] = useState<any>('-----');
    const [numDays, setNumDays] = useState<any>('-----');

    useEffect(()=>{
        if(!seasonSelected.id && action=='') {
            navigate({ pathname: RoutesMap.SEASON}, { replace: true })
        }
    }, [!seasonSelected.id])

    useEffect(() => {
        setSelectedDepartment({} as Department)
        getDepartments()
    },[])

    useEffect(() => {
        if(departments && seasonSelected.id){
            getCustomDepartment(seasonSelected.departments)
        }
    },[departments, seasonSelected.id])

    useEffect(() => {
        if(seasonSelected.id) counDates(seasonSelected.start_date, seasonSelected.end_date, seasonSelected.day_slack || 0)
    },[seasonSelected.id])

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.name) errors.name = "nombre requerido";
        if(!values.start_date) errors.start_date = "fecha inicio requerido";
        if(!values.end_date) errors.end_date = "fecha fin requerido";
        if(values.day_slack < 0) errors.day_slack = "dias de holgura requerido";
        return errors;
    }

    function getCustomDepartment(originaldepartements): any {
        const resp = originaldepartements ? originaldepartements.map(department => ({id: department.id, name: department.name})) : [];
        const findTypeSeason = TypeSeason.find(item => item.id == seasonSelected.idtype_season);
        setSelectedTypeSeason(findTypeSeason)
        setSelectDepartments(resp)
        return resp;
    }

    // const customDepartments = getCustomDepartment(departments || []);

    const onCancel = ()  => {
        setSelectedSeason({} as Season)
        setSelectedDepartment({} as Department)
        navigate({ pathname: RoutesMap.SEASON })
    }

    const clearState = () => {
        setSelectedSeason({} as Season)
        setSelectedDepartment({} as Department)
        setActionSeason('')
    }

    const counDates = (start_date, end_date, day_slack) => {
        if(start_date && end_date){
            const Weekends = countWeekends(start_date, end_date, day_slack);
            const numDay = countDays(start_date, end_date, day_slack);

            setWeekends(Weekends)
            setNumDays(numDay)
        }
    }

    const onSubmit = async(values)  => {

        let dateStart = moment(values.start_date, "YYYY-MM-DD");
        let dateEnd = moment(values.end_date, "YYYY-MM-DD");

        if(!!!selectedTypeSeason) return CustomSnackbar('warning', 'Seleccione el tipo de temporada.')
        if(dateStart > dateEnd) return CustomSnackbar('warning', 'La fecha final tiene que ser mayor a la inicial.')
        if(selectedTypeSeason.id == 2){
            if (!!!(dateStart.isoWeekday() === 1 && dateEnd.isoWeekday() === 1)) return CustomSnackbar('warning', 'Recuerde que las temporadas altas son de lunes a lunes.')
        }
        if(selectDepartments.length == 0) return CustomSnackbar('warning', 'Seleccione al menos un departamento.')
        
        const iddepartments = selectDepartments.map(department => department.id)

        // const data:any = {
        //     name: values.name,
        //     start_date: moment(values.start_date).format('YYYY-MM-DD'),
        //     end_date: moment(values.end_date).format('YYYY-MM-DD'),
        //     iddepartment: values.iddepartment,
        //     idcompany: values.idcompany,
        // }

        const data:any = {
            name: values.name,
            start_date: moment(values.start_date).format('YYYY-MM-DD'),
            end_date: moment(values.end_date).format('YYYY-MM-DD'),
            iddepartments: iddepartments,
            idcompany: values.idcompany,
            day_slack: values.day_slack,
            number_days: numDays,
            idtype_season: selectedTypeSeason.id
        }

        if(seasonSelected.id){
            const response = await updateMassive({...data, id:values.id});
            if (response === true) {
                clearState()
                navigate({ pathname: RoutesMap.SEASON })
            }
        }else{
            const response = await createSeasonMassive(data);
            if (response === true) {
                clearState()
                navigate({ pathname: RoutesMap.SEASON })
            }
        }
    }

    return (
        <>
        {statusSeason == ApiStatus.FETCHING && <CustomBackdrop open={true}/>}
        
        <CustomForm >

            <CustomFormHeader
                title={!seasonSelected.id ? 'Nueva Temporada' : 'Editar Temporada'}
                text={!seasonSelected.id ? 'Ingrese los datos de la nueva Temporada' : 'Ingrese los datos de la Temporada'}
                goBack={RoutesMap.SEASON}
                clearState={clearState}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2} >
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={errors.name && touched.name ? true : false}
                                            helperText={errors.name && touched.name ? errors.name : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Tipo de Temporada:</FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-company"
                                            value={selectedTypeSeason}
                                            options={TypeSeason || []}
                                            getOptionLabel={(option) => option.name || ""}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                            onChange={(e, newValue) => {
                                                setSelectedTypeSeason(newValue);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Fecha inicial:</FormLabel>
                                        <TextField
                                            id="start_date"
                                            type="date" 
                                            name="start_date"
                                            fullWidth
                                            size="small"
                                            value={values.start_date}
                                            onChange={(e)=>{handleChange(e); counDates(e.target.value, values.end_date, values.day_slack)}}
                                            error={errors.start_date && touched.start_date ? true : false}
                                            helperText={errors.start_date && touched.start_date ? errors.start_date : ''}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} spacing={2} sx={{marginTop:'5px'}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Fecha final:</FormLabel>
                                        <TextField
                                            id="end_date"
                                            type="date" 
                                            name="end_date"
                                            fullWidth
                                            size="small"
                                            value={values.end_date}
                                            onChange={(e)=>{handleChange(e); counDates(values.start_date, e.target.value, values.day_slack)}}
                                            error={errors.end_date && touched.end_date ? true : false}
                                            helperText={errors.end_date && touched.end_date ? errors.end_date : ''}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Departamentos:</FormLabel>
                                        <Autocomplete
                                            id="disable-portal"
                                            multiple
                                            disablePortal
                                            value={selectDepartments|| []}
                                            options={departments || []}
                                            getOptionLabel={(option) => option.name || ""}
                                            fullWidth
                                            renderInput={(params) => <TextField 
                                                {...params} 
                                                // label="escribe para buscar" 
                                                size='small'
                                            />}
                                            onChange={(e, newValue) => { 
                                                setSelectDepartments(newValue || []) 
                                                setFieldValue("departments", newValue);
                                            }}
                                            filterSelectedOptions
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Días de Holgura:</FormLabel>
                                        <TextField
                                            id="day_slack"
                                            type="number" 
                                            name="day_slack"
                                            fullWidth
                                            size="small"
                                            value={values.day_slack}
                                            onChange={(e) =>{ handleChange(e) ; counDates(values.start_date, values.end_date, e.target.value)}}
                                            error={errors.day_slack && touched.day_slack ? true : false}
                                            helperText={errors.day_slack && touched.day_slack ? errors.day_slack : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8} mt={2}>
                                        <Grid display='flex' sx={{width:'100%'}}>
                                            <DateRangeIcon/>
                                            <Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px', marginLeft:2, textAlign: 'center', marginRight:2,}}>
                                                {'Número de días'} <div>{numDays || '-----'}</div>
                                            </Typography>
                                            <span style={{border:'1px #808080 solid'}}></span>
                                            <Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px', marginLeft:2, textAlign: 'center'}}>
                                                {'Cantidad de Fines de semana'} <div>{weekends.numWeekends || '-----'}</div>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    { selectedTypeSeason?.id == 2 && <Grid item xs={12}>
                                        {/* <Typography>Recuerde lo siguiente: <br/></Typography> */}
                                        <Typography>(*) Las temporadas altas son de lunes a lunes.</Typography>
                                        <Typography>(*) Las reservas en temporada altas son de una semana completa, en caso de que el número de semanas supere al numero de propietarios las semanas sobrantes serán perdidas.</Typography>

                                    </Grid>}
                                </Grid>
                                
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={'Guardar Cambios'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
        </>
    )
}