import { Autocomplete, Button, Grid, IconButton, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"

//assets
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SearchIcon from '@mui/icons-material/Search'
import { RenditionAddCostModal } from "./components/RenditionAddCostModal"
import { RenditionAdminTableDetail } from "./components/RenditionAdminTableDetail"
import { useDepartmentStore } from "../../hooks/useDepartmentStore"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { ApiStatus } from "../../types/api/status"
import { Department } from "../../types/slices/departmentType"
import { Month } from "../../types/slices/monthType"
import { Year } from "../../types/slices/yearType"
import { useRenditionStore } from "../../hooks/useRenditionStore"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"
import { Rendition } from "../../types/slices/renditionType"

const months = [
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Setiembre'},
    { id: 10, name: 'Octubre'},
    { id: 11, name: 'Noviembre'},
    { id: 12, name: 'Diciembre'},
]

const years = [
    { id: 2023, name: '2023'},
    { id: 2024, name: '2024'},
    { id: 2025, name: '2025'},
    { id: 2026, name: '2026'},
    { id: 2027, name: '2027'},
    { id: 2028, name: '2028'},
    { id: 2029, name: '2029'},
    { id: 2030, name: '2030'},
    { id: 2031, name: '2031'},
    { id: 2032, name: '2032'},
    { id: 2033, name: '2033'},
    { id: 2034, name: '2034'},
    { id: 2035, name: '2035'},
    { id: 2036, name: '2036'},
    { id: 2037, name: '2037'},
    { id: 2038, name: '2038'},
    { id: 2039, name: '2039'},
    { id: 2040, name: '2040'},
]

export const RenditionOpen = (props) => {
    const { status: departmentStatus, departments, getDepartments } = useDepartmentStore()
    const { status: renditionStatus, selectedRendition, setSelectedRendition, getOrCreateRendition } = useRenditionStore()

    const [ isCreate, setIsCreate ]   = useState<boolean>(false)
    const [ showModal, setShowModal ] = useState<boolean>(false)

    const [ selectedDepartment, setSelectedDepartment ] = useState<Department|null>(null)
    const [ selectedMonth, setSelectedMonth ] =  useState<Month|null>(null)
    const [ selectedYear,  setSelectedYear  ] = useState<Year|null>(null)

    useEffect(()=>{
        getDepartments()
        validateIsEdit()
    }, [])

    const handleOpenRendition = async () => {
        if (selectedDepartment === null) return CustomSnackbar('error', 'Seleccione un departamento')
        if (selectedMonth === null) return CustomSnackbar('error', 'Seleccione un mes')
        if (selectedYear === null) return CustomSnackbar('error', 'Seleccione un año')
        const response = await getOrCreateRendition({ month: selectedMonth?.id, year: selectedYear?.id, iddepartment: selectedDepartment.id })
        if (response === true) setIsCreate(true)
    }

    const closeRendition = () => {
        setIsCreate(false)
        setSelectedMonth(null)
        setSelectedYear(null)
        setSelectedDepartment(null)
    }

    const validateIsEdit = async () => {
        if (Object.entries(selectedRendition).length > 0) {
            const month = months.find(month => month.id === parseInt(selectedRendition.month))
            const year  = years.find(year => year.id === parseInt(selectedRendition.year))
            const department = departments.find(department => department.id === selectedRendition.iddepartment)
            setSelectedMonth(month || null)
            setSelectedYear(year || null)
            setSelectedDepartment(department || null)
            await getOrCreateRendition({ month: selectedRendition.month, year: selectedRendition.year, iddepartment: selectedRendition.iddepartment })
            await setIsCreate(true)
            setSelectedRendition({} as Rendition)
        }
    }

    return(
        <Grid container>
            { departmentStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { renditionStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            {
              isCreate && (
                <Grid item xs={12} display='flex' alignItems='center'>
                  <IconButton id="back" size="small" color="primary" aria-label="view" onClick={() =>  closeRendition() }>
                    <ArrowBackIcon fontSize='small'/>
                  </IconButton>
                  <Typography>Volver</Typography>
                </Grid>
              )
            }
            <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '20px' }}>
                <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600 }}>
                    APERTURA DE RENDICIONES
                </Typography>
            </Grid>
            <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center" sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px'}}>
                <Autocomplete
                    id="combo-box-department"
                    size="small"
                    disabled={ isCreate ? true : false }
                    value={selectedDepartment}
                    options={departments || []}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    sx={{width:'300px', padding: '8px'}}
                    renderInput={(params) => <TextField {...params} label="Departamento" size="small" />}
                    onChange={(e, newValue) => {
                        setSelectedDepartment(newValue)
                    }}
                />
                <Autocomplete
                    id="combo-box-month"
                    size="small"
                    value={selectedMonth}
                    options={months}
                    disabled={ isCreate ? true : false }
                    getOptionLabel={(month) => month.name}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    sx={{width:'200px', padding: '8px'}}
                    renderInput={(params) => <TextField {...params} label="Mes"  size="small"/>}
                    onChange={(e, newValue) => {
                        setSelectedMonth(newValue)
                    }}
                />
                <Autocomplete
                    id="combo-box-year"
                    size="small"
                    value={selectedYear}
                    disabled={ isCreate ? true : false }
                    options={years}
                    getOptionLabel={(year) => year.name}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    sx={{width:'200px', padding: '8px'}}
                    renderInput={(params) => <TextField {...params} label="Año"  size="small" />}
                    onChange={(e, newValue) => {
                        setSelectedYear(newValue)
                    }}
                />
                {
                    !isCreate
                    ?
                    <Button 
                        onClick={ () => handleOpenRendition() }
                        size="small"
                        variant="contained" 
                        sx={{ backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '8px', fontSize:"12px", "&:hover": {backgroundColor: "#212D39" } }}
                    >
                        Crear apertura
                    </Button>
                    : 
                    <Button
                        onClick={ () => setShowModal(true) }
                        startIcon={<SearchIcon/>} 
                        sx={{ backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '8px', fontSize:"12px", "&:hover": {backgroundColor: "#212D39" } }}
                    >
                        Agregar gasto
                    </Button>
                }
            </Grid>
            <Grid item container xs={10} sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
                {
                    !isCreate
                    ?
                    <Grid item xs={12}> 
                        <Typography sx={{ fontWeight: 'bolder' }}>
                            Consejos de la aplicación:
                        </Typography>
                        <Typography sx={{ textAlign: 'justify', margin: '10px 0px'}}>
                        Revisar la categoría de gastos y gastos de los maestros. Al crear una rendición, podrás incorporar gastos comunes (por ejemplo gasto común del edificio) y gastos específicos por propietario (por ejemplo multas).
                        </Typography>
                    </Grid>
                    :
                    <RenditionAdminTableDetail
                    handleBack = {closeRendition}
                    departmentSelected={selectedDepartment}
                    />
                }
            </Grid>
            {
                showModal && (
                    <RenditionAddCostModal
                        open={showModal}
                        edit={false}
                        closeModal={ () => setShowModal(false) }
                        departmentSelected={selectedDepartment}
                        monthSelected={selectedMonth}
                        yearSelected={selectedYear}
                    />
                )
            }
        </Grid>
    )
}